<div class="bg-offwhite p-3">
    <div class="d-md-flex">
        <div class="flex-fill mt-1 mb-2">
            <ol class="breadcrumb text-uppercase fs-14 ">
                <li class="breadcrumb-item"><a href="#"><i class='fas fa-home'></i></a></li>
                <li class="breadcrumb-item active text-dark"><b>The HTTP 404</b></li>
            </ol>
        </div>
    </div>
    <div class="d-flex bg-white rounded text-center p-6 col-height">
        <div class="align-self-center m-auto">
            <svg xmlns="http://www.w3.org/2000/svg" class="error-page__svg" viewBox="0 0 512.005 512.005">
                <g>
                    <path d="M469.336,1.005H42.669C19.519,0.639,0.437,19.073,0.003,42.222v352.767c0.434,23.149,19.517,41.583,42.667,41.217     h136.533v25.6h-34.133c-18.843,0.02-34.113,15.29-34.133,34.133v8.533c-0.001,2.263,0.898,4.434,2.499,6.035     c1.6,1.6,3.771,2.499,6.035,2.499h273.067c2.263,0.001,4.434-0.898,6.035-2.499c1.6-1.6,2.499-3.771,2.499-6.035v-8.533     c-0.02-18.843-15.29-34.113-34.133-34.133h-34.133v-25.6h136.533c23.15,0.366,42.232-18.068,42.667-41.217V42.222     C511.568,19.073,492.486,0.639,469.336,1.005z M366.936,478.872c9.421,0.011,17.056,7.645,17.067,17.067h-256     c0.011-9.421,7.645-17.056,17.067-17.067H366.936z M196.269,461.805v-25.6h119.467v25.6H196.269z M494.936,394.989     c-0.427,13.726-11.872,24.523-25.6,24.15H42.669c-13.728,0.373-25.173-10.424-25.6-24.15v-18.517h477.867V394.989z      M494.936,359.405H17.069V42.222c0.427-13.726,11.872-24.523,25.6-24.15h426.667c13.728-0.373,25.173,10.424,25.6,24.15V359.405z     "></path>
                    <path d="M116.336,239.939l279.692-0.012c22.518-1.118,39.958-20.123,39.142-42.654l-0.009-84.946     c0.941-22.804-16.756-42.065-39.558-43.054l-279.692,0.012c-22.518,1.118-39.958,20.123-39.142,42.654l0.008,84.946     C75.837,219.688,93.534,238.95,116.336,239.939z M93.827,111.551c-0.615-13.131,9.392-24.34,22.509-25.213l278.842-0.012     c13.402,0.745,23.664,12.21,22.925,25.612l0.009,85.721c0.614,13.131-9.392,24.339-22.508,25.213l-278.842,0.012     c-13.403-0.744-23.666-12.21-22.925-25.612L93.827,111.551z"></path>
                    <path d="M145.036,180.205h34.133v17.067c0,4.713,3.82,8.533,8.533,8.533s8.533-3.82,8.533-8.533v-17.067h8.533     c4.713,0,8.533-3.82,8.533-8.533s-3.82-8.533-8.533-8.533h-8.533v-8.533c0-4.713-3.821-8.533-8.533-8.533     s-8.533,3.821-8.533,8.533v8.533h-19.425l26.833-46.967c2.326-4.09,0.902-9.292-3.184-11.626     c-4.086-2.335-9.29-0.921-11.633,3.16l-34.133,59.733c-1.511,2.641-1.501,5.886,0.026,8.518S141.994,180.207,145.036,180.205z"></path>
                    <path d="M307.169,180.205h34.133v17.067c0,4.713,3.82,8.533,8.533,8.533s8.533-3.82,8.533-8.533v-17.067h8.533     c4.713,0,8.533-3.82,8.533-8.533s-3.821-8.533-8.533-8.533h-8.533v-8.533c0-4.713-3.82-8.533-8.533-8.533     s-8.533,3.821-8.533,8.533v8.533h-19.425l26.833-46.967c2.326-4.09,0.902-9.292-3.184-11.626     c-4.086-2.335-9.29-0.921-11.633,3.16l-34.133,59.733c-1.511,2.641-1.501,5.886,0.026,8.518S304.127,180.207,307.169,180.205z"></path>
                    <path d="M247.436,205.805h17.067c14.132-0.015,25.585-11.468,25.6-25.6v-51.2c-0.015-14.132-11.468-25.585-25.6-25.6h-17.067     c-14.132,0.015-25.585,11.468-25.6,25.6v51.2C221.851,194.337,233.304,205.79,247.436,205.805z M238.903,129.005     c0.005-4.711,3.822-8.529,8.533-8.533h17.067c4.711,0.005,8.529,3.822,8.533,8.533v51.2c-0.005,4.711-3.822,8.529-8.533,8.533     h-17.067c-4.711-0.005-8.529-3.822-8.533-8.533V129.005z"></path>
                    <path d="M409.603,265.539h-307.2c-14.132,0.015-25.585,11.468-25.6,25.6v17.067c0.015,14.132,11.468,25.585,25.6,25.6h307.2     c14.132-0.015,25.585-11.468,25.6-25.6v-17.067C435.187,277.007,423.735,265.554,409.603,265.539z M102.403,316.739     c-4.711-0.005-8.529-3.822-8.533-8.533v-17.067c0.005-4.711,3.822-8.529,8.533-8.533h26.723l-22.756,34.133H102.403z      M126.88,316.739l22.756-34.133h22.156l-22.756,34.133H126.88z M169.546,316.739l22.756-34.133h22.156l-22.756,34.133H169.546z      M212.213,316.739l22.756-34.133h22.156l-22.756,34.133H212.213z M254.88,316.739l22.756-34.133h22.156l-22.756,34.133H254.88z      M297.546,316.739l22.756-34.133h22.156l-22.756,34.133H297.546z M340.213,316.739l22.756-34.133h22.156l-22.756,34.133H340.213z      M418.136,308.205c-0.005,4.711-3.822,8.529-8.533,8.533H382.88l22.756-34.133h3.967c4.711,0.005,8.529,3.822,8.533,8.533     V308.205z"></path>
                </g>
            </svg>
            <h1>The page not found!</h1>
            <p>The page you are trying to access does not exist or has been moved.<br/>
                Try going back to our homepage.</p>
            <a class="btn btn-primary text-nowrap" [routerLink]="['//']">
                <i class="fas fa-home fs-14 me-1"></i> Back to Home
            </a>
        </div>
    </div>
</div>
