import { Pipe, PipeTransform } from '@angular/core';
import { AppointmentType } from 'src/app/api-client';
import { ReferenceDataProvider } from '../reference-data-provider';

@Pipe({
  name: 'apptType',
  pure: false
})
export class ApptTypePipe implements PipeTransform {

  constructor(private referenceDataProvider: ReferenceDataProvider){}

  transform(value?: string | number, ...args: any[]): any {
    
    if(!value) {
      return "";
    }
    
    const types: AppointmentType[] = this.referenceDataProvider.apptTypes;
    if(!types || types.length == 0) {
      return value;
    }

    for (let index = 0; index < types.length; index++) {
      if(types[index].typeId == ((typeof value === 'string') ? parseInt(value as string) : value)) {
        return types[index].type;
      }
    }
    
    return value;
  }
}
