import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { MsalService } from '@azure/msal-angular';

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(private router: Router, private authService: MsalService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

        let url: string = state.url;
        return this.checkLogin(url);
    }


    checkLogin(url: string): boolean {
        let loggedIn = this.authService.instance.getAllAccounts().length > 0;
        if (!loggedIn) {
            this.router.navigateByUrl("");
            return false;
        }
        // if (this.authService.isLoggedIn()) { return true; }

        // // Store the attempted URL for redirecting
        // this.authService.redirectUrl = url;

        // Navigate to the login page with extras
        return true;
    }
}