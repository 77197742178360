import { Injectable } from "@angular/core";
import { AppointmentType, HsappointmentModeMasterModel, HstimeZoneMasterModel, ReferenceDataService } from "../api-client";
import { ConfigurationResponseModel } from "../api-client/model/configurationResponseModel";

@Injectable()
export class ReferenceDataProvider {
    private timezones: HstimeZoneMasterModel[] = [];
    private appointmentTypes: AppointmentType[] = [];
    private appointmentModes: HsappointmentModeMasterModel[] = [];
    private azureConfigurations : ConfigurationResponseModel[] = [];

    constructor(private referenceDataService: ReferenceDataService) {
    }

    public init(): void {
        let that = this;
        setTimeout(function() {
            that.loadTimeZones();
            that.loadAppointmentTypes();
            that.loadAppointmentModes();
            that.loadAzureConfigurations();
        }, 1000);
    }

    private async loadTimeZones(): Promise<void> {
        let resultTZ = await this.referenceDataService.referenceDataGetTimeZoneList().toPromise();
        if(resultTZ != undefined){
            this.timezones = resultTZ;
        }
    }

    private async loadAppointmentTypes(): Promise<void> {
        let resultAT = await this.referenceDataService.referenceDataGetAppointmentTypes().toPromise();
        if(resultAT != undefined){
            this.appointmentTypes = resultAT;
        }
    }

    private async loadAppointmentModes(): Promise<void> {
        let resultAM = await this.referenceDataService.referenceDataGetAppointmentModes().toPromise();
        if(resultAM){
            this.appointmentModes = resultAM;
        }
    }

    private async loadAzureConfigurations() : Promise<void> {
        let resultAC = await this.referenceDataService.referenceDataGetConfigurations().toPromise();
        if(resultAC){
            this.azureConfigurations = resultAC;
        }
    }

    public async getTimezones(): Promise<HstimeZoneMasterModel[]> {
        if(!this.timezones || this.timezones.length == 0) {
            await this.loadTimeZones();
        }

        return this.timezones;
    }

    public async getAppointmentModes(): Promise<HsappointmentModeMasterModel[]> {
        if(!this.appointmentModes || this.appointmentModes.length == 0) {
            await this.loadAppointmentModes();
        }

        return this.appointmentModes;
    }

    public async getAppointmentTypes(): Promise<AppointmentType[]> {
        if(!this.appointmentTypes || this.appointmentTypes.length == 0) {
            await this.loadAppointmentTypes();
        }

        return this.appointmentTypes;
    }

    public async getAzureConfigurations(): Promise<ConfigurationResponseModel[]> {
        if(!this.azureConfigurations || this.azureConfigurations.length == 0) {
            await this.loadAzureConfigurations();
        }

        return this.azureConfigurations;
    }

    public get apptModes() {
        return this.appointmentModes;
    }

    public get apptTypes() {
        return this.appointmentTypes;
    }

    public getAzureConfigurationById(input : string){
        if(!this.azureConfigurations || this.azureConfigurations.length == 0) {
            this.loadAzureConfigurations();
        }
        return this.azureConfigurations.find(x => x.key == input)?.value;
    }
}
