
<div class="pt-3 ps-lg-2">
    <div id="notifications-panel">
        <div class="d-flex">
            <div class="flex-fill">
                <h3 class="h5">Additional Email/Phone Notifications</h3>
            </div>
            <!--<div *ngIf="appointmentStatus !== 'CANCELED'">
                <a class="d-inline-block cursor-pointer px-1 pt-04 text-dark">
                    <i data-bs-toggle="modal" data-bs-target="#notificationModal" 
                        (click)="openNotificationModal()"
                        class="fas fa-edit fs-16"></i>
                </a>
            </div>-->
        </div>
        <hr/>
        <strong>
            Email Address
        </strong>
        <div class="text-truncate">
            {{notification.emailId ? notification.emailId : "-- Not available --"}}
        </div>
        <hr/>
        <strong>
            Phone Number
        </strong>
        <div class="text-truncate">
            {{notification.mobileNo ? notification.mobileNo: "-- Not available --"}}
        </div>
    </div>
    <hr/>
    <button *ngIf="appointmentDetail.enableBLSTemplate" id="bls_template" class="btn btn-sm btn-dark rounded-pill px-4" (click)="openOutlook()" >
        BLS Template
    </button>
    <div id="comments-panel">
        <h3 class="h5 mt-3">Comment</h3>
        <textarea class="form-control" rows="3" 
            placeholder="Enter your comment here"
            [(ngModel)]="comment"
            *ngIf="appointmentStatus !== 'CANCELED'"></textarea>
        <div class="mt-2 text-end"
            *ngIf="appointmentStatus !== 'CANCELED'">
            <button class="btn btn-sm btn-dark rounded-pill px-4"
                [disabled]="!comment" (click)="saveComment()">Save</button>
        </div>
    </div>
    <div *ngFor="let note of notes">
        <hr class="hr-dash"/>
        <div class="d-flex justify-content-between fs-12 text-lightest">
            {{note.note}}
        </div>
        <div class="d-flex justify-content-between fs-12 text-lightest">
            <div>
                {{note.createdOn | usDateTime}}
            </div>
            <div>
                <i>Updated By: {{note.createdBy}}</i>
            </div>
        </div>
    </div>
</div>
<!-- NotificationModal Modal -->
<div class="modal fade" id="notificationModal" tabindex="-1" aria-labelledby="ariaNotificationModal" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
            <h5 class="modal-title" id="ariaNotificationModal">Update Email/Phone</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <form [formGroup]="updateEmailPhoneForm" (ngSubmit)="onSubmit()">
                <div class="modal-body">
                    <div class="mb-3">
                        <label for="emailAddress" class="form-label fw-bold">Email address</label>
                        <input type="text" id="emailAddress" 
                            placeholder="example@example.com" 
                            class="form-control" 
                            formControlName="emailId" />
                    </div>
                    <div>
                        <label for="phoneNumber" class="form-label fw-bold">Phone Number</label>
                        <input type="tel" id="phoneNumber" 
                            placeholder="(XXX) XXX-XXXX"
                            maxlength="10"
                            class="form-control" 
                            formControlName="mobileNo" />
                    </div>
                </div>
                <div class="modal-footer text-end">
                    <button type="submit" class="btn btn-dark rounded-pill px-4 my-0"
                            [disabled]="!updateEmailPhoneForm.valid">Confirm</button>
                </div>
            </form>
        </div>
    </div>
  </div>