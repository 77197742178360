/**
 * Somatus Hybrid Scheduler API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CareMemberScheduleModel } from './careMemberScheduleModel';
import { InputTaskModel } from './inputTaskModel';

export interface CreateAppointmentRequest { 
    id: number;
    draftAppointmentId?: number;
    patientId: number;
    taskIds?: Array<InputTaskModel>;
    typeId: number;
    modeId?: string;
    scheduleDateTime: Date;
    scheduleTimeZoneId?: string;
    schedulePreTime?: number;
    scheduleDuration?: number;
    schedulePostTime?: number;
    preferredLanguageCode?: string;
    address?: string;
    zip?: string;
    lat?: string;
    lon?: string;
    careMembers?: Array<CareMemberScheduleModel>;
    note?: string;
    addressType?: string;
    mobileNo?: string;
    email?: string;
    notificationMobileNo?: string;
    notificationEmailId?: string;
    textOptOutStatus: boolean;
    callOptOutStatus: boolean;
    isOverrideAppointment: boolean;
    elasticId?: string;
}