/**
 * Somatus Hybrid Scheduler API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { EventAttandes } from './eventAttandes';

export interface CalenderEvent { 
    eventId: string;
    patientId?: number;
    isHSSchedule: boolean;
    fromDate: string;
    toDate: string;
    title: string;
    showAs: string;
    startDateTime: Date;
    endDateTime: Date;
    attendee: Array<EventAttandes>;
    createdDateTime?: Date;
    lastModifiedDateTime?: Date;
    isAllDay?: boolean;
    isOnlineMeeting?: boolean;
    recurrence: string;
    bodyPreview: string;
    iCalUId: string;
    isCancelled?: boolean;
    changeKey: string;
    seriesMasterId: string;
}