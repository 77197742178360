import { Component, OnInit } from '@angular/core';
import { GenericModal } from 'src/app/commons-ui/generic-modal/generic-modal';
import { GenericModalButtonInfo } from 'src/app/commons-ui/generic-modal/generic-modal-service';
import { Loader } from 'src/app/commons-ui/loader';
import { CareTeamService, PatientModel } from '../../api-client';
import { CareMemberInfo } from '../../api-client/model/careMemberInfo';

@Component({
  selector: 'hs-add-care-member',
  templateUrl: './add-care-member.component.html',
  styleUrls: ['./add-care-member.component.scss']
})
export class AddCareMemberComponent implements OnInit, GenericModal<PatientModel, CareMemberInfo> {

  private outputHandler?: (data: CareMemberInfo) => void;
  public _typedText: string = "";
  private timeoutRef: any;
  public typeId: number = 0;
  public careTeamMemberList : any;
  public careMembers?: CareMemberInfo[];
  public selectedCareMember?: CareMemberInfo;
  public keys: string[] = [];
  private patient?: PatientModel;
  public isProximity: boolean = false;
  constructor(private careTeamService: CareTeamService) { }

  public getTitle(): string {
    return "Add a new member to the Care team";
  }

  public getSubTitle(): string {
    return "Members added here will be added to the Calendar view";
  }

  public setInputData(data: PatientModel): void {
      this.patient = data;
  }

  public getLeftButtons(): GenericModalButtonInfo[] {
    return [
      {
        label: "Close",
        cssClasses: "btn btn-outline-dark rounded-pill px-4",
        action: () => {
          return Promise.resolve(true);
        }
      }
    ];
  }

  public getRightButtons(): GenericModalButtonInfo[] {
    return [
      {
        label: "Submit",
        cssClasses: "btn btn-dark rounded-pill px-4",
        action: () => {
          if(!this.selectedCareMember) {
            return Promise.resolve(false);
          }
          this.outputHandler?.(this.selectedCareMember);
          return Promise.resolve(true);
        }
      }
    ];
    }

    public set typedText(text: string) {
        if (this.timeoutRef) {
            clearTimeout(this.timeoutRef);
            this.timeoutRef = null;
        }
        this.careMembers = undefined;
        if (text.length < 3) {
            return;
        }

        this._typedText = text;
        this.timeoutRef = setTimeout(this.search.bind(this), 500);

    }
    public async search(): Promise<void> {
        if (this._typedText!="") {
            Loader.showLoader("care-members-results-panel");
            try {
                this.careMembers = await this.careTeamService.careTeamGetCareTeamMember(this._typedText, this.typeId).toPromise();
            } finally {
                Loader.hideLoader("care-members-results-panel");
            }
        }
   
  }
  public setOutputDataHandler(handler: (data: CareMemberInfo) => void): void {
    this.outputHandler = handler;
  }

  public onCloseClicked(): Promise<boolean> {
    return Promise.resolve(true);
  }
  private fetchCareMemberList(){
      this.careTeamService.careTeamGetCareMemberTypes().subscribe((data: any) => {
          this.careTeamMemberList = data;
      });
  }
  public get typedText() {
        return this._typedText;
  }
  ngOnInit(): void {
    this.fetchCareMemberList();
    }

  public async searchProximity(): Promise<void> {
    const stateIds = this.patient?.state;
    const stateId = parseInt(stateIds![0]);
    Loader.showLoader("care-members-results-panel");
    try {
        this.careMembers = await this.careTeamService.careTeamProximitySearch(stateId, this.patient!.address).toPromise();
    } finally {
        Loader.hideLoader("care-members-results-panel");
    }
  }

   public onChangeCareMember() {
       this._typedText = "";
       this.careMembers = undefined;
       this.search();
       if (this.typeId == 244) {
           this.isProximity = true;
       }
       else {
           this.isProximity = false;
       }
    }

    public onProximityChange() {
        this.isProximity = !this.isProximity;
        this._typedText = "";
        this.careMembers = undefined;
    }
  handleKeyDown(event: KeyboardEvent) {
    //This method is created to add handle key dowm functionality in future
  }

  handleKeyUp(event: KeyboardEvent) {
    //This method is created to add handle key up functionality in future
  }

  handleKeyPress(event: KeyboardEvent) {
    //This method is created to add handle key press functionality in future
  }
}
