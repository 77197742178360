<div class="bg-offwhite p-3 pt-2">
    <div class="d-md-flex">
        <div class="flex-fill mt-1 mb-2">
            <ol class="breadcrumb text-uppercase fs-14">
                <li class="breadcrumb-item"><a href="#"><i class='fas fa-home'></i></a></li>
                <li class="breadcrumb-item active text-dark"><b>Appointments</b></li>
            </ol>
        </div>
        <div class="d-flex align-items-start mt--md-2 breadcrumb__highlight-wrap position-relative">
            <div class="breadcrumb__highlight  py-1" *ngFor="let status of todayStatuses; let index = index">
                <div class="fs-12 text-lighter mt-125">{{status.label}}</div>
                <strong>{{status.count}} <span class="fs-12">Appointment</span></strong>
            </div>
            <div id="filter" class="breadcrumb__filter d-md-none collapsed" data-bs-toggle="collapse" data-bs-target="#filterOption">
                <i class="fas fa-filter"></i>
            </div>
        </div>
    </div>
    <div class="bg-white p-3 col-height">
        <div class="table__head pt-0">
            <div class="row mt--1">
                <div class="col-xl-7 order-xl-2">
                    <div id="filterOption" class="d-md-block collapse">
                        <div class="d-md-flex">
                            <div class="input-group mt-2 mt-lg-0">
                                <span class="input-group-text" id="basic-addon1">
                                    <i class="fas fa-search"></i>
                                </span>
                                <input type="text" class="form-control search-input-field" 
                                    placeholder="Search Appointment" aria-label="Username" 
                                    aria-describedby="basic-addon1" [ngModel]="text"
                                    (ngModelChange)="searchText($event)"
                                >
                            </div>
                            <div class="date-range">
                                <hs-date-range (dateRangeSelected)="dateRangeSelectionEvent($event)"></hs-date-range>
                            </div>
                            <div class="form-check mt-2">
                                <input type="checkbox" class="form-check-input" 
                                    id="createdByCheckbox"
                                    aria-label="Username" 
                                    aria-describedby="basic-addon1"
                                    [value]="createdByCheckbox" (change)="onCheckboxChange()" />
                                <label class="text-nowrap" for="createdByCheckbox">Created By Me</label>
                            </div>
                            
                        </div>
                        <hr class="d-md-none" />
                    </div>
                </div>
                <div class="col-xl-5 order-xl-1">
                    <ul class="nav table__tabs" id="myTab" role="tablist">
                        <li *ngFor="let status of statuses; let tab = index;" class="table__tabs-list" role="presentation">
                            <button class="table__tabs-item" [ngClass]="{'active': tab == 0}"
                                data-bs-toggle="tab" type="button" role="tab" aria-controls="Upcoming" aria-selected="true"
                                (click)="setStatus(status.code)">
                                {{status.label}}
                                <span *ngIf="status.count > 0" class="badge ms-1">{{status.count}}</span>
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        
        <div id="appointments-panel" class="table-content bg-white pt-3" role="tabpanel" aria-labelledby="Upcoming-tab">
            <div class="row g-2 d-lg-block d-none">
                <div class="col-lg-11">
                    <div class="row g-2">
                        <div class="col-1">
                            <strong class="text-nowrap">Patient ID</strong>
                        </div>
                        <div class="col-2">
                            <strong>Patient Name</strong>
                        </div>
                        <div class="col-3">
                            <strong>Task Name(s)</strong>
                        </div>
                        <div class="col-4">
                            <strong>Address</strong>
                        </div>
                        <div class="col-2 text-nowrap">
                            <strong>Appointment Date/Time</strong>
                        </div>
                    </div>
                </div>
            </div>
            <hr class="d-none d-lg-flex" />
            <div *ngIf="appointmentsList?.totalResults == 0" class="alert alert-success text-center">No matching appointment found!</div>
            <ng-container *ngFor="let appointment of appointmentsList?.results">
                <div class="row g-2 appointment-row">
                    <div class="col-lg-11">
                        <div class="row g-2"
                        [ngClass]="{'text-danger': appointment.status === CANCELLED,
                                                    'text-success': appointment.status === 'ACCEPTED'}"
                         >
                            <div class="col-lg-1">
                                <div class="d-flex">
                                    <div class="d-lg-none">
                                        <strong class="table__info">Patient ID</strong>
                                    </div>
                                    <div>
                                        {{appointment.enrollementNumber}}
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-2">
                                <div class="d-flex">
                                    <div class="d-lg-none">
                                        <strong class="table__info">Patient Name</strong>
                                    </div>
                                    <div class="text-truncate-md">
                                        {{appointment.patientName}}
                                    </div>
                                </div> 
                            </div>
                            <div class="col-lg-3">
                                <div class="d-flex">
                                    <div class="d-lg-none">
                                        <strong class="table__info">Task Name(s)</strong>
                                    </div>
                                    <div class="text-truncate-md">
                                        {{appointment.type}}/{{appointment.mode}}
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="d-flex">
                                    <div class="d-lg-none">
                                        <strong class="table__info">Address</strong>
                                    </div>
                                    <div class="text-truncate-md">
                                        {{appointment.address}}
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-2">
                                <div class="d-flex">
                                    <div class="d-lg-none">
                                        <strong class="table__info">Date/Time</strong>
                                    </div>
                                    <div class="text-truncate-md">
                                        {{appointment.scheduleDateTime | usDateTime}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-1 text-lg-end">
                        <strong class="table__info d-none d-sm-inline-block d-lg-none"></strong>
                        <a class="btn btn-sm btn-outline-primary table__btn" [routerLink]="['/appointments',appointment.id]">
                            <i class="fas fa-bars"></i> <span class="d-lg-none ms-1">View Details</span>
                        </a>
                    </div>
                </div>
                <hr/>
            </ng-container>
            <nav *ngIf="appointmentsList?.totalResults !== 0" aria-label="Page navigation example">
                <ul class="pagination justify-content-center justify-content-md-end mt-2 mb-0">
                    <li class="page-item" [ngClass]="{'disabled': pageNumber === 0}">
                    <button class="page-link" tabindex="-1" aria-disabled="true" (click)="gotoPage(pageNumber - 1)">
                        <i class="fas fa-arrow-left"></i>
                    </button>
                    </li>
                    <ng-container *ngFor="let page of pageNumbers">
                        <li *ngIf="page >= 0" class="page-item d-none d-sm-block" 
                            [ngClass]="{'active': pageNumber === page}">
                            <button class="page-link" (click)="gotoPage(page)">{{page + 1}}</button>
                        </li>
                        <li *ngIf="page < 0" class="page-item disabled d-none d-sm-block">
                            <a class="page-link bg-white" href="#" tabindex="-1" aria-disabled="true">...</a>
                        </li>
                    </ng-container>
                    <li class="page-item" [ngClass]="{'disabled': pageNumber >= ((appointmentsList?.totalPages ?? 0) - 1)}">
                        <button class="page-link" (click)="gotoPage(pageNumber + 1)">
                            <i class="fas fa-arrow-right"></i>
                        </button>
                    </li>
                </ul>
            </nav>
        </div>
    </div>
</div>