import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'title'
})
export class TitlePipe implements PipeTransform {

  transform(value: string, ...args: any[]): any {
    if(!value) {
      return "";
    }
    
    const parts = value.split(/\s/);

    let initial: string = parts
        .map(part => {
            let word: string = part.substr(0, 1).toUpperCase();
            if(part.length > 1) {
                word += part.substr(1).toLowerCase();
            }
            return word;
        })
        .join(" ");
    
    return initial;
  }
}
