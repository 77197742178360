import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GenericModalService } from './generic-modal/generic-modal-service';
import { ComponentHostDirective } from './component-host';
import { GenericModalComponent } from './generic-modal/generic-modal.component';
import { TaskStatusComponent } from './task-status/task-status.component';
import { NavigationMapComponent } from './navigation-map/navigation-map.component';
import { TimeFieldDropdownComponent } from './time-field-dropdown/time-field-dropdown.component';



@NgModule({
    declarations: [
        ComponentHostDirective,
        GenericModalComponent,
        TaskStatusComponent,
        NavigationMapComponent,
        TimeFieldDropdownComponent,
    ],
    imports: [
        CommonModule
    ],
    exports: [
        GenericModalComponent,
        TaskStatusComponent,
        NavigationMapComponent,
        TimeFieldDropdownComponent,
    ],
    providers: [
        GenericModalService
    ]
})
export class CommonsUiModule { }
