import { DatePipe } from "@angular/common";
import { Injectable } from "@angular/core";
import { DateTime } from "../date-time";

@Injectable()
export class CommonDateTimeFormatter {
    constructor(private datePipe: DatePipe){}

    public transform(value: any, format: string, offsetMillis?: number): string {
        if(typeof(value) == 'string') {
            value = value + "Z";
        } else if(value instanceof Date) {
            value = new Date(this.roundTimeQuarterHour(value.getTime() + (offsetMillis ?? 0)));
        }
        return this.datePipe.transform(value, format) ?? "";
    }

    public roundTimeQuarterHour(timeinMS: number):Date {
        var timeToReturn = new Date(timeinMS);
    
        timeToReturn.setMilliseconds(Math.round(timeToReturn.getMilliseconds() / 1000) * 1000);
        timeToReturn.setSeconds(Math.round(timeToReturn.getSeconds() / 60) * 60);
        timeToReturn.setMinutes(Math.round(timeToReturn.getMinutes() / 15) * 15);
        return timeToReturn;
    }

    public static calculateOffset(timezone: string): number {
        let date = new Date();
        let tzDate = new Date(date.toLocaleString('en-US', { timeZone: timezone }));
        return date.getTime() - tzDate.getTime();
    }
}