/**
 * Somatus Hybrid Scheduler API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { AppointmentDetail } from '../model/appointmentDetail';
import { AppointmentInfo } from '../model/appointmentInfo';
import { AppointmentNoteRequest } from '../model/appointmentNoteRequest';
import { AppointmentNoteResponse } from '../model/appointmentNoteResponse';
import { AppointmentResendInviteRequestModel } from '../model/appointmentResendInviteRequestModel';
import { AppointmentResponseModel } from '../model/appointmentResponseModel';
import { AppointmentsListResponse } from '../model/appointmentsListResponse';
import { CancelAppointmentRequestModel } from '../model/cancelAppointmentRequestModel';
import { CreateAppointmentRequest } from '../model/createAppointmentRequest';
import { TodayAppointmentsResponse } from '../model/todayAppointmentsResponse';
import { UpdateAppointmentContactInfoRequest } from '../model/updateAppointmentContactInfoRequest';
import { ValueTupleOfStringAndLong } from '../model/valueTupleOfStringAndLong';
import { ViewAppointment } from '../model/viewAppointment';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class AppointmentService {

    protected basePath = 'http://localhost:5001/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public appointmentAppointmentNote(body: AppointmentNoteRequest, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public appointmentAppointmentNote(body: AppointmentNoteRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public appointmentAppointmentNote(body: AppointmentNoteRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public appointmentAppointmentNote(body: AppointmentNoteRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling appointmentAppointmentNote.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<boolean>('post',`${this.basePath}/api/Appointment/note`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public appointmentAppointmentNotification(body: UpdateAppointmentContactInfoRequest, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public appointmentAppointmentNotification(body: UpdateAppointmentContactInfoRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public appointmentAppointmentNotification(body: UpdateAppointmentContactInfoRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public appointmentAppointmentNotification(body: UpdateAppointmentContactInfoRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling appointmentAppointmentNotification.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<boolean>('post',`${this.basePath}/api/Appointment/notification`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param patientId 
     * @param status 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public appointmentCancelAppointment(patientId?: number, status?: string, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public appointmentCancelAppointment(patientId?: number, status?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public appointmentCancelAppointment(patientId?: number, status?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public appointmentCancelAppointment(patientId?: number, status?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (patientId !== undefined && patientId !== null) {
            queryParameters = queryParameters.set('patientId', <any>patientId);
        }
        if (status !== undefined && status !== null) {
            queryParameters = queryParameters.set('status', <any>status);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<boolean>('delete',`${this.basePath}/api/Appointment/cancel`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public appointmentCancelAppointment2(body: CancelAppointmentRequestModel, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public appointmentCancelAppointment2(body: CancelAppointmentRequestModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public appointmentCancelAppointment2(body: CancelAppointmentRequestModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public appointmentCancelAppointment2(body: CancelAppointmentRequestModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling appointmentCancelAppointment2.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<boolean>('post',`${this.basePath}/api/Appointment/cancel`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public appointmentCreateAppointments(body: CreateAppointmentRequest, observe?: 'body', reportProgress?: boolean): Observable<AppointmentResponseModel>;
    public appointmentCreateAppointments(body: CreateAppointmentRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AppointmentResponseModel>>;
    public appointmentCreateAppointments(body: CreateAppointmentRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AppointmentResponseModel>>;
    public appointmentCreateAppointments(body: CreateAppointmentRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling appointmentCreateAppointments.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<AppointmentResponseModel>('post',`${this.basePath}/api/Appointment`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param isTestTelehealth 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public appointmentDraftAppointments(body: CreateAppointmentRequest, isTestTelehealth?: boolean, observe?: 'body', reportProgress?: boolean): Observable<ValueTupleOfStringAndLong>;
    public appointmentDraftAppointments(body: CreateAppointmentRequest, isTestTelehealth?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ValueTupleOfStringAndLong>>;
    public appointmentDraftAppointments(body: CreateAppointmentRequest, isTestTelehealth?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ValueTupleOfStringAndLong>>;
    public appointmentDraftAppointments(body: CreateAppointmentRequest, isTestTelehealth?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling appointmentDraftAppointments.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (isTestTelehealth !== undefined && isTestTelehealth !== null) {
            queryParameters = queryParameters.set('isTestTelehealth', <any>isTestTelehealth);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<ValueTupleOfStringAndLong>('post',`${this.basePath}/api/Appointment/draft`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public appointmentGetAppointmentDetail(id: number, observe?: 'body', reportProgress?: boolean): Observable<AppointmentDetail>;
    public appointmentGetAppointmentDetail(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AppointmentDetail>>;
    public appointmentGetAppointmentDetail(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AppointmentDetail>>;
    public appointmentGetAppointmentDetail(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling appointmentGetAppointmentDetail.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<AppointmentDetail>('get',`${this.basePath}/api/Appointment/detail/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param appointmentId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public appointmentGetAppointmentHistory(appointmentId: number, observe?: 'body', reportProgress?: boolean): Observable<Array<ViewAppointment>>;
    public appointmentGetAppointmentHistory(appointmentId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ViewAppointment>>>;
    public appointmentGetAppointmentHistory(appointmentId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ViewAppointment>>>;
    public appointmentGetAppointmentHistory(appointmentId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (appointmentId === null || appointmentId === undefined) {
            throw new Error('Required parameter appointmentId was null or undefined when calling appointmentGetAppointmentHistory.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<ViewAppointment>>('get',`${this.basePath}/api/Appointment/history/${encodeURIComponent(String(appointmentId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public appointmentGetAppointmentNotes(id: number, observe?: 'body', reportProgress?: boolean): Observable<Array<AppointmentNoteResponse>>;
    public appointmentGetAppointmentNotes(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<AppointmentNoteResponse>>>;
    public appointmentGetAppointmentNotes(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<AppointmentNoteResponse>>>;
    public appointmentGetAppointmentNotes(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling appointmentGetAppointmentNotes.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<AppointmentNoteResponse>>('get',`${this.basePath}/api/Appointment/notes/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param status 
     * @param text 
     * @param pageNumber 
     * @param pageSize 
     * @param startDate 
     * @param endDate 
     * @param createdByMe 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public appointmentGetAppointments(status?: string, text?: string, pageNumber?: number, pageSize?: number, startDate?: Date, endDate?: Date, createdByMe?: boolean, observe?: 'body', reportProgress?: boolean): Observable<AppointmentsListResponse>;
    public appointmentGetAppointments(status?: string, text?: string, pageNumber?: number, pageSize?: number, startDate?: Date, endDate?: Date, createdByMe?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AppointmentsListResponse>>;
    public appointmentGetAppointments(status?: string, text?: string, pageNumber?: number, pageSize?: number, startDate?: Date, endDate?: Date, createdByMe?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AppointmentsListResponse>>;
    public appointmentGetAppointments(status?: string, text?: string, pageNumber?: number, pageSize?: number, startDate?: Date, endDate?: Date, createdByMe?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {








        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (status !== undefined && status !== null) {
            queryParameters = queryParameters.set('status', <any>status);
        }
        if (text !== undefined && text !== null) {
            queryParameters = queryParameters.set('text', <any>text);
        }
        if (pageNumber !== undefined && pageNumber !== null) {
            queryParameters = queryParameters.set('pageNumber', <any>pageNumber);
        }
        if (pageSize !== undefined && pageSize !== null) {
            queryParameters = queryParameters.set('pageSize', <any>pageSize);
        }
        if (startDate !== undefined && startDate !== null) {
            queryParameters = queryParameters.set('startDate', <any>startDate.toISOString());
        }
        if (endDate !== undefined && endDate !== null) {
            queryParameters = queryParameters.set('endDate', <any>endDate.toISOString());
        }
        if (createdByMe !== undefined && createdByMe !== null) {
            queryParameters = queryParameters.set('createdByMe', <any>createdByMe);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<AppointmentsListResponse>('get',`${this.basePath}/api/Appointment`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param meetingid 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public appointmentGetMeetingRecording(meetingid: string, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public appointmentGetMeetingRecording(meetingid: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public appointmentGetMeetingRecording(meetingid: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public appointmentGetMeetingRecording(meetingid: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (meetingid === null || meetingid === undefined) {
            throw new Error('Required parameter meetingid was null or undefined when calling appointmentGetMeetingRecording.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<string>('get',`${this.basePath}/api/Appointment/GetRecording/${encodeURIComponent(String(meetingid))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param patientId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public appointmentGetPatientAppointments(patientId: number, observe?: 'body', reportProgress?: boolean): Observable<Array<AppointmentInfo>>;
    public appointmentGetPatientAppointments(patientId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<AppointmentInfo>>>;
    public appointmentGetPatientAppointments(patientId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<AppointmentInfo>>>;
    public appointmentGetPatientAppointments(patientId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (patientId === null || patientId === undefined) {
            throw new Error('Required parameter patientId was null or undefined when calling appointmentGetPatientAppointments.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<AppointmentInfo>>('get',`${this.basePath}/api/Appointment/patient/${encodeURIComponent(String(patientId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public appointmentGetTodayAppointments(observe?: 'body', reportProgress?: boolean): Observable<TodayAppointmentsResponse>;
    public appointmentGetTodayAppointments(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TodayAppointmentsResponse>>;
    public appointmentGetTodayAppointments(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TodayAppointmentsResponse>>;
    public appointmentGetTodayAppointments(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<TodayAppointmentsResponse>('get',`${this.basePath}/api/Appointment/today`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public appointmentResendInvite(body: AppointmentResendInviteRequestModel, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public appointmentResendInvite(body: AppointmentResendInviteRequestModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public appointmentResendInvite(body: AppointmentResendInviteRequestModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public appointmentResendInvite(body: AppointmentResendInviteRequestModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling appointmentResendInvite.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<boolean>('post',`${this.basePath}/api/Appointment/resendinvite`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param patientId 
     * @param noOfDays 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public appointmentUpcomingAppointments(patientId?: number, noOfDays?: number, observe?: 'body', reportProgress?: boolean): Observable<Array<AppointmentInfo>>;
    public appointmentUpcomingAppointments(patientId?: number, noOfDays?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<AppointmentInfo>>>;
    public appointmentUpcomingAppointments(patientId?: number, noOfDays?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<AppointmentInfo>>>;
    public appointmentUpcomingAppointments(patientId?: number, noOfDays?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (patientId !== undefined && patientId !== null) {
            queryParameters = queryParameters.set('patientId', <any>patientId);
        }
        if (noOfDays !== undefined && noOfDays !== null) {
            queryParameters = queryParameters.set('noOfDays', <any>noOfDays);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<AppointmentInfo>>('get',`${this.basePath}/api/Appointment/upcomingappointment`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public appointmentUpdateAppointments(body: CreateAppointmentRequest, observe?: 'body', reportProgress?: boolean): Observable<AppointmentResponseModel>;
    public appointmentUpdateAppointments(body: CreateAppointmentRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AppointmentResponseModel>>;
    public appointmentUpdateAppointments(body: CreateAppointmentRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AppointmentResponseModel>>;
    public appointmentUpdateAppointments(body: CreateAppointmentRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling appointmentUpdateAppointments.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<AppointmentResponseModel>('put',`${this.basePath}/api/Appointment`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
