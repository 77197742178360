/**
 * Somatus Hybrid Scheduler API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface PatientContactInfo { 
    patientContactId: number;
    patienId: number;
    contactTypeId: number;
    phoneType?: string;
    patientContactTypeName?: string;
    designateContactMethodId?: number;
    designateContactTypeId?: number;
    name?: string;
    preferredContact?: string;
    isPrimary: boolean;
    isCallOptOut: boolean;
    isTextOptOut: boolean;
    statusId?: number;
    status?: string;
}