<style>
    input.ng-invalid.ng-touched {
        border: 1px solid rgb(234, 84, 85);
    }
    .error{
        border: 1px solid rgb(234, 84, 85);
    }
</style>
<div class="schedule-calendar mt-2">
    <div id="schedule-timeline">
        <mbsc-eventcalendar #scheduleTimeline
                        *ngIf="schedulerVisible"
                        [resources]="resources"
                        [data]="events"
                        [options]="calendarOptions"
                        [(selectedDate)]="calendarSelectedDate"
                        [headerTemplate]="headerTemplate"
                        [resourceTemplate]="resourceTemplate"
                        [timezonePlugin]="momentPlugin"
                        [colors]="myColors"
                        [displayTimezone]="timezone!"
                        dataTimezone="utc">
        <ng-template #headerTemplate>
            <div class="d-md-flex w-100">
                <div class="flex-fill input-datepicker">

                    <input mbsc-datepicker #picker="mobiscroll" readonly [controls]="['calendar']" [(ngModel)]="selectedMonth" dateFormat="MMMM YYYY" (change)="dateTimeChanged()" [min]="minDate" />
                    <div class="d-flex text-lightest p-2 pt-0 pb-0">
                        <div class="text-muted">
                            <small>
                                Click on a calendar slot to add a new
                                appointment
                            </small>
                        </div>
                    </div>
                </div>
                <div class="p-2">
                    <div class="d-flex">
                        <div class="d-flex me-3">
                            <mbsc-calendar-prev class="calendar-arrow-button"></mbsc-calendar-prev>
                            <mbsc-calendar-today class="md-custom-header-today"></mbsc-calendar-today>
                            <mbsc-calendar-next class="calendar-arrow-button"></mbsc-calendar-next>
                        </div>
                        <select id="timezone"
                                class="form-select form-select-sm"
                                name="timezone"
                                [(ngModel)]="timezone"
                                (change)="timezoneChanged()">
                            <option *ngFor="let timezone of timezones"
                                    [ngValue]="timezone.code">
                                {{ timezone.name }}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
        </ng-template>
    </mbsc-eventcalendar>
    </div>
    <div class="d-flex px-sm-2 mx-sm-1 text-sm-center">
        <button class="btn btn-link" (click)="launchAddCareMember()">
            <i class="fas fa-users me-1"></i> Add New Care Team Member
        </button>
        <button class="btn btn-link"
                data-bs-toggle="modal" data-bs-target="#bestTimeScheduleModal"
                (click)="scheduleService.openBestTimeScheduleModal('open')">
            <i class="fas fa-users me-1"></i> Best Time to Schedule
        </button>
        <button *ngIf="nPSearchLink"
                class="btn btn-link"
                data-bs-toggle="modal"
                data-bs-target="#centralNurseScheduleModal"
                (click)="
                scheduleService.openCentralNurseScheduleModal('open', 'NP')
            ">
            <i class="fas fa-users me-1"></i> Search NP Member(s)
        </button>
    </div>
    <hr class="m-0" />
    <div id="button-panel" class="d-flex justify-content-between py-2 px-3">
        <button type="button"
                class="btn btn-sm btn-outline-dark rounded-pill px-4"
                (click)="clearChanges()">
            Reset Changes
        </button>
        <button type="button"
                class="btn btn-sm btn-dark rounded-pill px-4"
                data-bs-toggle="modal"
                (click)="showVideoLink()"
                data-bs-target="#scheduleModal">
            {{ appointmentDetails ? 'Update' : 'Book' }} Appointment
        </button>
    </div>
</div>

<ng-template #resourceTemplate let-resource>
    <div class="pe-5">
        <span [innerHTML]="resource.name"></span><span *ngIf="resource.loading">
            &nbsp;<i class="fas fa-spinner fa-pulse"></i>
        </span>
    </div>
    <div class="position-relative">
        <div [innerHTML]="resource.description"></div>
        <div>
            <div (click)="openPopUpCalendar(resource)" class="btn-delete">
                <i class="fa fa-calendar-alt fs-11"></i>
            </div>
            <div class="btn-menu">
                <div type="button" data-bs-toggle="dropdown">
                    <i class="fas fa-ellipsis-v fs-11"></i>
                </div>
                <ul class="dropdown-menu">
                    <li>
                        <a class="dropdown-item"
                           href="javascript:void(0)"
                           (click)="openModelToRemoveCareMemberRow(resource)">Delete</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</ng-template>

<!-- TaskModal Modal -->
<div class="modal fade"
     id="scheduleModal"
     tabindex="-1"
     aria-labelledby="ariaScheduleModal"
     aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
            <form autocomplete="off"
                  #addUpdateAppointment="ngForm"
                  name="addUpdateAppointment"
                  (ngSubmit)="saveChanges(addUpdateAppointment,false)">
                <div class="modal-header">
                    <h5 class="modal-title" id="ariaScheduleModal">
                        Book Appointment
                    </h5>
                    <button type="button"
                            class="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="d-flex">
                        <div>
                            <div class="user mt-0">
                                {{ patientModel?.name | initial }}
                            </div>
                        </div>
                        <div class="d-flex flex-fill">
                            <div class="box flex-fill pt-0">
                                <div>
                                    <strong class="d-inline-block mw-35">
                                        {{
                                        patientModel?.name
                                        }}
                                    </strong>
                                </div>
                                <div class="small">
                                    <strong class="d-inline-block mw-35">
                                        DOB :
                                        {{
                                            patientModel?.dateofBirth
                                                | date: 'MM/dd/yyyy'
                                        }}
                                    </strong>
                                    (Age:
                                    <span>{{ patientModel?.age }}</span> Years)
                                    / {{ patientModel?.gender }}
                                </div>
                                <div>
                                    <strong class="d-inline-block small">
                                        {{
                                        addressType
                                        }}
                                    </strong>: {{ address }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="!appointmentDetails">
                        <h6 class="mt-2 p-2 bg-secondary d-flex justify-content-between">
                            <div>Task List</div>
                        </h6>
                        <div *ngIf="!selectedTasks || selectedTasks.length == 0"
                             class="alert alert-primary">
                            No task selected!
                        </div>
                        <ng-container *ngFor="let task of selectedTasks">
                            <div class="d-flex p-2">
                                <div class="flex-fill">
                                    <strong>{{ task.task.task }}</strong> -
                                    {{ task.task.description }}
                                    <hs-task-status [status]="task.task.statusId"></hs-task-status>
                                </div>
                                <div class="text-end">
                                    <div class="text-end">
                                        <div class="fs-12">
                                            {{
                                                task.task.dueDate
                                                    | date: 'MM/dd/yyyy'
                                            }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr class="m-0" />
                        </ng-container>
                    </div>
                    <h6 class="mt-2 p-2 bg-secondary">
                        Care Team Member Schedule
                    </h6>
                    <div *ngIf="!updatedEvents || updatedEvents.length == 0"
                         class="alert alert-danger">
                        Please book care team member calendar
                    </div>

                    <ng-container *ngFor="let event of updatedEvents">
                        <div class="d-flex p-2">
                            <div class="flex-fill">
                                <strong>
                                    {{
                                    careMembersMap.get(event.resource)?.name
                                    }}
                                </strong>
                                - {{ event.modeId | apptMode }}
                            </div>
                            <div class="text-end">
                                <div class="fs-12">
                                    {{
                                        event.start
                                            | usDateTime: -this.timezoneOffset
                                    }}-{{
                                        event.end | time: -this.timezoneOffset
                                    }}
                                </div>
                            </div>
                        </div>
                        <hr class="m-0" />
                    </ng-container>
                    <div class="d-flex fst-italic text-lightest mt-3"
                         *ngIf="updatedEvents && updatedEvents.length > 0">
                        <div class="me-1">
                            <i class="fas fa-hand-point-right"></i>
                        </div>
                        <div>
                            The following tasks will be updated in RC when
                            appointment is booked
                        </div>
                    </div>
                    <h6 class="mt-2 p-2 bg-secondary">
                        Add Notification by Email/Phone
                    </h6>
                    <div class="row">
                        <div class="col-12 col-md-6">
                            <label for="emailAddress" class="form-label fw-bold">Email address</label>
                            <input type="email"
                                   class="form-control"
                                   name="emailAddress"
                                   #emailAddress
                                   id="emailAddress"
                                   pattern='^(([^<>()[\]\\.,;:\s@" ]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$'
                                   placeholder="example@example.com"
                                   [(ngModel)]="notificationEmail"
                                   [ngClass]="{'error': showErrorEmail}"
                                   (focus)="formTouched()" />
                            <span class="text-danger"
                                  *ngIf="
                                    addUpdateAppointment.controls[
                                        'emailAddress'
                                    ].invalid
                                ">Invalid email address</span>
                        </div>
                        <div class="col-12 col-md-6">
                            <label for="phoneAddress" class="form-label fw-bold">Phone Number</label>
                            <input type="text"
                                   name="phoneAddress"
                                   class="form-control"
                                   minlength="10"
                                   mask="(XXX) XXX-XXXX"
                                   #phoneAddress
                                   id="phoneAddress"
                                   placeholder="(XXX) XXX-XXXX"
                                   [ngClass]="{'error': showErrorPhone}"
                                   [(ngModel)]="notificationMobile"
                                   (focus)="formTouched()" />
                            <span class="text-danger"
                                  *ngIf="
                                    addUpdateAppointment.controls[
                                        'phoneAddress'
                                    ].invalid
                                ">Invalid phone number</span>
                        </div>
                    </div>
                    <div>
                        <span class="text-danger" *ngIf="showError">Email/phone entered matches patient email/phone, which will result in duplication of communication to patient. Please enter a different email/phone</span>
                    </div>
                </div>
                <div class="modal-footer text-end">
                    <button *ngIf="testVideoLinkRequired"
                            type="button"
                            class="btn btn-dark rounded-pill px-4"
                            data-bs-dismiss="modal"
                            [disabled]="updatedEvents.length == 0"
                            (click)="draftChanges()">
                        Test Telehealth
                    </button>
                    <button type="submit"
                            class="btn btn-dark rounded-pill px-4"
                            [disabled]="
                            !addUpdateAppointment.valid ||
                            updatedEvents.length == 0
                        ">
                        Confirm
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>

<div class="modal fade" id="schedule-conflict-response-modal">
    <div class="modal-dialog modal-dialog-centered modal-md">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title w-100 text-center" id="ariaconflictresponsemodal">
                    Appointment Conflict(s)
                </h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" (click)="closeModal()" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="d-flex mb-3 alert alert-danger">
                    <div class="flex-fill fs-15">
                        One or more of the Care Team Members included have an appointment already booked during this day/time. Please review and make the appropriate updates for the Care Team Members(s) below:
                        <ul>
                            <li *ngFor="let res of scheduleResponses">
                                {{res.errorMessage}}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="modal-footer d-block text-center" id="cancel-modal-footer">
                <button type="button" class="btn btn-outline-dark rounded-pill px-4 my-0" data-bs-dismiss="modal" (click)="closeModal()">
                    Cancel
                </button>
                <button type="button" class="btn btn-outline-dark rounded-pill px-4 my-0" data-bs-dismiss="modal" (click)="confirmModal(addUpdateAppointment)">
                    Confirm
                </button>
            </div>
        </div>
    </div>
</div>

<!-- Delete Care Member Modal -->
<div class="modal fade" id="delete-care-member-modal">
    <div class="modal-dialog modal-dialog-centered modal-md">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title w-100 text-center" id="ariaCancelModal">
                    Remove Care Team Member
                </h5>
            </div>
            <div class="modal-body">
                <div class="d-flex mb-3">
                    <div class="flex-fill fs-15">
                        This action will:
                        <ul>
                            <li>
                                Remove Care Team Member from the scheduling grid
                            </li>
                            <li>
                                Remove appointment (if any) scheduled with this
                                Care Team Member
                            </li>
                        </ul>
                        Are you sure you want to remove the selected Care Team
                        Member?
                    </div>
                </div>
            </div>
            <div class="modal-footer d-block text-center"
                 id="cancel-modal-footer">
                <button type="button"
                        class="btn btn-outline-dark rounded-pill px-4 my-0"
                        data-bs-dismiss="modal">
                    Cancel
                </button>
                <button type="button"
                        class="btn btn-dark rounded-pill px-4 my-0"
                        data-bs-dismiss="modal"
                        (click)="removeCareMember()">
                    Confirm
                </button>
            </div>
        </div>
    </div>
</div>

<!-- Calendar Modal -->
<div class="aside-model">
    <div class="aside-model__header">
        <h6 class="m-0">
            {{SelectedCareMemberName}} <span *ngIf="popupCalEventsLoading">
                &nbsp;<i class="fas fa-spinner fa-pulse"></i>
            </span>
        </h6>
        <i class="fas fa-times aside-model__close"
           (click)="openModelCalanedar()"></i>
    </div>
    <div class="aside-model__content">
        <div class="md-switching-view-cont my-calendar-view" id="my-calendar-view">
            <mbsc-eventcalendar [data]="eventsPopupCal"
                                [view]="calView"
                                [headerTemplate]="customTemplate"
                                [options]="calendarOptionsPopupCal"
                                [timezonePlugin]="momentPlugin"
                                [displayTimezone]="timezone!"
                                dataTimezone="utc"
                                class="md-google-calendar">
                <ng-template #customTemplate>
                    <mbsc-calendar-nav style="width: 165px;"class="google-cal-header-nav"></mbsc-calendar-nav>
                    <div class="google-cal-header-picker">
                        <mbsc-segmented-group [(ngModel)]="popupCalView" (change)="changeView()">
                            <mbsc-segmented value="month">Month</mbsc-segmented>
                            <mbsc-segmented value="week">Week</mbsc-segmented>
                            <mbsc-segmented value="day">Day</mbsc-segmented>
                            <mbsc-segmented value="agenda">Agenda</mbsc-segmented>
                        </mbsc-segmented-group>
                    </div>
                    <mbsc-calendar-prev class="google-cal-header-prev"></mbsc-calendar-prev>
                    <mbsc-calendar-today class="google-cal-header-today"></mbsc-calendar-today>
                    <mbsc-calendar-next class="google-cal-header-next"></mbsc-calendar-next>
                </ng-template>
            </mbsc-eventcalendar>
        </div>
        <div style="margin-top: 10px;">
            <button type="button" class="btn btn-dark rounded-pill px-4 my-0" *ngIf="isMonthCreateAppointment" (click)="CreateMonthCalAppointment()"><i class="fas fa-plus fs-12 align-middle me-1"></i> Create Appointment ({{ TransformDate(selectedMonthDate)}})</button>
        </div>
    </div>
</div>
<div class="aside-model__overlay" (click)="openModelCalanedar()"></div>

<!-- Central Nurse Schedule Modal -->
<div class="modal fade" id="bestTimeScheduleModal" tabindex="-1" aria-labelledby="Best Time Nurse Modal" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg">
        <hs-search-best-time [patient]="patientModel!" [careMembers]="careMembersMap!" (submitBestTimeEvent)="submitBestTimeEvent($event)"  (submitElasticEvent)="submitElasticEvent($event)"></hs-search-best-time>
    </div>
</div>
