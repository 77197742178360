import { Component, ComponentFactoryResolver, ComponentRef, OnInit, Type, ViewChild } from '@angular/core';
import { ComponentHostDirective } from '../component-host';
import { GenericModal } from './generic-modal';
import { GenericModalHandler } from './generic-modal-handler';
import { GenericModalButtonInfo, GenericModalService } from './generic-modal-service';

declare const $: any;

@Component({
  selector: 'hs-generic-modal',
  templateUrl: './generic-modal.component.html',
  styleUrls: ['./generic-modal.component.scss']
})
export class GenericModalComponent implements OnInit, GenericModalHandler {

  @ViewChild(ComponentHostDirective, {static: true})
  componentHost!: ComponentHostDirective;

  private component: any;

  public title?: string;
  public subTitle?: string;

  public leftButtons?: GenericModalButtonInfo[];
  public rightButtons?: GenericModalButtonInfo[];

  constructor(private componentFactoryResolver: ComponentFactoryResolver, private genericModalService: GenericModalService) { }

  ngOnInit(): void {
    this.genericModalService.setHandler(this);
  }

  public showModel<I, O>(dialogComponent: Type<GenericModal<I, O>>, inputData?: I, handler?: (data: O) => void): void {
    
    const componentFactory: any = this.componentFactoryResolver.resolveComponentFactory(dialogComponent);

    const viewContainerRef = this.componentHost.viewContainerRef;
    viewContainerRef.clear();

    const componentRef: ComponentRef<any> = viewContainerRef.createComponent<typeof dialogComponent>(componentFactory);

    this.component = componentRef.instance;

    if(inputData) {
      this.component.setInputData(inputData);
    }
    
    this.title = this.component.getTitle();
    this.subTitle = this.component.getSubTitle();
    this.leftButtons = this.component.getLeftButtons();
    this.rightButtons = this.component.getRightButtons();

    if(handler) {
      this.component.setOutputDataHandler(handler);
    }

    $("#global-generic-modal").modal('show');
    $("#global-generic-modal").on('hidden.bs.modal', () => viewContainerRef.clear());
  }

  public async buttonClicked(button: GenericModalButtonInfo): Promise<void> {
    if(button.action) {
      let close = await button.action();
      if(close) {
        this.hideDialog();
      }
    } else {
      this.hideDialog();
    }
  }

  public async closeClicked(): Promise<void> {
    let close = await this.component.onCloseClicked();
    if(close) {
      this.hideDialog();
    }
  }

  private hideDialog(): void {
    $("#global-generic-modal").modal('hide');
  }
}
