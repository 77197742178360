import { Pipe, PipeTransform } from '@angular/core';
import { CentralNurseScheduleResponse } from 'src/app/api-client/model/centralNurseScheduleResponse';
import { CentralCareMemberInfo } from '../search-central-nurse/search-central-nurse.component';

@Pipe({
    name: 'filterCareNurse'
})
export class FilterCareNursePipe implements PipeTransform {

    transform(value: CentralCareMemberInfo[], args: CentralNurseScheduleResponse[]): CentralCareMemberInfo[] {
        if (value && value.length > 0 && args && args.length > 0) {
            let startDateTime = args[0].startDateTime;
            console.log(startDateTime);
            return value.filter((item: CentralCareMemberInfo) => {
                const slots = args.filter((arrItem) => arrItem.careMemberId === item.id);
                if (slots.length > 0) {
                    //item.slots = [...slots];
                    item.scheduleStartDate = startDateTime;
                    return item;
                }
                return;
            })
        }
        return [];
    }

}
