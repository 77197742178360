/**
 * Somatus Hybrid Scheduler API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CareMemberInfo } from './careMemberInfo';
import { PatientModel } from './patientModel';
import { RCTask } from './rCTask';

export interface AppointmentDetail { 
    patient?: PatientModel;
    preAppointmentTasks?: Array<RCTask>;
    postAppointmentTasks?: Array<RCTask>;
    appointmentScheduleDetails?: Array<CareMemberInfo>;
    id: number;
    mobileNo?: string;
    emailId?: string;
    notificationMobileNo?: string;
    notificationEmailId?: string;
    address?: string;
    addressType?: string;
    createdOn?: string;
    oneClickMeetingLink?: string;
    lat?: string;
    lon?: string;
    typeId: number;
    modeId?: string;
    timezone?: string;
    status?: string;
    meetingId?: string;
    enableBLSTemplate: boolean;
}