import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { CommonConstants } from './app/commons/common-constants';

let storedPath = window.localStorage.getItem('requestedUrl');
const path = window.location.href.split('/#');
if (storedPath) {
  let pathList: string[] = [];
  try {
    pathList = [...JSON.parse(storedPath)];
  } catch(error) {}
  pathList.push(path.length > 1 ? path[1]: CommonConstants.DEFAULT_HOME);
  window.localStorage.setItem('requestedUrl', JSON.stringify(pathList));
} else {
  window.localStorage.setItem('requestedUrl', JSON.stringify([path.length > 1 ? path[1]: CommonConstants.DEFAULT_HOME]));
}


if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
