import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';

@Pipe({
  name: 'safeUrl'
})
export class SafeUrlPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) { }
  transform(url: string, ...args: unknown[]): any {
    const trustedUrl :SafeResourceUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    return trustedUrl;
  }

}
