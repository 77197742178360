<div class="login__wrap" *ngIf="!loggedIn">
    <img class="img-fluid" src="./assets/img/login.jpg" alt="Somatus">
    <div>
        <div class="text-center fs-16">Welcome back!</div>
        <h5 class="text-center mb-4">Please login your account</h5>
        <div class="text-center">
            <button class="btn btn-outline-dark btn-lg rounded-pill px-4"  (click)="loginRedirect()">
                <svg class="mr-2" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" viewBox="0 0 512 512" width="18px" height="18px">
                    <path style="fill:#4CAF50;" d="M272,240h240V16c0-8.832-7.168-16-16-16H272V240z"></path>
                    <path style="fill:#F44336;" d="M240,240V0H16C7.168,0,0,7.168,0,16v224H240z"></path>
                    <path style="fill:#2196F3;" d="M240,272H0v224c0,8.832,7.168,16,16,16h224V272z"></path>
                    <path style="fill:#FFC107;" d="M272,272v240h224c8.832,0,16-7.168,16-16V272H272z"></path>
                </svg>
                Sign In as Somatus Employee
            </button>
        </div>
    </div>
</div>


<router-outlet *ngIf="!isIframe"></router-outlet>