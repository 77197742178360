<header class="header">
    <div class="container-fluid">
        <nav class="navbar navbar-expand-md navbar-light">
            <button class="navbar-toggler collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#navbarContent" aria-controls="navbarContent" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <a [routerLink]="'/'" class="header__logo">
                <img class="img-fluid" src="./assets/img/logo.png"/>
               
            </a>
            <button class="navbar-toggler fs-14 p-2 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSearch" aria-controls="navbarSearch" aria-expanded="false" aria-label="Toggle navigation">
                <i class="fas fa-search"></i>
            </button>
            <div id="navbarSearch" class="header__search d-md-flex collapse">
                <i class="fas fa-search header__search-icon"></i>
                <input class="form-control header__search-input" type="text" placeholder="Search patient to book appointment" [(ngModel)]="typedText">
                <div id="ui-autocomplete" class="search-results">
                    <div *ngIf="patentSearch && patentSearch.length == 0" class="alert alert-danger d-flex m-0">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-exclamation-triangle-fill flex-shrink-0 me-2" viewBox="0 0 16 16" role="img" aria-label="Warning:">
                            <use xlink:href="#exclamation-triangle-fill"></use>
                        </svg>
                        <div>
                            No Patient found!
                        </div>
                    </div>
                    <div *ngFor="let patient of patentSearch; let row = index;"
                         class="search-results__item"
                         (click)="schedulePatient(patient.patientId)">
                         <div class="flex-fill">{{patient.name}}</div>
                         <div>{{patient.enrollmentNumber}}</div>     
                    </div>
                </div>
            </div>
            <div class="collapse navbar-collapse d-md-flex justify-content-md-end" id="navbarContent">
                <ul class="navbar-nav my-md-1" >
                    <li class="nav-item">
                        <a [routerLink]="'/calendar'" class="btn text-white px-0 px-md-3 text-nowrap">My Calendar</a>
                    </li>
                    <li class="nav-item">
                        <a [routerLink]="'/appointments'" class="btn text-white me-md-2 px-0 px-md-3">Appointments</a>
                    </li>
                    <li class="nav-item mr-1r">
                        <a class="text-white fs-22">
                            <li (click)="openRequestForm()"  (keydown)="handleKeyDown($event)" (keyup)="handleKeyUp($event)"
                            (keypress)="handleKeyPress($event)" class="question-icon far fa-question-circle"></li>
                        </a>
                    </li>
                    <li class="nav-item border-0 mt-2 mt-md-0" *ngIf="loggedIn">
                        <a (click)="logout()" class="btn btn-outline-white rounded-pill px-4 d-none d-md-inline-block">Logout</a>
                        <a (click)="logout()" class="btn text-white me-md-2 px-0 px-md-3 d-md-none"><i class="fas fa-sign-out-alt"></i> Logout</a>
                    </li>
                    
                    <!--
                        <li class="nav-item border-0 mt-2 mt-md-0">
                            <button mat-raised-button *ngIf="!loggedIn" (click)="login()">Login</button>
                        </li>
                        <li class="nav-item border-0 mt-2 mt-md-0">
                            <button mat-raised-button *ngIf="loggedIn" (click)="logout()">Logout</button>
                        </li>
                    -->
                </ul>
            </div>
          </nav>
    </div>
</header>
