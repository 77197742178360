<div id="tasks-panel" class="mb-3">
    <div *ngIf="tasks && tasks.length > 0">
        <div class="d-flex p-2 ">
            <div class="pe-1">
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" (click)="toggleAllTask()" [checked]="allTasksSelected" id="allTask">
                </div>
            </div>
            <div class="flex-fill">
                <div class="row">
                    <div class="col-md-7">
                        <label for="allTask"><h6 class="mt-1 mb-2 text-uppercase">OUTSTANDING SCHEDULING TASK(S)</h6></label>
                    </div>
                    <div class="col-md-3 d-none d-md-block">
                        <h6 class="my-1 mb-2">ASSIGNED TO</h6>
                    </div>
                    <div class="col-md-2 d-none d-md-block">
                        <h6 class="my-1 mb-2">DUE DATE</h6>
                    </div>
                </div> 
            </div>
        </div>
        <hr class="m-0"/>
    </div>
    <div *ngFor="let task of tasks; let indexOfelement = index;">
        <div class="d-flex p-2 border-top border-white" [ngClass]="{'bg-secondary':selectedTaskIds.has(task.taskId)}">
            <div class="pe-1" (click)="toggleTask(task.taskId)">
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" [checked]="selectedTaskIds.has(task.taskId)">
                </div>
            </div>
            <div class="flex-fill">

                <div class="row">
                    <div class="col-12 col-md-7">
                        <strong>{{task.task}}</strong> - <span class="d-inline-block me-2">{{task.description}}</span>
                        <hs-task-status [status]="task.statusId"></hs-task-status>
                    </div>
                    <div class="col-12 col-md-3 mt-1 mt-md-0">
                        <div class="d-flex">
                            <div class="d-md-none">
                                <strong class="table__info">Assigned to</strong>
                            </div>
                            <div>{{task.careMember}}</div>
                        </div>  
                    </div>
                    <div class="col-12 col-md-1">
                        <div class="d-flex">
                            <div class="d-md-none">
                                <strong class="table__info">Due date</strong>
                            </div>
                            <div class="fs-12 text-lightest">{{task.dueDate | date:'MM/dd/yyyy'}}</div>
                        </div>  
                    </div>
                    <div class="col-12 col-md-1 text-md-end mt-1 mt-md-0">
                        <a class="d-none d-md-inline-block cursor-pointer px-1 text-dark"  *ngIf="selectedTaskIds.has(task.taskId)">
                        <i class="fas fa-edit fs-16" 
                            data-bs-toggle="modal" data-bs-target="#taskModal" (click)="openUpdateTaskModal(task, indexOfelement)"></i>
                        </a>
                        <a class="btn btn-sm btn-outline-primary table__btn d-md-none"  data-bs-toggle="modal" data-bs-target="#taskModal" (click)="openUpdateTaskModal(task, indexOfelement)" *ngIf="selectedTaskIds.has(task.taskId)">
                            <i class="fas fa-bars"></i>
                            <span class="d-lg-none ms-1">Update</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <hr class="m-0"/>
    </div>
    <div *ngIf="!tasks || tasks.length == 0" class="alert alert-primary alert-dismissible fade show mt-2">
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-exclamation-triangle-fill flex-shrink-0 me-2 svg-primary align-top" viewBox="0 0 16 16" role="img" aria-label="Warning:">
            <use xlink:href="#info-fill"></use>
        </svg>
        <strong>Info</strong> - There are no open tasks!
        <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
    </div>
</div>

<!-- TaskModal Modal -->
<div class="modal fade" id="taskModal" tabindex="-1" aria-labelledby="ariaTaskModal" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content">
        <form [formGroup]="taskStatusForm">
            <div class="modal-header">
                <div>
                    <h5 class="modal-title" id="ariaTaskModal">{{taskStatusForm.value.task}}
                        <hs-task-status [status]="taskStatusForm.value.statusId"></hs-task-status>
                    </h5>
                    <div>{{taskStatusForm.value.description}}</div>
                </div>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">            
                    <div class="mb-3">
                        <label for="taskStatus" class="form-label fw-bold">Task Status</label>
                        <select id="taskStatus" class="form-select" formControlName="statusId">
                        <option *ngFor="let task of taskStatusLust"  [value]="task.key">{{task.value}}</option>
                        </select>
                    </div>
                    <div>
                        <label for="CommentTextarea" class="form-label fw-bold">Comment</label>
                        <textarea class="form-control" 
                            id="CommentTextarea" rows="3" 
                            placeholder="Type comment here..."
                            formControlName="comment"></textarea>
                    </div>
                
                <div class="d-flex fst-italic text-lightest mt-3">
                    <div class="me-1"><i class="fas fa-hand-point-right"></i></div>
                    <div>
                        Task status changes will take effect once the appointment is booked
                    </div>
                </div>
            </div>
            <div class="modal-footer justify-content-between">
                <button type="button" class="btn btn-outline-dark rounded-pill px-4" 
                    data-bs-dismiss="modal">Cancel</button>
                <button type="button" class="btn btn-dark rounded-pill px-4" 
                    data-bs-dismiss="modal"
                    (click)="submitTaskStatus()">Save</button>
            </div>
        </form>
      </div>
    </div>
  </div>