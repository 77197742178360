import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'hs-recent-appointments',
  templateUrl: './recent-appointments.component.html',
  styleUrls: ['./recent-appointments.component.scss']
})
export class RecentAppointmentsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
