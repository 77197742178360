/**
 * Somatus Hybrid Scheduler API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface RCTask { 
    patientId: number;
    taskId: number;
    task?: string;
    careMemberId: number;
    careMember?: string;
    description?: string;
    dueDate: Date;
    source?: string;
    status?: string;
    statusId: number;
    patientActivityId: number;
    patientProgramId: number;
    currentUserId: number;
    comment?: string;
    timezoneId?: number;
    isNew: boolean;
    isDeleted: boolean;
    rcactivityId: number;
    dueDateTime?: Date;
}