import { Pipe, PipeTransform } from '@angular/core';
import { CommonDateTimeFormatter } from './common-date-time-formatter';

@Pipe({
    name: 'time'
})

export class TimePipe implements PipeTransform {

    constructor(private commonDateTimeFormatter: CommonDateTimeFormatter){}

    transform(value: any, offsetMillis?: number): any {
        return this.commonDateTimeFormatter.transform(value, 'h:mm a', offsetMillis);
    }
}