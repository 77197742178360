import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'hs-appointments-history',
  templateUrl: './appointments-history.component.html',
  styleUrls: ['./appointments-history.component.scss']
})
export class AppointmentsHistoryComponent implements OnInit {

  
  constructor() { }

  ngOnInit(): void {
    localStorage.removeItem('requestedUrl');
  }

}
