import { DatePipe } from "@angular/common";
import * as moment from "moment";
import { tz } from 'moment-timezone';

export class DateTime {

    public static readonly millisInSecond: number = 1000;
    public static readonly millisInMinute: number = 60 * DateTime.millisInSecond;
    public static readonly millisInHour: number = 60 * DateTime.millisInMinute;
    public static readonly millisInDay: number = 24 * DateTime.millisInHour;

    private static readonly datePipe: DatePipe = new DatePipe("en-US");

    private readonly time: number;

    constructor(time: number) {
        this.time = time;
    }

    public getDate(): Date {
        return new Date(this.time);
    }

    public getTime(): number {
        return this.time;
    }

    public startOfTheDay(): DateTime {
        let date: Date = this.getDate();
        date.setHours(0);
        date.setMinutes(0);
        date.setSeconds(0);
        date.setMilliseconds(0);
        return new DateTime(date.getTime());
    }

    public addDays(days: number): DateTime {
        return new DateTime(this.time + days * DateTime.millisInDay);
    }

    public addHours(hours: number): DateTime {
        return new DateTime(this.time + hours * DateTime.millisInHour);
    }

    public addMinutes(minutes: number): DateTime {
        return new DateTime(this.time + minutes * DateTime.millisInMinute);
    }

    public addSeconds(seconds: number): DateTime {
        return new DateTime(this.time + seconds * DateTime.millisInSecond);
    }

    public addMillis(millis: number): DateTime {
        return new DateTime(this.time + millis);
    }

    public static now(): DateTime {
        return new DateTime(Date.now());
    }

    public static from(date: Date | number | string): DateTime {
        switch(typeof(date)) {
            case "string":
                return new DateTime(new Date(date + "z").getTime());
            case "number":
                return new DateTime(new Date(date).getTime());
            default:
                return new DateTime(date?.getTime());
        }
    }

    public static formatStandardDate(date: Date): string {
        return DateTime.datePipe.transform(date, "yyyy-MM-dd") ?? "";
    }

    public static getLocalOffset(timezone: string): number {
        return (tz(timezone).utcOffset() - moment().parseZone().utcOffset());
    }

    public static getUtcOffset(timezone: string): number {
        return tz(timezone).utcOffset();
    }
    public static readonly getWeekDates = (date: any) => {
        const dates = [];
        const current = new Date();        
        const day1 = current.getDate() - current.getDay();    
        const day2 = day1 + 7;       
        const weekstart = new Date(current.setDate(day1));  
        const weekEnd = new Date(current.setDate(day2));
        weekstart.setHours(0,0,0,0);
        weekEnd.setHours(0,0,0,0)
        dates.push(weekstart, weekEnd);
        return dates;
      }
}