/**
 * Somatus Hybrid Scheduler API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CalenderEventExt } from './calenderEventExt';

export interface CareMemberCalenderEvents { 
    canAddEvents: boolean;
    events?: Array<CalenderEventExt>;
}