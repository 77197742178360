<div class="bg-bluelight p-3 pt-2 calender-view-container">
    <div class="d-flex">
        <div class="flex-fill mb-3 mt-1">
            <ol class="breadcrumb text-uppercase fs-14 ">
                <li class="breadcrumb-item"><a href="#"><i class='fas fa-home'></i></a></li>
                <li class="breadcrumb-item active text-dark"><b>My Calendar</b></li>
            </ol>
        </div>
    </div>
    <div class="md-switching-view-cont my-calendar-view" id="my-calendar-view">
        <mbsc-eventcalendar [data]="events" 
            [view]="calView" 
            [headerTemplate]="customTemplate" 
            [options]="calendarOptions"
            class="md-google-calendar">
            <ng-template #customTemplate>
                <mbsc-calendar-nav class="google-cal-header-nav"></mbsc-calendar-nav>
                <div class="google-cal-header-picker">
                    <mbsc-segmented-group [(ngModel)]="view" (change)="changeView()">
                        <mbsc-segmented value="month">Month</mbsc-segmented>
                        <mbsc-segmented value="week">Week</mbsc-segmented>
                        <mbsc-segmented value="day">Day</mbsc-segmented>
                        <mbsc-segmented value="agenda">Agenda</mbsc-segmented>
                    </mbsc-segmented-group>
                </div>
                <mbsc-calendar-prev class="google-cal-header-prev"></mbsc-calendar-prev>
                <mbsc-calendar-today class="google-cal-header-today"></mbsc-calendar-today>
                <mbsc-calendar-next class="google-cal-header-next"></mbsc-calendar-next>
            </ng-template>
        </mbsc-eventcalendar>
    </div>
</div>