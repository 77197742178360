import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs/internal/Subject';
import { CareMemberInfo } from 'src/app/api-client/model/models';
import { AddCareMemberAndEventSubscribe } from 'src/app/models/common';


@Injectable({
    providedIn: 'root'
})
export class ScheduleService {
    private shareTimezone = new Subject<string>();
    private shareTimezoneCode = new Subject<string>();
    public $shareTimezonSub = this.shareTimezone.asObservable();
    public $shareTimezonCodeSub = this.shareTimezoneCode.asObservable();
    private shareSearchType = new Subject<string>();
    public $shareSearchTypeSub = this.shareSearchType.asObservable();
    private openCentralNurseScheduleModalSub = new Subject<string>();
    private openBestTimeScheduleModalSub = new Subject<string>();
    public $openCentralNurseScheduleModal = this.openCentralNurseScheduleModalSub.asObservable();
    public $openBestTimeScheduleModal = this.openBestTimeScheduleModalSub.asObservable();
    private _openCareMemberEvents = new Subject<CareMemberInfo>();
    private _addCareMemberEvents = new Subject<AddCareMemberAndEventSubscribe>();

    constructor() { }
    public sendTimezone(value: string): void {
        this.shareTimezone.next(value);
    }
    public sendSearchType(value: string): void {
        this.shareSearchType.next(value);
    }
    public openCentralNurseScheduleModal(value: string, type: string): void {
        this.openCentralNurseScheduleModalSub.next(value);
        this.sendSearchType(type);
    }

    public openBestTimeScheduleModal(value: string): void {
        this.openBestTimeScheduleModalSub.next(value);
    }

    public sendTimezoneCode(value: string): void {
        this.shareTimezoneCode.next(value);
    }
    public openCareMemberEventsSubscription(): Observable<CareMemberInfo> {
        return this._openCareMemberEvents.asObservable();
    }
    public openCareMemberEvents(value: CareMemberInfo) {
        this._openCareMemberEvents.next(value);
    }
    public addCareMemberEventsSubscription() : Observable<AddCareMemberAndEventSubscribe> {
        return this._addCareMemberEvents.asObservable();
    }
    public addCareMemberEvents(value: AddCareMemberAndEventSubscribe) {
        this._addCareMemberEvents.next(value);
    } 
}
