export const environment = {
  production: '_@_production_@_',
  graph_endpoint:'_@_graph_endpoint_@_',
  authClientID:'_@_authClientID_@_',
  authAuthority:'_@_authAuthority_@_',
  authRedirectURI:'_@_authRedirectURI_@_',
  baseApiUrl:'_@_baseApiUrl_@_',
  apiClientID:'_@_apiClientID_@_',
  videoPlayer:'_@_videoPlayer_@_',
  requestFormLink: '_@_requestFormLink_@_',
  key_nav_map: '_@_key_nav_map_@_',
  saturday_search: '_@_saturday_search_@_',
  connectionString: '_@_connectionString_@_',
  to : '_@_to_@_',
  template_subject: '_@_template_subject_@_',
  azureAppConfiguration_connectionString : "_@_azureAppConfiguration_connectionString_@_",
  azureAppConfiguration_label : "_@_azureAppConfiguration_label_@_"
};