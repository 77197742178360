import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';


import { AppointmentService } from './api/appointment.service';
import { BestTimeService } from './api/bestTime.service';
import { CalendarService } from './api/calendar.service';
import { CareTeamService } from './api/careTeam.service';
import { HSCalendarService } from './api/hSCalendar.service';
import { MobileService } from './api/mobile.service';
import { PatientService } from './api/patient.service';
import { RCIntegrationService } from './api/rCIntegration.service';
import { RCTaskService } from './api/rCTask.service';
import { ReferenceDataService } from './api/referenceData.service';
import { UserService } from './api/user.service';
import { AppointmentWizardService } from './api/appointmentWizard.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: [
    AppointmentService,
    BestTimeService,
    CalendarService,
    CareTeamService,
    HSCalendarService,
    MobileService,
    PatientService,
    RCIntegrationService,
    RCTaskService,
    ReferenceDataService,
    UserService,
    AppointmentWizardService ]
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<any> {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
