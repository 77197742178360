/**
 * Somatus Hybrid Scheduler API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface PatientModel { 
    id: number;
    name?: string;
    dateofBirth?: Date;
    age: number;
    gender?: string;
    preferredTime?: string;
    language?: string;
    status?: string;
    requiredTimeSlot: number;
    appointmentType?: string;
    enrollmentNumber?: string;
    address?: string;
    payerName?: string;
    memberId?: string;
    email?: string;
    state?: Array<string>;
    zipcode?: string;
    timeZone?: string;
    lat: number;
    lng: number;
    eligibilityEndDate: Date;
    addressType?: string;
    clientName?: string;
    clientId: number;
    lineOfBusinessId: number;
    enableCareTeamMapping?: boolean;
    isCentralTeamOutReachMode?: boolean;
    phoneNumber: number;
    npEligibilityStatus?: string;
    centralVisiblility: boolean;
    stateId?: string;
}