<div class="modal-content">
    <div class="modal-header">
        <div>
            <h5 class="modal-title search-nurse" id="Contact Modal">Search {{modalTittle}} Availability</h5>
            <div>
                Selected {{modalDesc}} will be added to the CARE TEAM MEMBER CALENDAR(S) section
            </div>
        </div>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
    </div>
    <div class="px-3 py-2">
        <form [formGroup]="searchForm" (ngSubmit)="fetchAvailableScheduleList()">
            <div class="row g-2">
                <div class="col-12 col-lg-12">
                    <div class="row g-2">

                        <div class="col-6 col-lg-3 appointment-start">
                            <label for="appointmentStart" class="form-label fw-bold text-nowrap">Start Date</label>
                            <div class="date-range"><mbsc-datepicker [disabled]="!isNpSearch" formControlName="selectedDate"></mbsc-datepicker></div>
                        </div>
                        <div class="col-6 col-lg-2" *ngIf="!isAutoSearch">
                            <hs-time-field [time]="searchForm.controls.selectedTime.value"
                                           [containerClass]="''"
                                           [labelText]="'Start Time'"
                                           [labelClass]="'fw-bold text-nowrap'"
                                           [selectClass]="'form-select'"
                                           [dropdownDisabled]="searchingCareNurse"
                                           (onChange)="time = $event!">
                            </hs-time-field>
                       </div>
                        <div class="col-6 col-lg-2" *ngIf="isAutoSearch">
                            <label for="duration" class="form-label fw-bold">Selected Day(s)</label>
                            <div class="input-group">
                                <select *ngIf="!dualVisit && !(isSaturdaySearch === 'true')" formControlName="daySlotId"
                                        class="form-select">
                                    <option value="D">Mon-Fri</option>
                                    <option value="S">Saturday</option>
                                </select>
                                <select *ngIf="!dualVisit && (isSaturdaySearch === 'true')" formControlName="daySlotId"
                                        class="form-select">
                                    <option value="D">Mon-Sat</option>
                                </select>
                                <select *ngIf= "dualVisit" formControlName="daySlotId"
                                        class="form-select">
                                    <option value="D">Mon-Fri</option>
                                </select>
                            </div>
                        </div>
                        <div [ngClass]="{'col-6 col-lg-3': isNpSearch, 'col-6 col-lg-2': !isNpSearch}">
                            <label for="language" class="form-label fw-bold">{{ isNpSearch ? 'Language (Optional)' : 'Language' }}</label>
                            <div class="input-group">
                                <select *ngIf="isNpSearch" formControlName="languageId" class="form-select">
                                    <option value="">--Select Language--</option>
                                    <option *ngFor="let language of languages"
                                            [ngValue]="language.id">
                                        {{language.name}}
                                    </option>
                                  </select>
                                  <select *ngIf="!isNpSearch" formControlName="languageId" class="form-select">
                                    <option *ngFor="let language of languages" [ngValue]="language.id">{{language.name}}</option>
                                  </select>
                            </div>
                        </div>
                        <div [ngClass]="{'col-6 col-lg-4': isNpSearch, 'col-6 col-lg-5': !isNpSearch}">
                            <label for="duration" class="form-label fw-bold">Duration</label>
                            <div class="input-group">
                                <select id="duration" *ngIf="dualVisit && !isNpSearch" 
                                formControlName="duration" class="form-select">
                                <option *ngFor="let durationTime of durationTimeslist" [ngValue]="durationTime">{{durationTime}} Mins</option>
                                </select>
                                <select id="duration" *ngIf="!dualVisit || isNpSearch" 
                                formControlName="duration" class="form-select">
                                    <option *ngFor="let durationTime of durationTimes" [ngValue]="durationTime">{{durationTime}} Mins</option>
                                  </select>
                                <button type="submit" [disabled]="searchForm.invalid || searchingCareNurse" class="btn btn-sm btn-primary input-group-text ms-2 rounded-3">
                                    <i class="fas fa-search me-1"></i> Search Availability
                                </button>
                            </div>
                        </div>

                         <div class="col-6 col-lg-3 appointment-start" *ngIf="!isNpSearch">
                            <div class="pe-1">
                                <input type="checkbox" class="form-check-input"
                                       [checked]="isTca" name="autoSearch"
                                       (change)="tcaSearch($event)" />
                                <label for="tcaSearch" class="form-label fw-bold text-nowrap" style="margin-left: 5px;">TCA/Urgent CMA</label>
                            </div>
                        </div>
                        <div class="col-6 col-lg-3 appointment-start" *ngIf="isNPEligible && !isNpSearch">
                            <div class="pe-1">
                                <input type="checkbox" class="form-check-input"
                                       [checked]="dualVisit" name="autoSearch"
                                       (change)="dualSearch($event)" />
                                <label for="dualSearch" class="form-label fw-bold text-nowrap" style="margin-left: 5px;">Dual Visit</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <hr class="hr-light m-0" />
    <div class="modal-body" id="centralNurseScheduleModalBody" *ngIf="!isAutoSearch">
        <div class="results-panel">
            <div class="border rounded care-member-result"
                 *ngFor="let careMember of centralCareMember; let indexOfItem = index;"
                 [ngClass]="{'care-member-result-selected': careMember.selectedMember}"
                 (click)="careMember.selectedMember = !careMember.selectedMember">
                <div class="row">
                    <div class="col-12 col-lg-5">
                        <strong class="d-block">{{careMember.name}}</strong>
                        <span class="fs-12">{{careMember.emailId}}</span>
                        <br /><div class="d-inline-block border px-1 rounded fw-normal align-top text-center fs-11 border-primary text-primary bg-primary-light mt-05">Appointment Count: {{careMember.appointmentCount}}</div>
                    </div>
                    <div class="col-12 col-lg-7">
                        <hs-central-nurse-availability-ui [times]="times" [careMember]="careMember?.slots"></hs-central-nurse-availability-ui>
                    </div>
                </div>
            </div>
            <div *ngIf="!beforeSearch && centralCareMemberList.length === 0" class="alert alert-success text-center">No matching {{modalTittle}} found!</div>
        </div>
    </div>
    <div class="modal-body" id="autoCentralNurseScheduleModalBody" *ngIf="isAutoSearch">
        <div class="results-panel" >
            <table class="table" *ngIf="autoSearchScheduleList.length > 0 && autoSearchScheduleList[0].careMemberId !== 0">
                <thead>
                    <tr>
                        <th scope="col">Central Nurse</th>
                        <th scope="col">{{showNursePractitioner? 'Nurse Practitioner': 'Email Address'}}</th>
                        <th scope="col">Date</th>
                        <th scope="col">From Time</th>
                        <th scope="col">To Time</th>
                    </tr>
                </thead>
                <tbody *ngFor="let item of autoSearchScheduleList">
                    <tr (click)="submitAutoSearchSchedules(item)" data-bs-dismiss="modal">
                        <th scope="row">{{item.name}}</th>
                        <td *ngIf="!showNursePractitioner">{{item.emailId}}</td>
                        <th scope="row" *ngIf="showNursePractitioner">{{item.nursePractitioner?.name}}</th>
                        <td>{{item.startDateTime | usDate}}</td>
                        <td>{{item.startDateTime | time}}</td>
                        <td>{{item.endDateTime | time}}</td>
                    </tr>
                </tbody>
            </table>
            <div class="results-panel" *ngIf="autoSearchScheduleList.length > 0 && autoSearchScheduleList[0].careMemberId === 0">
                <div class="alert alert-danger text-center py-4 border border-danger rounded-3">
                    <div class="mb-2">
                        <i class="fas fa-exclamation-triangle text-danger me-1 fs-24"></i>
                    </div>
                    <p>
                        <span *ngIf="showNursePractitioner">No Central Nurse/Nurse Practitioner combination available</span>
                        <span *ngIf="!showNursePractitioner">Central Nurse is not available</span>
                        <span *ngIf="slotAvailabilityErrorMessage.daySlotId === 'M'"> in the <b>Morning</b></span>
                        <span *ngIf="slotAvailabilityErrorMessage.daySlotId === 'A'">in the <b>Afternoon</b></span>
                        <span *ngIf="slotAvailabilityErrorMessage.daySlotId === 'E'">in the <b>Evening</b></span>
                        <span *ngIf="slotAvailabilityErrorMessage.daySlotId === 'S'">for <b>Saturday</b></span>
                        from {{slotAvailabilityErrorMessage.searchStartDate | date:'MM/dd/yyyy'}} to
                        {{slotAvailabilityErrorMessage.searchEndDate | date:'MM/dd/yyyy'}}
                    </p>
                    Click the <b>Next</b> button to search for next available day(s)
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer justify-content-between w-100" *ngIf="!isAutoSearch">
        <div class="d-flex text-start">&nbsp;</div>
        <div class="d-flex text-end">
            <button type="button" class="btn btn-sm btn-dark rounded-pill px-4"
                    data-bs-dismiss="modal"
                    [disabled]="!enableSubmitButton()"
                    (click)="submitSchedules()">
                Submit
            </button>
        </div>
    </div>

    <div class="modal-footer justify-content-between w-100" *ngIf="isAutoSearch">
        <div class="d-flex text-start">
            <button type="button" class="btn btn-sm btn-dark rounded-pill px-4" (click)="ResetFetchAutoSearchList($event)">
                Reset Date
            </button>
        </div>
        <div class="d-flex text-end">
            <button type="button" [disabled]="isPreviousButtonDisabled" class="btn btn-sm btn-dark rounded-pill px-4" style="margin-right: 10px;" (click)="previousFetchAutoSearchList($event)">
                Previous
            </button>
            <button type="button" [disabled]="isNextButtonDisabled" class="btn btn-sm btn-dark rounded-pill px-4" (click)="afterFetchAutoSearchList($event)">
                Next
            </button>
        </div>
    </div>
</div>
