import {
  ICustomLogEvents,
  ISessionDetails,
} from "./../../commons/customLogEvent";
import { Injectable } from "@angular/core";
import { AppInsightsService } from "src/app/app-insights-service";
import { CommonConstants } from "src/app/commons/common-constants";

@Injectable({
  providedIn: "root",
})
export class LoggingServiceTsService {
  constructor(public appInsights: AppInsightsService) {}

  createCustomLog(
    event: string,
    message: string,
    level: string
  ): ICustomLogEvents {
    const sessionDetails = this.GetSessionDetails();
    return {
      level,
      event,
      message,
      sessionDetails,
      timestamp: new Date().toString(),
    };
  }

  GetSessionDetails(): ISessionDetails {
    return {
      userName: localStorage.getItem("NAME")!,
      userEmail: localStorage.getItem("USERNAME")!,
      sessionId: localStorage.getItem(CommonConstants.LS_SESSION)!
    };
  }
  logEventForComponentRendering(customEvent: string) {
    const customLog = this.createCustomLog(
      "Component Loading",
      "Component Rendered Successfully",
      "INFO"
    );
    this.trackEvent(customEvent, customLog);
  }

  trackEvent(event: string, log?: ICustomLogEvents): void {
    this.appInsights.trackEvent(event, log as any);
  }

  logException(error: any): void {
    this.appInsights.logException(error);
  }

  logPageView(name?: string, url?: string,properties?: { [key: string]: any }) : void {
    this.appInsights.trackPageView(name,url,properties);
  }
}
