<div id="schedule-info-panel" class="modal-body">
    <div class="row g-2">
        <div class="col-12 col-sm-2">
            <div class="mb-3">
                <label for="Priority" class="form-label fw-bold">Priority</label>
                <input id="Priority" type="text" class="form-control" 
                    placeholder="Priority" [value]="patientPriority" disabled/>
            </div>
        </div>
        <div class="col-12 col-sm-3">
            <div class="mb-3">
                <label for="Patient" class="form-label fw-bold">Patient</label>
                <input id="Patient" type="text" class="form-control" 
                    placeholder="Patient" [value]="patient?.name" disabled/>
            </div>
        </div>
        <div class="col-12 col-sm-7">
            <div class="mb-3">
                <label for="Location" class="form-label fw-bold">Location</label>
                <div class="form-control__wrap">
                    <a *ngIf="patient?.address && showLocationLink" href="http://maps.google.com/maps?q={{patient?.address}}&f=q&hl=en&geocode=&mrt=all&ie=UTF8&hq=&hnear={{patient?.address}}"  target="_blank" class="form-control__icon">
                        <i class="fas fa-map-marker-alt"></i>
                    </a>
                    <input id="Location" type="text" class="form-control" 
                    placeholder="Patient" [value]="patient?.address" disabled/>
                </div>
            </div>
        </div>
    </div>
    <div class="row g-2">
        <div class="col-12 col-sm-4">
            <div class="mb-3">
                <label for="appointmentType" class="form-label fw-bold">Task Type</label>
                <input id="appointmentType" type="text" class="form-control" 
                    [value]="apptData.typeId | apptType" disabled />
            
            </div>
        </div>
        <div class="col-12 col-sm-4">
            <div class="mb-3">
                <label for="scheduleMode" class="form-label fw-bold">Appointment Type</label>
                <input id="schedulMode" type="text" class="form-control" 
                    [value]="apptData.modeId | apptMode" disabled />
                
            </div>
        </div>
        <div class="col-12 col-sm-3">
            <div class="mb-3">
                <label for="exampleInputEmail1" class="form-label fw-bold"><span class="d-lg-inline d-sm-none">Preferred</span> Language</label>
                <input id="exampleInputEmail1" type="text" class="form-control" 
                    [value]="patient?.language" disabled/>
            </div>
        </div>
    </div>
    <label for="appointmentTime" class="form-label fw-bold">Appointment Time: {{startDate | usDate}}</label>
    <div class="row g-0 mb-2">
        <div class="col-6 col-sm-3">
            <div class="select-group">
                <div class="p-05">PRE</div>
                <div class="p-05">{{apptData.schedulePreTime}} Mins</div>
            </div>
        </div>
        <div class="col-6 col-sm-3">
            <div class="select-group">
                <div class="p-05">Starting Time</div>
                <div class="p-05">{{startDate | time}}</div>
            </div>
        </div>
        <div class="col-6 col-sm-3">
            <div class="select-group">
                <div class="p-05">Duration</div>
                <div class="p-05">{{duration}} Mins</div>
            </div>
        </div>
        <div class="col-6 col-sm-3">
            <div class="select-group is-right rounded-0">
                <div class="p-05">POST</div>
                <div class="p-05">{{apptData.schedulePostTime}} Mins</div>
            </div>
        </div>
    </div>
    <div *ngFor="let note of notes">
        <hr class="hr-dash"/>
        <div class="d-flex justify-content-between fs-12 text-lightest">
            <div>
                {{note.note}}&nbsp;&nbsp;&nbsp;&nbsp;{{note.createdOn | usDateTime}}
            </div>
            <div>
                <i>Updated By: {{note.createdBy}}</i>
            </div>
        </div>
    </div>
    <div class="modal-footer text-end w-100" *ngIf="apptData.showOptionalButtons">
        <a class="btn btn-sm btn-dark rounded-pill px-4" 
            *ngIf="patient?.address && showLocationLink" 
            href="http://maps.google.com/maps?q={{patient?.address}}&f=q&hl=en&geocode=&mrt=all&ie=UTF8&hq=&hnear={{patient?.address}}" 
            target="_blank">
            <i class="fas fa-map-marker-alt"></i> Location
        </a>
        <a class="btn btn-sm btn-dark rounded-pill px-4 "
                [href]="apptData.oneClickMeetingLink" target="_blank"  *ngIf="showVideoCallButton">
                <i class="fas fa-video me-1"></i> Start Video Call</a>
        <button type="button" class="btn btn-sm btn-dark rounded-pill px-4" 
            (click)="viewAppointmentDetails()">View Details</button>
    </div>
</div>

