import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppointmentDetail, AppointmentManagementService, AppointmentNoteResponse, AppointmentService, PatientModel, PatientService } from 'src/app/api-client';
import { GenericModal } from 'src/app/commons-ui/generic-modal/generic-modal';
import { GenericModalButtonInfo, GenericModalService } from 'src/app/commons-ui/generic-modal/generic-modal-service';
import { Loader } from 'src/app/commons-ui/loader';
import { DateTime } from 'src/app/commons/date-time';
import { CalendarEvent } from '../schedule-calendar/calendar-event';

interface ApptDate extends CalendarEvent{
  start?: any;
  end?: any;
  showOptionalButtons?: boolean;
}

@Component({
  selector: 'hs-view-schedule-popup',
  templateUrl: './view-schedule-popup.component.html',
  styleUrls: ['./view-schedule-popup.component.scss']
})
export class ViewSchedulePopupComponent implements OnInit, GenericModal<ViewSchedulePopupInput, void> {

  public patient?: PatientModel;
  public patientPriority: string = "";
  
  public apptData: ApptDate = <ApptDate>{};

  public startDate?: Date;
  public duration?: number;
  public showVideoCallButton: boolean = false;
  public showLocationLink: boolean = false;
  public notes: AppointmentNoteResponse[] = [];

  constructor(private patientService: PatientService, 
              private router: Router, 
              private genericModalService: GenericModalService,
              private appointmentService: AppointmentService,
              private readonly appointmentManagementService : AppointmentManagementService) {}
  
  ngOnInit(): void {}

  getTitle(): string {
    return this.apptData.showOptionalButtons ? "Appointment" : "Schedule";
  }
  getSubTitle(): string {
    return "";
  }
  async updateButtonState(): Promise<void> {
    if (String(this.apptData.modeId).toUpperCase() === 'IN-HOME') {
      this.showLocationLink = true;
    }
    if (String(this.apptData.status).toUpperCase() !== 'CANCELED' && this.apptData.appointmentId && new Date().getTime() < new Date(this.apptData.end).getTime()) {
      const data = await this.appointmentService.appointmentGetAppointmentDetail(this.apptData.appointmentId).toPromise();
      if (data?.oneClickMeetingLink) {
        this.showVideoCallButton = true;
        this.apptData = {...this.apptData, oneClickMeetingLink: data.oneClickMeetingLink}
      }
    }
  }
  private loadNotes(): void {
    this.apptData.appointmentId && this.appointmentManagementService.appointmentManagementGetAppointmentNotes(this.apptData.appointmentId).subscribe((notes: AppointmentNoteResponse[]) => {
      this.notes = notes;
    });
  }
  setInputData(data: ViewSchedulePopupInput): void {
      this.apptData = data.event;
      this.apptData.showOptionalButtons = data.showOptionalButtons;
      let startTimeMillsec = new Date(data.event?.start?.toString()!).getTime() - data.timezoneOffset + (this.apptData.schedulePreTime ?? 0) * DateTime.millisInMinute;
      this.startDate = this.roundTimeQuarterHour(startTimeMillsec);

      let endDate: Date | null = data.event?.end ? DateTime.from(data.event?.end as Date).addMinutes(-(data.event.schedulePostTime ?? 0)).getDate() : null;
      let endTimeMillsec = new Date(endDate?.toString()!).getTime() - data.timezoneOffset;
      this.duration = endDate ? (endTimeMillsec -  startTimeMillsec) / DateTime.millisInMinute : 60;

      
      this.loadPatient(data.event.patientId!);
  }

  private async loadPatient(id: number): Promise<void> {
    Loader.showLoader("schedule-info-panel");
    await this.loadPatientPriority(id);
    try {
      this.patient = await this.patientService.patientGetDetail(id).toPromise();
      if (this.apptData.showOptionalButtons) {
        await this.updateButtonState();
        this.loadNotes();
      }
    } finally {
      Loader.hideLoader("schedule-info-panel");
    }
  }

  private async loadPatientPriority(id: number): Promise<void> {
    try {
        this.patientPriority = await this.patientService.patientGetPriority(id).toPromise() ?? '';
    } catch (error) {
        console.error(error);
    }
}

  public roundTimeQuarterHour(timeinMS: number):Date {
      var timeToReturn = new Date(timeinMS);
  
      timeToReturn.setMilliseconds(Math.round(timeToReturn.getMilliseconds() / 1000) * 1000);
      timeToReturn.setSeconds(Math.round(timeToReturn.getSeconds() / 60) * 60);
      timeToReturn.setMinutes(Math.round(timeToReturn.getMinutes() / 15) * 15);
      return timeToReturn;
  }

  setOutputDataHandler(handler: (data: void) => void): void {
  }

  getLeftButtons(): GenericModalButtonInfo[] {
    return [];
  }

  getRightButtons(): GenericModalButtonInfo[] {
    if (this.apptData.showOptionalButtons) {
      return [];
    }
    return [
      {
        label: "Close",
        action: () => Promise.resolve(true),
        cssClasses: "btn btn-info"
      }
    ];
  }

  onCloseClicked(): Promise<boolean> {
    return Promise.resolve(true);
  }
  viewAppointmentDetails(): void {
    this.genericModalService.hideModel();
    this.apptData.appointmentId && this.router.navigate(['appointments/' + this.apptData.appointmentId]);
  }
}

export interface ViewSchedulePopupInput {
  event: CalendarEvent;
  timezoneOffset: number;
  showOptionalButtons?: boolean;
}
