import { Injectable } from "@angular/core";
import { CommonConstants } from "src/app/commons/common-constants";
import { CalendarEvent } from "./calendar-event";

@Injectable()
export class EventHelper {

    /** Update event color based on the mode Type */
    public updateColor(event: CalendarEvent, isUpdateRequest?:boolean): void {
        let update: boolean = event.appointmentId! > 0;
        if(!event.isHSSchedule) {
            event.color = CommonConstants.LIGHTGRAY;
        }
        else if(event.selectedAppointment){
            if(event.modeId == CommonConstants.IN_HOME) {
                event.color =  CommonConstants.NEW_IN_HOME_COLOR;
            }else if(event.modeId == CommonConstants.TELEHEALTH) {
                event.color =  CommonConstants.NEW_TELEHEALTH_COLOR;
            }else if(event.modeId == CommonConstants.TELEPHONE) {
                event.color =  CommonConstants.NEW_TELEPHONE_COLOR;
            }else if(event.modeId == CommonConstants.IN_CLINIC) {
                event.color =  CommonConstants.NEW_IN_CLINIC_COLOR;
            }
        }else{
            if(event.modeId == CommonConstants.IN_HOME) {
                event.color = update ? CommonConstants.IN_HOME_COLOR : CommonConstants.NEW_IN_HOME_COLOR;
            }else if(event.modeId == CommonConstants.TELEHEALTH) {
                event.color = update ? CommonConstants.TELEHEALTH_COLOR : CommonConstants.NEW_TELEHEALTH_COLOR;
            }else if(event.modeId == CommonConstants.TELEPHONE) {
                event.color = update ? CommonConstants.TELEPHONE_COLOR : CommonConstants.NEW_TELEPHONE_COLOR;
            }else if(event.modeId == CommonConstants.IN_CLINIC) {
                event.color = CommonConstants.NEW_IN_CLINIC_COLOR;
            }else if(!event.appointmentId) {
                event.color = "yellow";
            } else {
                event.color = "aquamarine";
            }
        }
    }

     /** Update event color based on the mode Type */
     public getDisplayText(event: CalendarEvent): void {
        if(!event.isHSSchedule) {
            event.color = CommonConstants.LIGHTGRAY;
        }
 
        else if(event.modeId == CommonConstants.IN_HOME) {
            event.color = CommonConstants.IN_HOME_COLOR;
        }else if(event.modeId == CommonConstants.TELEHEALTH) {
            event.color = CommonConstants.TELEHEALTH_COLOR;
        }else if(event.modeId == CommonConstants.TELEPHONE) {
            event.color = CommonConstants.TELEPHONE_COLOR;
        } else if(event.modeId == CommonConstants.IN_CLINIC) {
            event.color = CommonConstants.NEW_IN_CLINIC_COLOR; 
        } else if(!event.appointmentId) {
            event.color = "yellow";
        } else {
            event.color = "aquamarine";
        }
    }


    public isDraft(event: CalendarEvent): boolean {
        if(!event.isHSSchedule) {
            return false;
        }
        
        if(event.appointmentId) {
            return false;
        }

        return true;
    }
}
