
            <div class="d-md-flex">
				<div>
					<div class="user">HK</div>
				</div>
				<div class="d-flex flex-fill">
					<div class="box flex-fill">
						<div><strong class="d-inline-block mw-35">Hexxxxxx is</strong></div>
						<div class="small"><strong class="d-inline-block mw-35 ">DOB : 05/14/1952</strong> (Age: <span>69</span> Years) / Male</div>
						<div><strong class="d-inline-block mw-35 small">51***** **** ***** et</strong></div>
					</div>
				</div>
			</div>
			<h6 class="mt-2 p-2 bg-secondary">Care Team Member Schedule</h6>
			<div class="d-flex p-2">
				<div class="flex-fill">
					<strong>John Doe</strong> - InHome
				</div>
				<div class="text-end">
					<div class="fs-12">
						1:00pm - 2:00pm, <span class="text-lightest">(27 Thu Oct 2021)</span>
					</div>
				</div>
			</div>
			<hr class="m-0"/>
			<div class="d-flex p-2">
				<div class="flex-fill">
					<strong>Hemant Kumar</strong> - Telephonic
				</div>
				<div class="text-end">
					<div class="fs-12">
						5:00pm - 5:45pm, <span class="text-lightest">(28 Thu Oct 2021)</span>
					</div>
				</div>
			</div>
			<hr class="m-0"/>
			<div class="d-flex p-2">
				<div class="flex-fill">
					<strong>Surendra Choudhary</strong> - Dual
				</div>
				<div class="text-end">
					<div class="fs-12">
						2:00pm - 2:30pm, <span class="text-lightest">(29 Thu Oct 2021)</span>
					</div>
				</div>
			</div>
      