import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { setOptions, formatDate, MbscSelectOptions, MbscPopup, MbscDatepickerOptions, options  } from '@mobiscroll/angular';
import * as moment from 'moment-timezone';
import { DateTime } from 'src/app/commons/date-time';

setOptions({
  theme: 'ios',
  themeVariant: 'light'
});

const now = new Date();
const day = now.getDay();
const monday = now.getDate() - day + (day === 0 ? -6 : 1);
const startDate = DateTime.now().startOfTheDay().getDate();
const endDate =  DateTime.now().addDays(7).startOfTheDay().getDate();

@Component({
  selector: 'hs-date-range',
  templateUrl: './date-range.component.html',
  styleUrls: ['./date-range.component.scss']
})
export class DateRangeComponent implements OnInit {

    @ViewChild('popup', { static: false })
    popup!: MbscPopup;
    @Output() dateRangeSelected = new EventEmitter<{startDate:Date,endDate:Date}>();
    dateInput = '';
    selected = 'custom';
    selectedDate: any = [startDate, endDate];
    disableInput = false;
    respPopup = {
        responsive: {
            xsmall: {
                display: 'bottom',
                touchUi: true,
                buttons: [{
                        text: 'Apply',
                        handler: function () {}
                    },
                    'cancel'
                ]
            },
            custom: {
                breakpoint: 559,
                buttons: [],
                display: 'anchored',
                anchor: document.querySelector('.date-filter-input'),
                anchorAlign: 'start',
                touchUi: false,
                scrollLock: false,
                showArrow: false,
                maxWidth: 920
            }
        }
    };

    respSelect = {
        xsmall: {
            touchUi: true
        },
        small: {
            touchUi: false
        }
    };

    myData = [{
        value: 'custom',
        text: 'Custom'
    }, {
        value: 'today',
        text: 'Today'
    }, {
        value: 'yesterday',
        text: 'Yesterday'
    }, {
        value: 'last-week',
        text: 'Last week'
    }, {
        value: 'last-7-days',
        text: 'Last 7 days'
    }, {
        value: 'last-30-days',
        text: 'Last 30 days'
    }];

    selectOptions: MbscSelectOptions = {
        responsive: {
            xsmall: {
                touchUi: true
            },
            small: {
                touchUi: false
            }
        },
        onChange: (event) => {
            const s = event.value;
            if (s === 'custom') {
                this.disableInput = false;
            } else {
                this.disableInput = true;

                switch (s) {
                    case 'today':
                        this.selectedDate = [DateTime.now().startOfTheDay().getDate(), DateTime.now().startOfTheDay().getDate()];
                        break;
                    case 'yesterday':
                        this.selectedDate = [DateTime.now().startOfTheDay().addDays(-1).getDate(), DateTime.now().startOfTheDay().addDays(-1).getDate()];
                        break;
                    case 'last-week':
                        this.selectedDate = [new Date(now.getFullYear(), now.getMonth(), monday - 7),
                            new Date(now.getFullYear(), now.getMonth(), monday - 1)
                        ];
                        break;
                    case 'last-7-days':
                        this.selectedDate = [DateTime.now().startOfTheDay().addDays(-6).getDate(), DateTime.now().startOfTheDay().getDate()];
                        break;
                    case 'last-30-days':
                        this.selectedDate = [DateTime.now().startOfTheDay().addDays(-29).getDate(), DateTime.now().startOfTheDay().getDate()];
                        break;
                    default:
                        break;
                }
            }
            this.selected = s;
        }
    };

    calendarOptions: MbscDatepickerOptions = {
        controls: ['calendar'],
        select: 'range',
        display: 'inline',
        showRangeLabels: false,
        pages: 'auto',
        returnFormat: 'iso8601',
        showOnClick: false,
        showOnFocus: false,
        onChange: () => {
            this.disableInput = false;
        }
    };

    inputClick(): void {
        this.popup.open();
    }

    changeInputValue(start: Date, end: Date): void {
        const locale = options.locale || {};
        const dateFormat = locale.dateFormat || 'MM/DD/YYYY';
        const startDate = new Date(start);
        const endDate = new Date(end);
        this.dateInput = `${startDate instanceof Date ? formatDate(dateFormat, startDate) : ''} - ${endDate instanceof Date ? formatDate(dateFormat, new Date(end)) : ''}`;
        this.dateRangeSelected.emit({ startDate: new Date(start), endDate: new Date(end)});
    }

    changeInputDataValue(start: Date, end: Date): void {
        const locale = options.locale || {};
        let startDateArray = start.toString().split('-')
        let endDateArray = end.toString().split('-')
        if (startDateArray.length < 3) {
            startDateArray = moment(start).format('YYYY-MM-DD').toString().split('-')
        }
        if (endDateArray.length < 3) {
            endDateArray = moment(end).format('YYYY-MM-DD').toString().split('-')
        }
        this.dateInput = startDateArray[1] + '/' + startDateArray[2] + '/' + startDateArray[0] + '-' + endDateArray[1] + '/' + endDateArray[2] + '/' + endDateArray[0];
        console.log(this.dateInput);
        this.dateRangeSelected.emit({ startDate: new Date(start), endDate: new Date(end) });
    }

    applyClick(): void {
        const date = this.selectedDate;
        if (this.disableInput) {
            this.changeInputValue(date[0], date[1] || date[0]);
        }
        else {
            this.changeInputDataValue(date[0], date[1] || date[0]);
        }
        this.popup.close();
    }

    cancelClick(): void {
        this.popup.close();
    }

    ngOnInit(): void {
        this.changeInputValue(startDate, endDate);
    }

}
