import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';
import { AzureAppConfigurationService } from 'src/app/azure-app-configuration.service';
import { SafeUrlPipe } from 'src/app/commons/pipes/safe.pipe';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'hs-navigation-map',
  templateUrl: './navigation-map.component.html',
  styleUrls: ['./navigation-map.component.scss']
})
export class NavigationMapComponent implements OnInit {
    //@ViewChild('mapContainer', { static: false }) gmap!: ElementRef;
    //public lat:number = 0;
    //public lng:number = 0;
    private baseGoogleUrl = "https://www.google.com/maps/embed/v1/place?key=";
    public url : any;
    private key_nav_map : any;
    public safeUrl: SafeResourceUrl = {} as SafeResourceUrl;

    @Input()
    set address(value:string) {
        if (value) {
            this.key_nav_map = environment.key_nav_map;
            this.url = this.baseGoogleUrl+this.key_nav_map;
            this.safeUrl = this.safe.transform(this.url + value);
        }
    }
    constructor(private safe: SafeUrlPipe,
        private azureAppConfigService : AzureAppConfigurationService
    ) { }
    ngOnInit(): void { }

}
