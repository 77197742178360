<div id="schedule-component-panel">
    <div class="bg-offwhite p-3 pt-2">
        <div class="d-md-flex">
            <div class="flex-fill mt-1 mb-2">
                <ol class="breadcrumb text-uppercase fs-14 ">
                    <li class="breadcrumb-item"><a href="#"><i class='fas fa-home'></i></a></li>
                    <li *ngIf="appointment" class="breadcrumb-item"><a [routerLink]="'/appointments'">Appointments</a></li>
                    <li class="breadcrumb-item active text-dark"><b>{{appointmentId ? 'Update' : 'Add'}} Schedule</b></li>
                </ol>
            </div>
            <div class="d-none d-md-flex align-items-start breadcrumb__highlight-wrap p-2 p-md-0">
                <div class="mt--375-md">
                    <button class="btn btn-sm btn-outline-dark rounded-pill px-sm-4 my-1 ng-star-inserted" (click)="showPatientAppointments()">Patient Appointment(s)</button>
                </div>
            </div>
        </div>

        <div id="scheduler-panel">
            <div *ngIf="!pageLoadingFailed" class="bg-white p-3 col-height">
                <div class="d-lg-flex mb-3">
                    <div class="d-flex">
                        <div class="user mt-1">{{patient?.name | initial}}</div>
                        <div class="box mt-1">
                            <div><strong class="d-inline-block mw-35">{{patient?.name}}</strong></div>
                            <div class="small"><strong class="d-inline-block mw-35 ">DOB : {{patient?.dateofBirth | date:'MM/dd/yyyy'}}</strong> (Age: <span>{{patient?.age}}</span> Years) / {{patient?.gender}}</div>
                        </div>
                    </div>
                    <div class="d-md-flex flex-fill">
                        <hr class="mt-3 mb-1 d-lg-none" />
                        <div class="d-xl-flex flex-fill ms-md-5 ps-md-4 ms-lg-0 ps-lg-0">
                            <div id="appointment-info" *ngIf="appointment" class="d-inline-block d-xl-block">
                                <div class="d-inline-block border border-light px-2 py-1 me-1 rounded bg-offwhite fs-12 my-2">{{appointment.typeId | apptType}}</div>
                            </div>
                            <div id="appointment-info" *ngIf="appointment" class="d-inline-block d-xl-block">
                                <div class="d-inline-block border border-light px-2 py-1 me-1 rounded bg-offwhite fs-12 my-2">{{appointment.modeId | apptMode}}</div>
                            </div>
                            <div id="appointment-info" class="d-inline-block d-xl-block">
                                <div class="d-inline-block border border-light px-2 py-1 me-1 rounded bg-offwhite fs-12 my-2">{{patient?.status}}</div>
                            </div>
                            <div id="appointment-info" class="d-inline-block d-xl-block">
                                <div class="d-inline-block border border-light px-2 py-1 me-1 rounded bg-offwhite fs-12 my-2">{{patient?.clientName }}</div>
                            </div>
                            <div id="appointment-info" class="d-inline-block d-xl-block">
                                <div class="d-inline-block border border-light px-2 py-1 me-1 rounded bg-offwhite fs-12 my-2">{{ patientPriority }}</div>
                            </div>
                            <div *ngIf="practiceName!=''" id="appointment-info" class="d-inline-block d-xl-block">
                                <div class="d-inline-block border border-light px-2 py-1 me-1 rounded bg-offwhite fs-12 my-2">{{ practiceName }}</div>
                            </div>
                        </div>
                        <div class="justify-content-end">
                            <div class="mt-1">
                                <div class="d-flex">
                                    <div class="box" *ngIf="!selectedPatientContact?.preferredContact || selectedPatientContact?.preferredContact === ''">
                                        <div class="alert alert-danger d-flex align-items-center " role="alert">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-exclamation-triangle-fill flex-shrink-0 me-2" viewBox="0 0 16 16" role="img" aria-label="Warning:">
                                                <use xlink:href="#exclamation-triangle-fill"></use>
                                            </svg>
                                            <div>
                                                Contact number is not available.
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="selectedPatientContact?.preferredContact && selectedPatientContact?.preferredContact?.trim() !== ''">
                                        <div class="d-flex">
                                            <button
                                            (click)="draftappointment()"
                                                type="button"
                                                class="btn btn-dark rounded-pill px-4 contact-number contact-number__hover text-nowrap"
                                                data-bs-dismiss="modal"
                                                style="margin-right: 20px;"
                                                [disabled]="isActive">
                                                Test Telehealth
                                            </button>
                                            <div class="contact-number contact-number__hover text-nowrap" (click)="showPatientContactsDialog()">
                                                <i class="fas fa-phone-alt contact-number__icon"></i>
                                                {{ selectedPatientContact?.preferredContact}}
                                                <span class="contact-number__info">Phone Number</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr class="m-0" />
                <div class="px-3 py-2 bg-primary-light text-primary border-bottom-primary text-md-end">
                    
                    <button type="button" class="btn btn-sm btn-outline-primary rounded-pill px-sm-3 lh-sm me-2 my-1 my-sm-0"
                        data-bs-toggle="modal" data-bs-target="#appointmentWizardModal"><i class="fas fa-plus fs-12 align-middle me-1"></i> Appointment Wizard</button>
                        <!-- <button *ngIf="patient?.centralVisiblility" type="button"
                                class="btn btn-sm btn-outline-primary rounded-pill px-sm-3 lh-sm my-1 my-sm-0"
                                data-bs-toggle="modal" data-bs-target="#centralNurseScheduleModal"
                                (click)="scheduleService.openCentralNurseScheduleModal('open','CN')">
                            <i class="fas fa-plus fs-12 align-middle me-1"></i> Schedule with Central
                        </button> -->
                </div>
                <div class="row">
                    <div class="col-12 col-lg-9 order-lg-2 pt-2">
                        <div *ngIf="selectedPatientContact?.isCallOptOut || selectedPatientContact?.isTextOptOut">
                            <div class="alert alert-danger d-flex alert-dismissible fade show mt-1 mb-2" role="alert">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-exclamation-triangle-fill flex-shrink-0 me-2" viewBox="0 0 16 16" role="img" aria-label="Warning:">
                                    <use xlink:href="#exclamation-triangle-fill"></use>
                                </svg>
                                <div class="flex-fill">
                                    Patient has Opted out of Automated Text reminders for this number
                                </div>
                                <div>
                                    <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                </div>
                            </div>
                        </div>
                        <hs-task-list *ngIf="!appointmentId" [patient]="patient!" (onChange)="selectedTask = $event"></hs-task-list>
                        <div *ngIf="appointment" [ngClass]="{'mb-3':appointment.preAppointmentTasks && appointment.preAppointmentTasks.length > 0}">
                            <h5 class="p-2 bg-secondary d-flex justify-content-between"
                                *ngIf="appointment.preAppointmentTasks && appointment.preAppointmentTasks.length > 0">
                                <div>Scheduling Tasks associated with this appointment</div>
                            </h5>
                            <div *ngFor="let taskItem of appointment.preAppointmentTasks; let itemIndex = index">
                                <div class="d-flex p-2">
                                    <div class="flex-fill">
                                        <strong>{{taskItem.task}}</strong> - {{taskItem.description}}
                                        <hs-task-status [status]="taskItem.statusId"></hs-task-status>
                                    </div>
                                    <div class="text-end">
                                        <div class="text-end">
                                            <div class="fs-12">
                                                {{taskItem.dueDate | date:'MM/dd/yyyy'}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr class="m-0" />
                            </div>
                        </div>
                        <div class="d-xl-flex">
                            <div class="flex-fill">
                                <h3 class="h5 mt-1 mb-2 pe-2 text-nowrap-md">CARE TEAM MEMBER CALENDAR(S)</h3>
                            </div>
                            <div>
                                <div class="d-inline-block border border-light px-2 py-1 me-1 mb-1 rounded bg-offwhite fs-12">
                                    <strong>Eligibility End Date</strong> - {{patient?.eligibilityEndDate | date:'MM/dd/yyyy'}}
                                </div>
                                <div class="d-inline-block border border-light px-2 py-1 me-1 mb-1 rounded bg-offwhite fs-12">
                                    <strong>Language</strong> - {{patient?.language}}
                                </div>
                                <div class="d-inline-block border border-light px-2 py-1 mb-1 rounded bg-offwhite fs-12">
                                    <strong>Preferred Time</strong> - {{patient?.preferredTime}}
                                </div>
                            </div>
                        </div>
                        <hs-schedule-calendar [patient]="patient!"
                                            [selectedTasks]="selectedTask"
                                            [address]="address"
                                            [addressType]="addressType"
                                            [contactInfo]="selectedPatientContact"
                                            [appointment]="appointment!"
                                            [appointmentId]="appointmentId!"
                                            [addCentralCareNurse]="centralCareNurse"></hs-schedule-calendar>

                    </div>
                    <div class="col-12 col-lg-3 order-lg-1">
                        <div id="map-div" class="map-div">
                            <div class="d-flex">
                                <div class="flex-fill">
                                    <h3 class="h5 my-3">Patient Location</h3>
                                </div>
                                <div>
                                    <a class="d-inline-block cursor-pointer px-1 py-3 text-dark" data-bs-toggle="modal"
                                    data-bs-target="#updateAddress"
                                    (click)="openUpdateAddressDialog()">
                                        <i class="fas fa-edit fs-16"></i>
                                    </a>
                                </div>
                            </div>
                            <div class="fs-14 pb-2">
                                <hr class="mt-0" />
                                <div class="text-nowrap"><i class="fas fa-map-marker-alt text-primary me-1 fs-16"></i> <strong class="d-inline-block">{{addressType}}</strong></div>
                                <div>
                                    {{address}}
                                </div>
                            </div>
                            <div class="alert alert-danger d-flex align-items-center " role="alert" *ngIf="!address">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-exclamation-triangle-fill flex-shrink-0 me-2" viewBox="0 0 16 16" role="img" aria-label="Warning:">
                                    <use xlink:href="#exclamation-triangle-fill"></use>
                                </svg>
                                <div>
                                    Location not found.
                                </div>
                            </div>
                            <div class="map-wrapper"><hs-navigation-map [address]="address" *ngIf="address"></hs-navigation-map></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="pageLoadingFailed" class="alert alert-danger text-center">
            Unable to load Patient Details!
        </div>
    </div>

    <div class="d-md-none btn-float">
        <div class="btn-float__content">
            <button class="btn btn-sm btn-primary rounded-pill px-3 px-sm-4" (click)="showPatientAppointments()">Patient Appointments</button>
        </div>
    </div>

    <!-- ContactModal Modal -->
    <div class="modal fade" id="contactModal" tabindex="-1" aria-labelledby="Contact Modal" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="Contact Modal">Providers and Contacts pop</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <table class="table table-bordered m-0">
                        <thead>
                            <tr>
                                <th scope="col" width="28">
                                </th>
                                <th scope="col">Contact Type</th>
                                <th scope="col">Contact Name</th>
                                <th scope="col">Contact Number</th>
                                <th scope="col" class="text-center" width="90">Is Primary</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th scope="row">
                                    <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" checked>
                                </th>
                                <td>Access sergen</td>
                                <td>Provider</td>
                                <td>+1(811) 9118-814</td>
                                <td class="text-center"><i class="fas fa-check text-success"></i></td>
                            </tr>
                            <tr>
                                <th scope="row">
                                    <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2">
                                </th>
                                <td>Patient</td>
                                <td>Ariank</td>
                                <td>+1(811) 9118-812</td>
                                <td class="text-center"></td>
                            </tr>
                            <tr>
                                <th scope="row">
                                    <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault3">
                                </th>
                                <td>PCP</td>
                                <td>Maxank</td>
                                <td>+1(811) 9118-813</td>
                                <td class="text-center"></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="modal-footer justify-content-between">
                    <button type="button" class="btn btn-outline-dark rounded-pill px-4" data-bs-dismiss="modal">Cancel</button>
                    <button type="button" class="btn btn-dark rounded-pill px-4" data-bs-dismiss="modal">Update Contact</button>
                </div>
            </div>
        </div>
    </div>

    <!-- AddressModal Modal -->
    <div class="modal fade" id="updateAddress" tabindex="-1" aria-labelledby="Address Modal" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-md">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="Contact Modal">Change Address for this Appointment</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="form-floating">
                        <div class="mb-3">
                            <label for="addressType" class="form-label fw-bold">Location Name</label>
                            <input id="addressType" type="text"
                                class="form-control"
                                placeholder="addressType"
                                [(ngModel)]="addressType" />

                        </div>
                        <div class="mb-3">
                            <label for="addressType" class="form-label fw-bold">Address</label>
                            <textarea class="form-control " rows="3"
                                    id="floatingAddress"
                                    placeholder="Write address here"
                                    [(ngModel)]="address">{{address}}</textarea>
                        </div>

                    </div>
                </div>
                <div class="modal-footer text-end">
                    <button type="button"
                            class="btn btn-dark rounded-pill px-4"
                            data-bs-dismiss="modal"
                            (click)="updateAddress()">
                        Change Address
                    </button>
                </div>
            </div>
        </div>
    </div>
    <!-- Central Nurse Schedule Modal -->
    <div class="modal fade" id="centralNurseScheduleModal" tabindex="-1" aria-labelledby="Central Nurse Modal" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-lg">
            <hs-search-central-nurse [patient]="patient!" (submitCareNurseEvent)="submitCareNurseEvent($event)"></hs-search-central-nurse>
        </div>
    </div>

    <!-- Central Nurse Schedule Modal -->
    <div class="modal fade" id="appointmentWizardModal" tabindex="-1" aria-labelledby="Central Nurse Modal" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-xl">
            <div class="modal-content">
                <div class="modal-header">
                    <div>
                        <h5 id="Contact Modal" class="modal-title search-nurse">Search Care Member(s) Availability</h5>
                        <div>Search care member based on the availability</div>
                    </div>
                    <button type="button" data-bs-dismiss="modal" aria-label="Close" class="btn-close" (click)="resetForm()"></button>
                </div>

                <!--Step 1 Start -->
            
                <form [formGroup]="searchAppointmentForm" (ngSubmit)="CareMemberAppointment()">
                <div class="modal-form p-3 appointment-start">
                    <label for="selectDateSearching" class="form-label fw-bold">Select Date for searching available appointment slot</label>
                    <div class="row g-2">
                        <div class="col-sm-6 col-lg-4 col-xl-3">
                            <div class="date-range">
                                <mbsc-datepicker  [controls]="['date']" min="`${this.Today.toLocaleDateString('en-US')}`" formControlName="selectDateSearching" ></mbsc-datepicker>
                            </div>
                        </div>
                    </div>
                </div>
                <!--Step 1 End -->

                <!--Step 2 Start -->
                <div class="modal-form p-3">
                        <div class="row g-2">
                            <div class="col-sm-4 col-lg-2">
                                <div class="input-group">
                                    <select formControlName="IdTaskType" class="form-select" (change)="typeChanged()">
                                        <option value="" selected>Task Type</option>
                                        <option *ngFor="let type of appointmentTypes" [ngValue]="type.typeId">{{type.type}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-sm-4 col-lg-2">
                                <div class="input-group">
                                    <select formControlName="IdAppointmentType" class="form-select" (change)="modeChanged()">
                                        <option value="" selected>Appointment Type</option>
                                        <option *ngFor="let mode of appointmentModes" >{{ mode.modeId | apptMode }}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-sm-4 col-lg-2">
                                <div class="input-group">
                                    <select formControlName="IdDuration" class="form-select">
                                        <option value="" selected>Duration</option>
                                        <option *ngFor="let duration of durations" [value]="duration">{{duration}} Min</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-sm-4 col-lg-2">
                                <div class="input-group">
                                    <select formControlName="IdStaffType" class="form-select" (change)="staffChanged($event)">
                                        <option value="" selected>Staff Type</option>
                                        <option *ngFor="let type of staffTypes">{{type.name}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-sm-4 col-lg-3">
                                <div class="position-relative">
                                    <div class="input-group">
                                        <input formControlName="careMemberName" id="careMember" type="text" placeholder="Care Member (Optional)" class="form-control" (input)="searchText($event)" [disabled]="disableSearch"  /> 
                                    </div>
                                    <div *ngIf=" showResults && careMembers && careMembers.length != 0">
                                        <div class="care-member-result">
                                            <div class="care-member-result__item" *ngFor="let careMember of careMembers"
                                                (click)="selectCareMember(careMember)" (keydown)="handleKeyDown($event)" (keyup)="handleKeyUp($event)"
                                                (keypress)="handleKeyPress($event)">
                                                <strong class="d-block">{{careMember.name}}</strong>
                                                <span class="fs-12">{{careMember.emailId}}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="noResult" class="care-member-result">
                                        <div class=" alert alert-success text-center">No licensed Care Member found!</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-4 col-lg-1">
                                <button type="submit"  class="btn btn-primary w-100" [disabled]="!searchAppointmentForm.valid   || careMemberList.length === 3  || !isCarememberNameValid " >Add</button>
                            </div>
                        </div>
                    <div class="mt-3">
                        <div class="px-3 py-1 bg-white border rounded-3 border mt-1" *ngFor="let list of careMemberList; let i =index ">
                            <div class="d-flex align-items-center" >
                                <div class="flex-fill">
                                    <div class="row align-items-center">
                                        <div class="col-lg-6">
                                            {{ list.staffType }} - <strong *ngIf="list.careMemberName !== ''">{{ list.careMemberName }}</strong>
                                            <span *ngIf="list.careMemberName === ''"><strong>Any</strong></span>                                            
                                        </div>
                                        <div class="col-4 col-lg-2">
                                            {{list.taskName}}
                                        </div>
                                        <div class="col-4 col-lg-2">
                                            {{list.appointmentType | apptMode}}
                                        </div>
                                        <div class="col-4 col-lg-2">
                                            {{list.duration}}
                                        </div>
                                    </div>
                                </div>
                                <button type="button" aria-label="Close" class="btn-close p-0" (click)="removeEntry(i)"  ></button>
                            </div>
                        </div> 
                    </div>
                </div>
                </form>
                <!--Step 2 End -->

                <div class="modal-body" id="result-panel-slots">
                    <div class="results-panel">
                        <div *ngIf="resultLength == 1 && !this.maxDaysLimitExceed && !this.noCareMemberFound && !this.isTerminated ">
                            <div class="d-none d-lg-block">
                                <div class="slot-row no-title">
                                    <div class="slot-column slot-column--3">
                                        <ng-container *ngIf="careMemberSlots && careMemberSlots[0].careMemberResponseModels">
                                            <strong>{{ careMemberSlots[0].careMemberResponseModels[0].staffType }}</strong>
                                        </ng-container>
                                    </div>
                                    <div class="slot-column slot-column--3">
                                        <strong>Date</strong>
                                    </div>
                                    <div class="slot-column slot-column--3">
                                        <strong>Time Slot</strong>
                                    </div>
                                </div>
                            </div>
                            <div class="scroll-modal-content">
                                <div class="slot-row cursor-pointer" *ngFor="let slots of careMemberSlots" (click)="selectResult(slots)" (keydown)="handleKeyDown($event)" (keyup)="handleKeyUp($event)" (keypress)="handleKeyPress($event)">
                                    <div class="slot-column slot-column--3">
                                        <div class="d-flex" *ngIf="slots != null && slots.careMemberResponseModels != null">
                                            <div class="d-lg-none">
                                                <strong class="table__info-lg">{{ slots.careMemberResponseModels[0].staffType }}</strong>
                                            </div>
                                            <div class="text-truncate">{{ slots.careMemberResponseModels[0].careMemberName }}</div>
                                        </div>
                                    </div>
                                    <div class="slot-column slot-column--3">
                                        <div class="d-flex">
                                            <div class="d-lg-none">
                                                <strong class="table__info-lg">Date</strong>
                                            </div>
                                            <div class="text-truncate">{{ appointmentScheduleDay(slots.slotStartDate) }} - {{ appointmentScheduledDate(slots.slotStartDate)}}</div>
                                        </div>
                                    </div>
                                    <div class="slot-column slot-column--3">
                                        <div class="d-flex">
                                            <div class="d-lg-none">
                                                <strong class="table__info-lg">Time Slot</strong>
                                            </div>
                                            <div class="text-truncate">{{appointmentScheduledTime(slots.slotStartDate)}} - {{ appointmentScheduledTime(slots.slotEndDate) }}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>                            
                        </div>
                        <div *ngIf="resultLength == 2 && !this.maxDaysLimitExceed && !this.noCareMemberFound && !this.isTerminated">
                            <div class="d-none d-lg-block">
                                <div class="slot-row no-title">
                                    <div class="slot-column slot-column--4">
                                        <ng-container *ngIf="careMemberSlots != null && careMemberSlots[0].careMemberResponseModels != null">
                                            <strong>{{ careMemberSlots[0].careMemberResponseModels[0].staffType }}</strong>
                                        </ng-container>
                                    </div>
                                    <div class="slot-column slot-column--4">
                                        <ng-container *ngIf="careMemberSlots != null && careMemberSlots[0].careMemberResponseModels != null">
                                            <strong>{{ careMemberSlots[0].careMemberResponseModels[1].staffType }}</strong>
                                        </ng-container>
                                    </div>
                                    <div class="slot-column slot-column--4">
                                        <strong>Date</strong>
                                    </div>
                                    <div class="slot-column slot-column--4">
                                        <strong>Time Slot</strong>
                                    </div>
                                </div>
                            </div>
                            <div class="scroll-modal-content">
                                <div class="slot-row cursor-pointer" *ngFor="let slots of careMemberSlots" (click)="selectResult(slots)" (keydown)="handleKeyDown($event)" (keyup)="handleKeyUp($event)" (keypress)="handleKeyPress($event)">
                                    <div class="slot-column slot-column--4">
                                        <div class="d-flex" *ngIf="slots != null && slots.careMemberResponseModels != null">
                                            <div class="d-lg-none">
                                                <strong class="table__info-lg">{{ slots.careMemberResponseModels[0].staffType }}</strong>
                                            </div>
                                            <div class="text-truncate">{{ slots.careMemberResponseModels[0].careMemberName }}</div>
                                        </div>
                                    </div>
                                    <div class="slot-column slot-column--4">
                                        <div class="d-flex" *ngIf="slots != null && slots.careMemberResponseModels != null">
                                            <div class="d-lg-none">
                                                <strong class="table__info-lg">{{ slots.careMemberResponseModels[1].staffType }}</strong>
                                            </div>
                                            <div class="text-truncate">{{ slots.careMemberResponseModels[1].careMemberName }}</div>
                                        </div>
                                    </div>
                                    <div class="slot-column slot-column--4">
                                        <div class="d-flex">
                                            <div class="d-lg-none">
                                                <strong class="table__info-lg">Date</strong>
                                            </div>
                                            <div class="text-truncate">{{ appointmentScheduleDay(slots.slotStartDate) }} - {{ appointmentScheduledDate(slots.slotStartDate)}}</div>
                                        </div>
                                    </div>
                                    <div class="slot-column slot-column--4">
                                        <div class="d-flex">
                                            <div class="d-lg-none">
                                                <strong class="table__info-lg">Time Slot</strong>
                                            </div>
                                            <div class="text-truncate">{{appointmentScheduledTime(slots.slotStartDate)}} - {{ appointmentScheduledTime(slots.slotEndDate) }}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>                            
                        </div>
                        <div *ngIf="resultLength == 3 && !this.maxDaysLimitExceed && !this.noCareMemberFound && !this.isTerminated">
                            <div class="d-none d-lg-block">
                                <div class="slot-row no-title">
                                    <div class="slot-column slot-column--5">
                                        <ng-container *ngIf="careMemberSlots != null && careMemberSlots[0].careMemberResponseModels != null">
                                            <strong>{{ careMemberSlots[0].careMemberResponseModels[0].staffType }}</strong>
                                        </ng-container>
                                    </div>
                                    <div class="slot-column slot-column--5">
                                        <ng-container *ngIf="careMemberSlots != null && careMemberSlots[0].careMemberResponseModels != null">
                                            <strong>{{ careMemberSlots[0].careMemberResponseModels[1].staffType }}</strong>
                                        </ng-container>
                                    </div>
                                    <div class="slot-column slot-column--5">
                                        <ng-container *ngIf="careMemberSlots != null && careMemberSlots[0].careMemberResponseModels != null">
                                            <strong>{{ careMemberSlots[0].careMemberResponseModels[2].staffType }}</strong>
                                        </ng-container>
                                    </div>
                                    <div class="slot-column slot-column--5">
                                        <strong>Date</strong>
                                    </div>
                                    <div class="slot-column slot-column--5">
                                        <strong>Time Slot</strong>
                                    </div>
                                </div>
                            </div>
                            <div class="scroll-modal-content">
                                <div class="slot-row cursor-pointer" *ngFor="let slots of careMemberSlots" (click)="selectResult(slots)" (keydown)="handleKeyDown($event)" (keyup)="handleKeyUp($event)" (keypress)="handleKeyPress($event)">
                                    <div class="slot-column slot-column--5">
                                        <div class="d-flex" *ngIf="slots != null && slots.careMemberResponseModels != null">
                                            <div class="d-lg-none">
                                                <strong class="table__info-lg">{{ slots.careMemberResponseModels[0].staffType }}</strong>
                                            </div>  
                                            <div class="text-truncate">{{ slots.careMemberResponseModels[0].careMemberName }}</div>
                                        </div>
                                    </div>
                                    <div class="slot-column slot-column--5">
                                        <div class="d-flex" *ngIf="slots != null && slots.careMemberResponseModels != null">
                                            <div class="d-lg-none">
                                                <strong class="table__info-lg">{{ slots.careMemberResponseModels[1].staffType }}</strong>
                                            </div>
                                            <div class="text-truncate">{{ slots.careMemberResponseModels[1].careMemberName }}</div>
                                        </div>
                                    </div>
                                    <div class="slot-column slot-column--5">
                                        <div class="d-flex" *ngIf="slots != null && slots.careMemberResponseModels != null">
                                            <div class="d-lg-none">
                                                <strong class="table__info-lg">{{ slots.careMemberResponseModels[2].staffType }}</strong>
                                            </div>
                                            <div class="text-truncate">{{ slots.careMemberResponseModels[2].careMemberName }}</div>
                                        </div>
                                    </div>
                                    <div class="slot-column slot-column--5">
                                        <div class="d-flex">
                                            <div class="d-lg-none">
                                                <strong class="table__info-lg">Date</strong>
                                            </div>
                                            <div class="text-truncate">{{ appointmentScheduleDay(slots.slotStartDate) }} - {{ appointmentScheduledDate(slots.slotStartDate)}}</div>
                                        </div>
                                    </div>
                                    <div class="slot-column slot-column--5">
                                        <div class="d-flex">
                                            <div class="d-lg-none">
                                                <strong class="table__info-lg">Time Slot</strong>
                                            </div>
                                            <div class="text-truncate">{{appointmentScheduledTime(slots.slotStartDate)}} - {{ appointmentScheduledTime(slots.slotEndDate) }}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>                            
                        </div>
                        <div class="results-panel" *ngIf="this.isTerminated">
                            <div class="alert alert-danger text-center py-4 border border-danger rounded-3">
                                <div class="mb-2">
                                    <i class="fas fa-exclamation-triangle text-danger me-1 fs-24"></i>
                                </div>
                                <div>The Care Team Member you are searching for has an inactive date in RenalIQ Care. Please perform another search or reach out to you manager.
                                </div>
                            </div>
                        </div>
                        <div class="results-panel" *ngIf="this.maxDaysLimitExceed">
                            <div class="alert alert-danger text-center py-4 border border-danger rounded-3">
                                <div class="mb-2">
                                    <i class="fas fa-exclamation-triangle text-danger me-1 fs-24"></i>
                                </div>
                                <div>The current search has exceeded the maximum {{this.careMemberSlots[0].maxDays}} -day search window. Please perform another search.
                                </div>
                            </div>
                        </div>
                        <div class="results-panel" *ngIf="resultLength == 4">
                            <div class="alert alert-danger text-center py-4 border border-danger rounded-3">
                                <div class="mb-2">
                                    <i class="fas fa-exclamation-triangle text-danger me-1 fs-24"></i>
                                </div>
                                <div>No Care Member available for the Day<br/>
                                   Click the <b>Next</b> button to search for next available day(s)
                                </div>
                            </div>
                        </div>
                        <div class="results-panel" *ngIf="noCareMemberFound">
                            <div class="alert alert-danger text-center py-4 border border-danger rounded-3">
                                <div class="mb-2">
                                    <i class="fas fa-exclamation-triangle text-danger me-1 fs-24"></i>
                                </div>
                                <div>No Care Team Member available. Please perform another search.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer justify-content-between w-100">
                    <div class="d-flex text-start">
                        <button type="button" class="btn btn-sm btn-dark rounded-pill px-4" [disabled]="isResetDisabled" (click)="resetForm()">Reset Search</button>
                    </div>
                    <div class="d-flex text-end">
                        <button type="button" class="btn btn-sm btn-dark rounded-pill px-4" [disabled]="isPrevDisable" (click)="goToNewDate(-1)"><i class="fas fa-angle-double-left"></i> Previous</button>
                        <button type="button" class="btn btn-sm btn-dark rounded-pill px-4 ms-2" [disabled]="isNextDisable || isTerminated" (click)="goToNewDate(+1)">Next <i class="fas fa-angle-double-right"></i></button>
                        <button type="button" class="btn btn-sm btn-dark rounded-pill px-4 ms-2" [disabled]="isSearchEnable" (click)="onSearch()">Search</button>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
