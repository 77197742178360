import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { AppointmentManagementService, ViewAppointment } from '../../api-client';
import { AppointmentService } from '../../api-client/api/appointment.service';
import { Loader } from '../../commons-ui/loader';

@Component({
  selector: 'hs-appointments-list',
  templateUrl: './appointments-list.component.html',
  styleUrls: ['./appointments-list.component.scss']
})
export class AppointmentsListComponent implements OnInit {
    appointmentsList: ViewAppointment[] = [];
    patientId: number=0;
    constructor(private appointmentService: AppointmentService,
        private route: ActivatedRoute,
        private readonly appointmentManagementService : AppointmentManagementService) { }

    ngOnInit(): void {
        this.route.params
            .subscribe(
                (params: Params) => {
                    this.patientId = +params['id'];
                    console.log(this.patientId);
                }
        );
        this.loadAppointments(this.patientId);
    }

    private async loadAppointments(id: any): Promise<void> {
        Loader.showLoader("appointments-panel"); 
        try {
            let resultAL = await this.appointmentManagementService.appointmentManagementGetAppointmentHistory(id).toPromise();
            if(resultAL != undefined){
                this.appointmentsList = resultAL;
            }
        }
        finally {
            Loader.hideLoader("appointments-panel");
        }
    }

}
