/**
 * Somatus Hybrid Scheduler API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface AvailableSlotsBestTimeResponse { 
    startDateTime: Date;
    endDateTime: Date;
    name: string;
    emailId: string;
    appointmentCount: number;
    appointmentDuration?: number;
    careMemberId: number;
    isAvailable: boolean;
    type: string;
    selectedMember: boolean;
    searchNoOfDays: number;
    searchEndDateTime: Date;
    rank: number;
}