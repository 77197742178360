import { Component, Input, OnInit } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';
import { SafeUrlPipe } from 'src/app/commons/pipes/safe.pipe';
import { ReferenceDataProvider } from 'src/app/commons/reference-data-provider';

@Component({
  selector: 'hs-navigation-map',
  templateUrl: './navigation-map.component.html',
  styleUrls: ['./navigation-map.component.scss']
})
export class NavigationMapComponent implements OnInit {
    //@ViewChild('mapContainer', { static: false }) gmap!: ElementRef;
    //public lat:number = 0;
    //public lng:number = 0;
    private baseGoogleUrl = "https://www.google.com/maps/embed/v1/place?key=";
    public url : any;
    private key_nav_map : any;
    public safeUrl: SafeResourceUrl = {} as SafeResourceUrl;
    private inputValue : string = '';

    @Input()
    set address(value:string) {
        if (value) {
            this.inputValue = value;
        }
    }
    constructor(private safe: SafeUrlPipe,
        private readonly refernceDataProvider : ReferenceDataProvider
    ) { }
    ngOnInit(): void {
        if(this.inputValue){
            this.key_nav_map = this.refernceDataProvider.getAzureConfigurationById('key_nav_map') ?? '';
            this.url = this.baseGoogleUrl+this.key_nav_map;
            this.safeUrl = this.safe.transform(this.url + this.inputValue);
        }
    }

    
}
