import { Component, OnInit } from '@angular/core';
import { GenericModal } from 'src/app/commons-ui/generic-modal/generic-modal';
import { GenericModalButtonInfo } from 'src/app/commons-ui/generic-modal/generic-modal-service';

@Component({
  selector: 'hs-existing-appointment',
  templateUrl: './existing-appointment.component.html',
  styleUrls: ['./existing-appointment.component.scss']
})
export class ExistingAppointmentComponent implements OnInit, GenericModal<void, void> {

  constructor() { }

  getTitle(): string {
    return "Appointment";;
  }

  getSubTitle(): string {
    return "Reschedule appointment";
  }

  setInputData(data: void): void {
    ;
  }

  setOutputDataHandler(handler: (data: void) => void): void {
    ;
  }

  getLeftButtons(): GenericModalButtonInfo[] {
    return [];
  }

  getRightButtons(): GenericModalButtonInfo[] {
    return [
      {
          label: "Close",
          cssClasses: "btn btn-outline-dark rounded-pill px-4",
          action: async () => true,
      },
    ];
  }

  onCloseClicked(): Promise<boolean> {
    return Promise.resolve(true);
  }

  ngOnInit(): void {
  }
}
