import { Component, OnInit } from '@angular/core';
import { tap } from 'rxjs/internal/operators/tap';
import { AppointmentService } from '../api-client';
import { AppointmentsListResponse } from '../api-client/model/appointmentsListResponse';
import { TodayAppointmentsResponse } from '../api-client/model/todayAppointmentsResponse';
import { Loader } from '../commons-ui/loader';
import { CommonConstants } from '../commons/common-constants';
import { DateTime } from '../commons/date-time';
import { LoggingServiceTsService } from '../store/loggingService/logging.service';

@Component({
    selector: 'hs-appointments',
    templateUrl: './appointments.component.html',
    styleUrls: ['./appointments.component.scss']
})
export class AppointmentsComponent implements OnInit {
    public CANCELLED: string = CommonConstants.APPOINTMENT_CANCEL;
    private pageSize: number = 10;
    private maxPagesToDisplay: number = 10;

    public todayStatuses: StatusTab[] = [
        { code: "", label: "Scheduled for Today", count: 0 },
        { code: "tentative", label: "Tentative", count: 0 },
    ];

    public statuses: StatusTab[] = [
        { code: "", label: "All", count: 0 },
        { code: "accepted", label: "Accepted", count: 0 },
        { code: "tentative", label: "Tentative", count: 0 },
        { code: "declined", label: "Declined", count: 0 },
        { code: "cancelled", label: "Cancelled", count: 0 },

    ];

    public status?: string;
    public text?: string;

    public pageNumber: number = 0;

    public todayAppointments?: TodayAppointmentsResponse;
    public appointmentsList?: AppointmentsListResponse;

    public pageNumbers: number[] = [];
    public selectedDateRange: string = '';
    public startDate: Date = DateTime.now().startOfTheDay().getDate();
    public endDate: Date = DateTime.now().startOfTheDay().getDate();
    public createdByCheckbox: boolean = false;

    constructor(private appointmentService: AppointmentService,private logging : LoggingServiceTsService) { }

    ngOnInit(): void {
        this.startDate = DateTime.now().startOfTheDay().getDate();
        this.endDate = DateTime.now().startOfTheDay().getDate();
        this.loadTodayAppointments();
        localStorage.removeItem('requestedUrl');
        let session = this.logging.GetSessionDetails();
        this.logging.logPageView("Appointment Page",window.location.href,session);
    }

    public async loadTodayAppointments(): Promise<void> {
        Loader.showLoader("today-status-panel");
        try {
            this.todayAppointments = await this.appointmentService.appointmentGetTodayAppointments().toPromise();
            this.todayStatuses.forEach(status => {
                status.count = 0;
                this.todayAppointments?.countsByStatuses?.forEach(statusCounts => {
                    if (status.code == statusCounts.status) {
                        status.count = statusCounts.count;
                    } else if (status.code.length == 0) {
                        status.count = this.todayAppointments?.totalCount ?? 0;
                    }
                });
            });
        } finally {
            Loader.hideLoader("today-status-panel");
        }
    }

    public async loadappointmentsList(): Promise<void> {
        Loader.showLoader("appointments-panel");
        try {
            this.appointmentsList = await this.appointmentService.appointmentGetAppointments(this.status, this.text, this.pageNumber, this.pageSize, this.startDate, this.endDate, this.createdByCheckbox).toPromise();
            this.pageNumbers = [];
            if (this.appointmentsList!.totalPages > this.maxPagesToDisplay) {
                for (let index = 0; index < this.maxPagesToDisplay / 2; index++) {
                    this.pageNumbers.push(index);
                }
                this.pageNumbers.push(-1);
                for (let index = this.appointmentsList!.totalPages - this.maxPagesToDisplay / 2; index < this.appointmentsList!.totalPages; index++) {
                    this.pageNumbers.push(index);
                }
            } else {
                for (let index = 0; index < this.appointmentsList!.totalPages; index++) {
                    this.pageNumbers.push(index);
                }
            }

            this.statuses.forEach(status => {
                status.count = 0;
                this.appointmentsList?.countsByStatuses?.forEach(statusCounts => {
                    if (status.code == statusCounts.status) {
                        status.count = statusCounts.count;
                    } else if (status.code.length == 0) {
                        status.count = this.appointmentsList?.totalCount ?? 0;
                    }
                });
            });
        } finally {
            Loader.hideLoader("appointments-panel");
        }
    }

    public searchText(value: any) {
        if (value.length > 2 || value.length === 0) {
            this.text = value;
            this.pageNumber = 0;
            this.loadappointmentsList();
        }
    }

    public setStatus(status: string) {
        this.status = status;
        this.pageNumber = 0;
        this.loadappointmentsList();
    }

    public gotoPage(page: number): void {
        if (page < 0 || page >= (this.appointmentsList?.totalPages ?? 0)) {
            return;
        }

        this.pageNumber = page;
        this.loadTodayAppointments();
        this.loadappointmentsList();
    }

    public dateRangeSelectionEvent(event: any): void {
        this.selectedDateRange = event;
        this.startDate = event.startDate;
        this.endDate = event.endDate;
        this.loadTodayAppointments();
        this.loadappointmentsList();
    }

    public onCheckboxChange(): void {
        this.createdByCheckbox = !this.createdByCheckbox;
        this.loadTodayAppointments();
        this.loadappointmentsList();
    }
}

interface StatusTab {
    code: string;
    label: string;
    count: number;
}
