/**
 * Somatus Hybrid Scheduler API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface CareMemberInfo { 
    id: number;
    name?: string;
    type?: string;
    mobileNo?: string;
    emailId?: string;
    photoUrl?: string;
    state?: Array<string>;
    schedulerId: number;
    scheduleStartDate: Date;
    scheduleEndDate: Date;
    status?: string;
    modeId?: string;
    schedulePreTime: number;
    schedulePostTime: number;
    address?: string;
    appointmentCount: number;
    appointmentDuration?: number;
    distance: number;
    isTerminated?: boolean;
}