<div class="modal-body">
    <div class="row g-2">
        <div class="col-12 col-sm-3">
            <div class="mb-3">
                <label for="Priority" class="form-label fw-bold">Priority</label>
                <input id="Priority" type="text" class="form-control" placeholder="Priority" [value]="patientPriority" readonly/>
            </div>
        </div>

        <div class="col-12 col-sm-6">
            <div class="mb-3">
                <label for="Patient" class="form-label fw-bold">Patient</label>
                <input id="Patient" type="text" class="form-control" placeholder="Patient" [value]="patient?.enrollmentNumber+'-'+patient?.name" readonly/>
            </div>
        </div>
        
        <div class="col-12 col-sm-3">
            <div class="mb-3">
                <label for="preferredLanguage" class="form-label fw-bold"><span class="d-lg-inline d-sm-none">Preferred</span> Language</label>
                <input id="preferredLanguage" type="text" class="form-control" placeholder="Patient" [value]="patient?.language" readonly/>
    
            </div>
        </div>
    </div>
    <div class="row g-2">
        <div class="col-12 col-sm-4">
            <div class="mb-3">
                <label for="scheduleType" class="form-label fw-bold">Task Type</label>
                <select id="scheduleType" class="form-select" name="scheduleType" #scheduleType="ngModel" [(ngModel)]="apptData!.typeId" (change)="typeChanged()" required>
                    <option *ngFor="let type of appointmentTypes" [ngValue]="type.typeId">{{type.type}}</option>
                </select>
            </div>
        </div>
        <div class="col-12 col-sm-8">
            <div class="mb-3">
                <div class="fw-bold pb-1">Appointment Type</div>
                <div class="my-2">
                    <div *ngFor="let mode of appointmentModes" class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" name="inlineRadioOptions" [id]="'mode-'+mode.modeId" [(ngModel)]="apptData.modeId" [value]="mode.modeId" (change)="modeChanged()">
                        <label class="form-check-label" [for]="'mode-'+mode.modeId">{{mode.modeId | apptMode}}</label>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <label for="appointmentTime" class="form-label fw-bold">Appointment Time: {{startDate | usDate}}</label>
    <div class="mb-3">
        <div class="row g-0 mb-3">
            <div *ngIf="apptData.modeId == 'in-home' || apptData.modeId == 'in-clinic' || apptData.modeId == 'assisted-tele-health'" class="col-6 col-sm-3">
                <div class="select-group">
                    <label for="timePre" class="form-label m-0">PRE</label>
                    <select id="timePre" [(ngModel)]="apptData!.schedulePreTime">
                        <option *ngFor="let time of prePostTimes" [ngValue]="time">{{time}} Mins</option>
                    </select>
                </div>
            </div>
            <div class="col-6 col-sm-3">
                <hs-time-field [time]="time!" (onChange)="time = $event!"></hs-time-field>
            </div>
            <div class="col-6 col-sm-3">
                <div class="select-group">
                    <label for="duration" class="form-label m-0">Duration</label>
                    <select id="duration" [(ngModel)]="duration">
                        <option *ngFor="let durationTime of durationTimes" [ngValue]="durationTime">{{durationTime}} Mins</option>
                    </select>
                </div>
            </div>
            <div *ngIf="apptData.modeId == 'in-home' || apptData.modeId == 'in-clinic'  || apptData.modeId == 'assisted-tele-health'" class="col-6 col-sm-3">
                <div class="select-group is-right rounded-0">
                    <label for="timePre" class="form-label m-0">POST</label>
                    <select id="timePre" [(ngModel)]="apptData!.schedulePostTime">
                        <option *ngFor="let time of prePostTimes" [ngValue]="time">{{time}} Mins</option>
                    </select>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="apptData.modeId == 'assisted-tele-health'" class="row g-2">
        <div class="col-12 col-md-6">
            <div class="mb-3">
                <label for="memberInHome" class="form-label fw-bold">
                    Care Member-In-Home
                </label>
                <div for="Location" class="input-group-text bg-white text-base">{{patient?.name}}</div>
            </div>
        </div>
        <div class="col-12 col-md-6">
            <label for="MemberTeleHealth" class="form-label fw-bold">Care Member-TeleHealth</label>
            <div class="input-group mb-3">
                <input type="text" class="form-control w-50" id="memberInHome" value="Alice">
                <select id="MemberTeleHealth" class="form-select text-end border-start-0 text-primary" disabled>
                    <option selected>Available</option>
                    <option value="1">Not Available</option>
                </select>
            </div>
        </div>
    </div>
    <div class="row g-2">
        <div class="col-12">
            <label for="Notes" class="form-label fw-bold">Notes for Care Team Member(s)</label>
            <textarea class="form-control" placeholder="Enter your notes here" id="Notes" [(ngModel)]="apptData.notes"></textarea>
        </div>
    </div>
</div>