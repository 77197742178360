import { Component, Input, OnInit } from '@angular/core';
import { ReferenceDataProvider } from 'src/app/commons/reference-data-provider';
@Component({
  selector: 'hs-care-member-map',
  templateUrl: './care-member-map.component.html',
  styleUrls: ['./care-member-map.component.scss']
})
export class CareMemberMapComponent implements OnInit {

  public url = "";
  constructor(private readonly referenceDataProvider : ReferenceDataProvider) { }

  ngOnInit(): void {
    let key_nav_map = this.referenceDataProvider.getAzureConfigurationById('key_nav_map') ?? '';
    this.url = "https://www.google.com/maps/embed/v1/place?key="+ key_nav_map +"&location=46.414382,10.013988";
  }
  

}
