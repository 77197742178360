import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { AppointmentInfo, AppointmentService, PatientAppointmentService } from 'src/app/api-client';
import { GenericModal } from 'src/app/commons-ui/generic-modal/generic-modal';
import { GenericModalButtonInfo, GenericModalService } from 'src/app/commons-ui/generic-modal/generic-modal-service';
import { Loader } from 'src/app/commons-ui/loader';
import { CommonConstants } from 'src/app/commons/common-constants';
import { DateTime } from 'src/app/commons/date-time';

@Component({
  selector: 'hs-patient-appointments',
  templateUrl: './patient-appointments.component.html',
  styleUrls: ['./patient-appointments.component.scss']
})
export class PatientAppointmentsComponent implements OnInit, GenericModal<number, void> {
  public CANCELLED: string = CommonConstants.APPOINTMENT_CANCEL;
  private patientId?: number;

  public appointments?: AppointmentInfo[];

  constructor(public genericModalService: GenericModalService,
     private appointmentService: AppointmentService,
     private datePipe: DatePipe,
    private readonly patientAppointmentService : PatientAppointmentService) { }

  ngOnInit(): void {
  }

  private async loadData(): Promise<void> {
    Loader.showLoader("appointments-list");
    try {
      this.appointments = await this.patientAppointmentService.patientAppointmentGetPatientAppointments(this.patientId!).toPromise();
    } finally {
      Loader.hideLoader("appointments-list");
    }
  }

  getTitle(): string {
    return "Patient Appointment(s)";
  }

  getLeftButtons(): GenericModalButtonInfo[] {
    return [];
  }

  getRightButtons(): GenericModalButtonInfo[] {
    return [
      {
        label: "Close",
        cssClasses: "btn btn-dark rounded-pill",
        action: () => Promise.resolve(true),
      }
    ];
  }

  getSubTitle(): string {
    return "";
  }

  setInputData(data: number): void {
    this.patientId = data;
    this.loadData();
  }

  setOutputDataHandler(handler: (data: void) => void): void {
  }

  onCloseClicked(): Promise<boolean> {
    return Promise.resolve(true);
  }
  public appointmentScheduledTime(appointment: AppointmentInfo): string | null {
    const timezone = appointment?.scheduleTimeZone ? appointment.scheduleTimeZone : 'US/Eastern';
    const startDate = new Date(new Date(new Date(appointment.scheduleDateTime + "Z").getTime() + appointment.schedulePreTime * 60000).toLocaleString('en-US', { timeZone: timezone }));
    const startTime = startDate ? this.datePipe.transform(startDate, 'shortTime') : '';
    return startTime
}

public appointmentScheduledDate(appointment: AppointmentInfo): string | null {
  const timezone = appointment?.scheduleTimeZone ? appointment.scheduleTimeZone : 'US/Eastern';
    const startDate = new Date(new Date(appointment.scheduleDateTime + "Z").toLocaleString('en-US', { timeZone: timezone }));
    return startDate ? this.datePipe.transform(startDate, 'MM/dd/yyyy') : '';
}
}
