<div class="modal-body">
    <div id="appointments-list" class="bg-white" role="tabpanel" aria-labelledby="Upcoming-tab">
        <div class="pe-2 d-none d-lg-block">
            <div class="row g-2 pe-2">
                <div class="col-lg-11">
                    <div class="row g-2">
                        <div class="col-1">
                            <strong>ID</strong>
                        </div>
                        <div class="col-6">
                            <strong>Appointment Type</strong>
                        </div>
                        <div class="col-3">
                            <strong>Appointment Date/Time</strong>
                        </div>
                        <div class="col-2">
                            <strong>Time Zone</strong>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="scroll-modal-content">
            <hr class="d-none d-lg-flex" />
            <div *ngIf="appointments && appointments?.length == 0" class="alert alert-success text-center">No appointment found!</div>
            <ng-container *ngFor="let appointment of appointments">
                <div class="row g-2">
                    <div class="col-lg-11">
                        <div class="row g-2"
                        [ngClass]="{'text-danger': appointment.status === CANCELLED, 'text-success': appointment.status === 'ACCEPTED'}">
                            <div class="col-lg-1">
                                <div class="d-flex">
                                    <div class="d-lg-none">
                                        <strong class="table__info">ID</strong>
                                    </div>
                                    <div class="text-truncate">
                                        {{appointment.id}}
                                    </div>
                                </div>
                            </div>
    
                            <div class="col-lg-6">
                                <div class="d-flex">
                                    <div class="d-lg-none">
                                        <strong class="table__info">Type</strong>
                                    </div>
                                    <div class="text-truncate">
                                        {{appointment.type}}/{{appointment.mode}} 
                                    </div>
                                </div>
                            </div>
                        
                            <div class="col-lg-3">
                                <div class="d-flex">
                                    <div class="d-lg-none">
                                        <strong class="table__info">Date/Time</strong>
                                    </div>
                                    <div class="text-truncate">
                                        <span>{{appointmentScheduledDate(appointment) }}, </span>
                                                {{appointmentScheduledTime(appointment)}}
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-2">
                                <div class="d-flex">
                                    <div class="d-lg-none">
                                        <strong class="table__info">Date/Time</strong>
                                    </div>
                                    <div class="text-truncate">
                                        {{appointment.scheduleTimeZone}}
                                    </div>
                                </div>
                            </div>
                            
                            <!--div class="col-lg-2">
                                <div class="d-flex">
                                    <div class="d-lg-none">
                                        <strong class="table__info">Appointment With</strong>
                                    </div>
                                    <div class="text-truncate">
                                        {{appointment.with}}`
                                    </div>
                                </div>
                            </div-->
                            
                        </div>
                    </div>
                    <div class="col-lg-1 text-lg-end">
                        <strong class="table__info d-none d-sm-inline-block d-lg-none"></strong>
                        <a class="btn btn-sm btn-outline-primary table__btn" [routerLink]="['/appointments',appointment.id]" (click)="genericModalService.hideModel()">
                            <i class="fas fa-bars"></i> <span class="d-lg-none ms-1">View Details</span>
                        </a>
                    </div>
                </div>
                <hr/>
            </ng-container>
        </div>
    </div>
</div>