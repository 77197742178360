<div class="row g-0" *ngIf="centralNurseSlot">
    <div class="col-4">
        <div class="care-member-slot"
            [class]="centralNurseSlot.slot1 ? 'bg-danger': 'bg-success'">
            <span>{{centralNurseSlot.slot1 ? 'Not Available': 'Available'}}</span>
            <div *ngIf="times.length > 0" class="is-time">{{times[0]}} - {{times[1]}}</div>
        </div>
    </div>
    <div class="col-4">
        <div class="care-member-slot is-active"
            [class]="centralNurseSlot.slot2 ? 'bg-danger': 'bg-success'">
            <span>{{centralNurseSlot.slot2 ? 'Not Available': 'Available'}}</span>
            <div *ngIf="times.length > 0" class="is-time">{{times[1]}} - {{times[2]}}</div>
        </div>
    </div>
    <div class="col-4">
        <div class="care-member-slot"
            [class]="centralNurseSlot.slot3 ? 'bg-danger': 'bg-success'">
            <span>{{centralNurseSlot.slot3 ? 'Not Available': 'Available'}}</span>
            <div *ngIf="times.length > 0" class="is-time">{{times[2]}} - {{times[3]}}</div>
        </div>
    </div>
</div>