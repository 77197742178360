/**
 * Somatus Hybrid Scheduler API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface AppointmentInfo { 
    id: number;
    patientId: number;
    enrollementNumber?: string;
    patientName?: string;
    _with?: string;
    address?: string;
    scheduleDateTime: Date;
    type?: string;
    mode?: string;
    typeId: number;
    modeId?: string;
    status?: string;
    createdBy?: number;
    createByName?: string;
    createdDate: Date;
    schedulePreTime: number;
    scheduleTimeZone?: string;
}