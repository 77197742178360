import { Component, OnInit } from '@angular/core';
import { GenericModal } from 'src/app/commons-ui/generic-modal/generic-modal';
import { GenericModalButtonInfo } from 'src/app/commons-ui/generic-modal/generic-modal-service';
import { CalendarEvent } from '../schedule-calendar/calendar-event';
import { AppointmentMode, AppointmentType, PatientModel, PatientService } from 'src/app/api-client';
import { ReferenceDataProvider } from 'src/app/commons/reference-data-provider';
import { DateTime } from 'src/app/commons/date-time';
import { Time } from '../schedule-calendar/time-field/time-field.component';
import { LoggingServiceTsService } from 'src/app/store/loggingService/logging.service';


@Component({
    selector: 'hs-appointment-popup',
    templateUrl: './appointment-popup.component.html',
    styleUrls: ['./appointment-popup.component.scss']
})

export class AppointmentPopupComponent implements OnInit, GenericModal<AppointmentPopupInput, AppointmentPopupOutput> {

    public readonly prePostTimes: number[] = [0, 15, 30, 45, 60, 75, 90, 105, 120];
    public readonly durationTimes: number[] = [30, 60, 90];

    private submitHandler?: (data: AppointmentPopupOutput) => void;

    public appointmentTypes: AppointmentType[] = [];
    public appointmentModes: AppointmentMode[] = [];

    public patient?: PatientModel;
    public patientPriority: string = "";
    
    public apptData: CalendarEvent = <CalendarEvent>{};
    public newSchedule: boolean = true;
    
    public defaultData: CalendarEvent = <CalendarEvent>{
        schedulePreTime: this.prePostTimes[0],
        schedulePostTime: this.prePostTimes[0],
        preferredLanguageCode: "en",
    };

    public startDate?: Date;
    public startTime?: Time;
    public duration?: number;
    
    constructor(private referenceDataProvider: ReferenceDataProvider, private patientService: PatientService, private loggingService: LoggingServiceTsService) { }

    ngOnInit(): void {
        this.loadAppointmentTypes();
    }

    private async loadPatientPriority(id: any): Promise<void> {
        try {
            let resultPP = await this.patientService.patientGetPriority(id).toPromise();
            if(resultPP !=  undefined){
                this.patientPriority = resultPP;
            }
            this.loggingService.trackEvent("Appointment PoP-Up ",this.loggingService.createCustomLog("Appointment PoP-Up ", "Values Rendered Successfully", "INFO"));
            
        } catch (error) {
            console.error(error);
        }
    }

    private async loadAppointmentTypes(): Promise<void> {
        await this.loadPatientPriority(this.patient?.id);
        this.appointmentTypes = await this.referenceDataProvider.getAppointmentTypes();
        this.apptData!.typeId = this.apptData!.typeId ?? this.appointmentTypes?.[0].typeId;

        this.updateAppointmentModes();
    }

    private async updateAppointmentModes(): Promise<void> {
        this.appointmentModes = this.appointmentTypes.find(mode => mode.typeId == this.apptData!.typeId)?.modes ?? [];
        this.apptData!.modeId = this.apptData!.modeId ?? this.appointmentModes?.[0]?.modeId!;
    }

    public getTitle(): string {
        return "Appointment";
    }

    public getSubTitle(): string {
        return "Book Appointment with " + this.patient?.name ?? "";
    }

    public setInputData(data: AppointmentPopupInput): void {
      
        this.patient = data.patient;
        
        Object.assign(this.apptData, data.event);
        this.apptData.timezoneOffset = data.timezoneOffset;

        this.apptData.modeId = this.apptData.modeId ?? this.defaultData.modeId;
        this.apptData.typeId = this.apptData.typeId ?? this.defaultData.typeId;
        this.apptData.preferredLanguageCode = this.apptData.preferredLanguageCode ?? this.defaultData.preferredLanguageCode;
        this.apptData.schedulePreTime = this.apptData.schedulePreTime ?? this.defaultData.schedulePreTime;
        this.apptData.schedulePostTime = this.apptData.schedulePostTime ?? this.defaultData.schedulePostTime;
        this.apptData.scheduleTimeZoneId = this.apptData.scheduleTimeZoneId ?? this.defaultData.scheduleTimeZoneId;

       
        this.newSchedule = data.newSchedule;
        if(data.newSchedule)/** New Appointment */
        {
            this.startDate = new Date(data.event?.start?.toString()!);
            this.startTime = {hours: this.startDate.getHours(), minutes: this.startDate.getMinutes()};
            this.duration = data.event?.end ? (new Date(data.event?.end?.toString()!).getTime() - this.startDate.getTime()) / DateTime.millisInMinute : 60;

            this.apptData.schedulePreTime = this.prePostTimes[1];
            this.apptData.schedulePostTime = this.prePostTimes[1];
        }else{/** Update Appointment */

            let startTimeMillsec = new Date(data.event?.start?.toString()!).getTime() - data.timezoneOffset + (this.apptData.schedulePreTime ?? 0) * DateTime.millisInMinute;

            this.startDate = this.roundTimeQuarterHour(startTimeMillsec);
            this.startTime = {hours: this.startDate.getHours(), minutes: this.startDate.getMinutes()};

            let endDate: Date | null = data.event?.end ? DateTime.from(data.event?.end as Date).addMinutes(-(data.event.schedulePostTime ?? 0)).getDate() : null;
            let endTimeMillsec = new Date(endDate?.toString()!).getTime() - data.timezoneOffset;
            this.duration = endDate ? (endTimeMillsec -  startTimeMillsec) / DateTime.millisInMinute : 60;
        }
    }

    getLeftButtons(): GenericModalButtonInfo[] {
        if(this.newSchedule) {
            return [];
        }
        return [
            {
                label: "Delete",
                cssClasses: "btn btn-secondary rounded-pill px-4",
                action: async () => this.deleteAppointment(),
            },
        ];
    }

    getRightButtons(): GenericModalButtonInfo[] {
        return [
            {
                label: this.newSchedule? "Add Schedule": 'Update Schedule',
                cssClasses: "btn btn-dark rounded-pill px-4",
                action: async () => await this.createAppointment(),
            },
        ];
    }

    

    private async createAppointment(): Promise<boolean> {
        
        if(this.apptData.modeId == "phone" || this.apptData.modeId == "telehealth") {
            this.apptData.schedulePreTime = 0;
            this.apptData.schedulePostTime = 0;
        }
       
        /** Offset data as per the required timezone */
        var tempDate = this.startDate;
        tempDate?.setTime(tempDate.getTime() + this.apptData.timezoneOffset!)
        this.apptData.start = DateTime.from(tempDate!).addMinutes(-this.apptData.schedulePreTime!).getDate();;
        this.apptData.end = DateTime.from(tempDate!).addMinutes(this.duration! + this.apptData.schedulePostTime!).getDate();
        this.submitHandler?.({event: this.apptData, delete: false});
        this.loggingService.trackEvent('User Clicked On Add Schedule', this.loggingService.createCustomLog('Clicked on Add Schedule','Sucessfully Created Appointement On Grid','INFO'));
        return true;
    }

    private async deleteAppointment(): Promise<boolean> {
        //** Call scheduler-calendar-appointment > createAppointment */
        this.submitHandler?.({event: this.apptData, delete: true});
        return true;
    }

    public set time(data: Time) {
       
        this.startDate?.setHours(data.hours);
        this.startDate?.setMinutes(data.minutes);
        this.startTime = data;
    }
    public renderLocation(): string {
        const type = this.patient?.addressType ? `${this.patient.addressType}: `: '';
        const address = this.patient?.address ? this.patient.address: '';
        return type + address;
    }

    public get time() {
        return this.startTime!;
    }

    public async onCloseClicked(): Promise<boolean> {
        return true;
    }

    public setOutputDataHandler(handler: (data: AppointmentPopupOutput) => void): void {
        this.submitHandler = handler;
    }

    public roundTimeQuarterHour(timeinMS: number):Date {
        var timeToReturn = new Date(timeinMS);
    
        timeToReturn.setMilliseconds(Math.round(timeToReturn.getMilliseconds() / 1000) * 1000);
        timeToReturn.setSeconds(Math.round(timeToReturn.getSeconds() / 60) * 60);
        timeToReturn.setMinutes(Math.round(timeToReturn.getMinutes() / 15) * 15);
        return timeToReturn;
    }

    public async typeChanged(): Promise<void> {
        await this.updateAppointmentModes();
        this.apptData!.modeId = this.appointmentModes?.[0].modeId!;
        this.modeChanged();
    }

    public modeChanged(): void {
        let mode: AppointmentMode | undefined = this.appointmentModes.find(m => m.modeId == this.apptData.modeId);
        if(!this.apptData.schedulePreTime || this.apptData.schedulePreTime == 0) {
            this.apptData.schedulePreTime = mode?.preAppointmentDuration;
        }
        if(!this.apptData.schedulePostTime || this.apptData.schedulePostTime == 0) {
            this.apptData.schedulePostTime = mode?.postAppointmentDuration;
        }
        this.duration = mode?.defaultAppointmentDuration;
    }
}

export interface AppointmentPopupInput {
    event: CalendarEvent;
    patient: PatientModel;
    timezoneOffset: number;
    newSchedule: boolean;
}

export interface AppointmentPopupOutput {
    event: CalendarEvent;
    delete: boolean;
}
