import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthService } from './auth-service';
import { AuthRoutingDeclarations, AuthRoutingModule } from './auth-routing.module';



@NgModule({
  declarations: [
    AuthRoutingDeclarations
  ],
  imports: [
    CommonModule,
    AuthRoutingModule
  ],
  providers: [
    AuthService
  ]
})
export class AuthModule { }
