<div class="modal-content">
    <div class="modal-header">
        <div>
            <h5 class="modal-title search-nurse" id="Contact Modal">Search Best Time to Schedule</h5>
        </div>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
    </div>
    <div class="px-3 py-2">
        <form [formGroup]="searchForm" (ngSubmit)="fetchScheduleSearchList()">
            <div class="row g-2">
                <div class="col-12 col-lg-12">
                    <div class="row g-2">
                        <div class="col-6 col-lg-3 appointment-start">
                            <label for="careTeamMember" class="form-label fw-bold">Care Team Member</label>
                            <select id="careTeamMember" formControlName="careMemberId" class="form-select">
                                <option *ngFor="let careMember of careTeamMembers" [ngValue]="careMember.id">{{careMember.name}}</option>
                            </select>
                        </div>
                        <div class="col-6 col-lg-3 appointment-start">
                            <label for="appointmentStart" class="form-label fw-bold text-nowrap">Start Date</label>
                            <div class="date-range"><mbsc-datepicker formControlName="selectedDate"></mbsc-datepicker></div>
                        </div>
                        <div class="col-6 col-lg-6">
                            <label for="duration" class="form-label fw-bold">Duration</label>
                            <div class="input-group">
                                <select id="duration" formControlName="duration" class="form-select">
                                    <option *ngFor="let durationTime of durationTimes" [ngValue]="durationTime">{{durationTime}} Mins</option>
                                </select>
                                <button type="submit" class="btn btn-sm btn-primary input-group-text ms-2 rounded-3">
                                    <i class="fas fa-search me-1"></i> Search Availability
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <hr class="hr-light m-0" />
    <div class="modal-body" id="autoBestTimeScheduleModalBody">
        <div class="results-panel">
            <table class="table" *ngIf="scheduleList.length > 0">
                <thead>
                    <tr>
                        <th scope="col">Name</th>
                        <th scope="col">Email Address</th>
                        <th scope="col">Date</th>
                        <th scope="col">From Time</th>
                        <th scope="col">To Time</th>
                    </tr>
                </thead>
                <tbody *ngFor="let item of scheduleList">
                    <tr (click)="submitAutoSearchSchedules(item)" data-bs-dismiss="modal">
                        <th scope="row">{{item.name}}</th>
                        <td>{{item.emailId}}</td>
                        <td>{{item.startDateTime | usDate}}</td>
                        <td>{{item.startDateTime | time}}</td>
                        <td>{{item.endDateTime | time}}</td>
                    </tr>
                </tbody>
            </table>
            <div class="results-panel" *ngIf="scheduleList.length <= 0 && !isNextButtonDisabled">
                <div class="alert alert-danger text-center py-4 border border-danger rounded-3">
                    <div class="mb-2">
                        <i class="fas fa-exclamation-triangle text-danger me-1 fs-24"></i>
                    </div>
                    <p>
                        Data not available
                        <!--<span *ngIf="slotAvailabilityErrorMessage.daySlotId === 'M'"> in the <b>Morning</b></span>
                        <span *ngIf="slotAvailabilityErrorMessage.daySlotId === 'A'">in the <b>Afternoon</b></span>
                        <span *ngIf="slotAvailabilityErrorMessage.daySlotId === 'E'">in the <b>Evening</b></span>
                        <span *ngIf="slotAvailabilityErrorMessage.daySlotId === 'S'">for <b>Saturday</b></span>
                        <span *ngIf="slotAvailabilityErrorMessage.daySlotId === 'D'">Any Time</span>
                        from {{slotAvailabilityErrorMessage.searchStartDate | date:'MM/dd/yyyy'}} to
                        {{slotAvailabilityErrorMessage.searchEndDate | date:'MM/dd/yyyy'}}-->
                    </p>
                    Click the <b>Next</b> button to search for next available day(s)
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer justify-content-between w-100">
        <div class="d-flex text-start">
            <button type="button" class="btn btn-sm btn-dark rounded-pill px-4" (click)="ResetFetchScheduleList($event)">
                Reset Date
            </button>
        </div>
        <div class="d-flex text-end">
            <button type="button" class="btn btn-sm btn-dark rounded-pill px-4" style="margin-right: 10px;" 
                    [disabled]=isPreviousButtonDisabled
                    (click)="previousFetchScheduleList($event)">
                Previous
            </button>
            <button type="button" class="btn btn-sm btn-dark rounded-pill px-4" [disabled]=isNextButtonDisabled
                    (click)="afterFetchScheduleList($event)">
                Next
            </button>
        </div>
    </div>
</div>
