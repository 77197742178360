
declare const $: any;

export class Loader {
    public static showLoader(elementId: string, options: LoaderOptions = {}) {
        let effect: string = "bounce";
        switch(options.effect) {
            case LoaderEffect.bounce:
                effect = "bounce";
                break;
            case LoaderEffect.rotateplane:
                effect = "rotateplane";
                break;
            case LoaderEffect.stretch:
                effect = "stretch";
                break;
            case LoaderEffect.orbit:
                effect = "orbit";
                break;
            case LoaderEffect.roundBounce:
                effect = "roundBounce";
                break;
            case LoaderEffect.win8:
                effect = "win8";
                break;
            case LoaderEffect.win8_linear:
                effect = "win8_linear";
                break;
            case LoaderEffect.ios:
                effect = "ios";
                break;
            case LoaderEffect.facebook:
                effect = "facebook";
                break;
            case LoaderEffect.rotation:
                effect = "rotation";
                break;
            case LoaderEffect.timer:
                effect = "timer";
                break;
            case LoaderEffect.pulse:
                effect = "pulse";
                break;
            case LoaderEffect.progressBar:
                effect = "progressBar";
                break;
            case LoaderEffect.bouncePulse:
                effect = "bouncePulse";
                break;
            case LoaderEffect.img:
                effect = "img";
                break;
        }
        $("#" + elementId).waitMe({
            "effect": effect,
            "text ": options.text,
            "color": "rgb(51,113,242)",
            "bg": "white"
        });
    }

    public static hideLoader(elementId: string) {
        $("#" + elementId).waitMe("hide");
    }
}

export class LoaderOptions {
    effect?: LoaderEffect = LoaderEffect.bounce;
    text?: string;
}

export enum LoaderEffect {
    bounce,
    rotateplane,
    stretch,
    orbit,
    roundBounce,
    win8,
    win8_linear,
    ios,
    facebook,
    rotation,
    timer,
    pulse,
    progressBar,
    bouncePulse,
    img,
}
