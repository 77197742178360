<p>recent-appointments works!</p>

<ul>
    <li><a [routerLink]="['/', 'appointments', '1']">Appointment 1</a></li>
    <li><a [routerLink]="['/', 'appointments', '2']">Appointment 2</a></li>
    <li><a [routerLink]="['/', 'appointments', '3']">Appointment 3</a></li>
</ul>

<br>
<br>

<a [routerLink]="['/', 'appointments', 'history']">See All >></a>
