<div id="appointment-details">
<div class="bg-offwhite p-3 pt-2">
    <div class="d-md-flex">
        <div class="flex-fill mt-1 mb-2">
            <ol class="breadcrumb text-uppercase fs-14 ">
                <li class="breadcrumb-item"><a href="#"><i class='fas fa-home'></i></a></li>
                <li class="breadcrumb-item"><a [routerLink]="'/appointments'">Appointments</a></li>
                <li class="breadcrumb-item active text-dark"><b>Appointment Detail</b></li>
            </ol>
        </div>
        <div class="d-none d-md-flex align-items-start">
            <!--<h6 class="p-2 bg-secondary d-flex justify-content-between text-danger"
            *ngIf="showRecordingNotAvailableMsg">Recorded link is not availalble.</h6>-->
            <!-- <a class="btn btn-sm btn-dark rounded-pill px-4"
            *ngIf="isRecordingAvailable" data-bs-toggle="modal" data-bs-target="#viewRecordingModal">View Recording</a> -->
            <a *ngIf="appointmentDetail.status !==  commonConstant.APPOINTMENT_CANCEL && isShowResendInviteBtn " class="btn btn-sm btn-dark rounded-pill px-4" data-bs-toggle="modal" data-bs-target="#resendinvite-modal"><i class="fas fa-redo me-1"></i> Resend Invite</a>
            <a class="btn btn-sm btn-dark rounded-pill px-4 ms-2"
               *ngIf="isRecordingAvailable" (click)="playVideo()">View Recording</a>
            <a *ngIf="appointmentDetail.status !== commonConstant.APPOINTMENT_CANCEL && appointmentDetail?.oneClickMeetingLink && showVideoCallButton
               && isTeleHealthModeScheduleExist"
               class="btn btn-sm btn-dark rounded-pill px-4 ms-2"
               [href]="appointmentDetail.oneClickMeetingLink" target="_blank">
                <i class="fas fa-video me-1"></i> Start Video Call
            </a>
            <a *ngIf="appointmentDetail.status !== commonConstant.APPOINTMENT_CANCEL && isShowEditButton" (click)="EditAppointment()"
               class="btn btn-sm btn-outline-dark rounded-pill px-4 ms-2" 
               [routerLink]="['/schedule',appointmentDetail.patient?.id,appointmentDetail.id]">Edit</a>
            <a *ngIf="appointmentDetail.status !==  commonConstant.APPOINTMENT_CANCEL " (click)="CancelAppointment()" class="btn btn-sm btn-outline-danger  rounded-pill px-4 ms-2"  data-bs-toggle="modal" data-bs-target="#cancel-modal">Cancel</a>



            <div *ngIf="appointmentDetail.status ===  commonConstant.APPOINTMENT_CANCEL " class="badge-danger d-inline-block border border-danger text-danger px-3 rounded-pill fw-normal align-top ms-2 text-center">Appointment Canceled </div>


        </div>
    </div>
    <div class="bg-white p-3 col-height" id="appointment-details">
        <div class="d-lg-flex">
            <div class="d-flex">
                <div class="user mt-1">{{appointmentDetail.patient?.name | initial}}</div>
                <div class="box mt-1">
                    <div><strong class="d-inline-block mw-35">{{appointmentDetail.patient?.name}}</strong></div>
                    <div class="small">
                        <strong class="d-inline-block mw-35 ">DOB : {{appointmentDetail.patient?.dateofBirth | date:'MM/dd/yyyy'}}</strong> (Age: {{appointmentDetail.patient?.age}} Year(s)) / {{appointmentDetail.patient?.gender === 'F' ? 'Female': 'Male'}}
                    </div>

                </div>
            </div>
            <div class="d-md-flex flex-fill">
                <hr class="mt-3 mb-1 d-lg-none" />
                <div class="d-lg-flex flex-fill ms-md-5 ps-md-4 ms-lg-0 ps-lg-0">
                    <div id="appointment-info" class="d-inline-block d-lg-block">
                        <div class="d-inline-block border border-light px-2 py-1 me-1 rounded bg-offwhite fs-12 my-2">{{appointmentDetail.typeId | apptType}}</div>
                    </div>
                    <div id="appointment-info" class="d-inline-block d-lg-block">
                        <div class="d-inline-block border border-light px-2 py-1 me-1 rounded bg-offwhite fs-12 my-2">{{appointmentDetail.modeId | apptMode}}</div>
                    </div>
                    <div id="appointment-info" class="d-inline-block d-lg-block">
                        <div class="d-inline-block border border-light px-2 py-1 me-1 rounded bg-offwhite fs-12 my-2">{{patientPriority}}</div>
                    </div>
                </div>
                <div class="justify-content-end" class="d-inline-block d-lg-block">
                    <div class="mt-1">
                        <div class="d-flex">
                            <button (click)="draftappointment()"
                            type="button"
                            class="btn btn-dark rounded-pill px-4 contact-number contact-number__hover text-nowrap"
                            data-bs-dismiss="modal"
                            style="margin-right: 20px;"
                            [disabled]="isActive">      
                            Test Telehealth   
                        </button>
                            <div class="contact-number">
                                <i class="fas fa-phone-alt contact-number__icon"></i>
                                {{ appointmentDetail.mobileNo}}
                                <span class="contact-number__info">Phone Number</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <hr class="mt-3 mb-0" />
        <div class="row">
            <div class="col-12 col-lg-6 pt-2 order-lg-2 border-start-md border-end-md">
                <div class="p-lg-2">
                    <h5 class="p-2 bg-secondary d-flex justify-content-between"
                        *ngIf="appointmentDetail.preAppointmentTasks && appointmentDetail.preAppointmentTasks.length > 0">
                        <div>Scheduling Tasks associated with this appointment</div>
                    </h5>
                    <div id="pre-appointment-task" [ngClass]="{'mb-3':appointmentDetail.preAppointmentTasks && appointmentDetail.preAppointmentTasks.length > 0}">
                        <div *ngFor="let taskItem of appointmentDetail.preAppointmentTasks; let itemIndex = index">
                            <div class="d-flex p-2">
                                <div class="flex-fill">
                                    {{taskItem.description}} - <strong class="me-2">{{taskItem.careMember ?? 'Not assigned'}}</strong>
                                    <hs-task-status [status]="taskItem.statusId"></hs-task-status>
                                </div>
                                <div class="text-end">
                                    <div class="text-end">
                                        <div class="fs-12">
                                            {{taskItem.dueDate  | date:'MM/dd/yyyy'}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr *ngIf="notLastElementOfArray(appointmentDetail.preAppointmentTasks, itemIndex)" class="m-0" />
                        </div>
                    </div>

                    <h5 class="p-2 bg-secondary"
                        *ngIf="appointmentDetail.appointmentScheduleDetails && appointmentDetail.appointmentScheduleDetails.length > 0">
                        <div id="appointment-info">
                            <div class="ms-3 d-inline-block border px-3 rounded-pill fw-normal align-top ms-2 text-center badge badge-primary text-primary pull-right" style="float:right">{{appointmentDetail.timezone}}</div>
                        </div>
                        Care Team Member Schedule(s)
                    </h5>
                    <div [ngClass]="{'mb-3':appointmentDetail.appointmentScheduleDetails && appointmentDetail.appointmentScheduleDetails.length > 0}">
                        <div *ngFor="let careTeam of appointmentDetail.appointmentScheduleDetails; let itemIndex = index">
                            <div class="d-sm-flex p-2">
                                <div class="flex-fill">
                                    <strong>{{careTeam.modeId | apptMode}}</strong> - <strong class="text-capitalize text-nowrap">{{careTeam.name}}</strong> - <span class="text-nowrap">{{careTeam.type}}</span>
                                </div>
                                <div class="text-sm-end">
                                    <div class="d-flex flex-wrap justify-content-end align-items-center fs-12">
                                        <div class="flex-fill">
                                            <div>
                                                <span class="text-lightest">{{careTeamMemberScheduledDate(careTeam) }}, </span>
                                                {{careTeamMemberScheduledTime(careTeam)}}
                                            </div>
                                            <div *ngIf="careTeam.modeId==='in-home'  || careTeam.modeId==='in-clinic'"  class="text-lightest">Drive Time: Pre-{{careTeam.schedulePreTime}} Mins / Post-{{careTeam.schedulePostTime}} Mins</div>
                                        </div>
                                        <div>
                                            <div [ngClass]="getcareTeamStatusClassName(careTeam.status)"
                                                 class="d-inline-block border px-3 rounded fw-normal align-top text-center ms-2 fs-12">
                                                {{careTeam.status}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr *ngIf="notLastElementOfArray(appointmentDetail.appointmentScheduleDetails, itemIndex)" class="m-0" />
                        </div>
                    </div>

                    <h5 class="p-2 bg-secondary d-flex justify-content-between"
                        *ngIf="appointmentDetail.postAppointmentTasks && appointmentDetail.postAppointmentTasks.length > 0">
                        <div>Care Team Task(s) for this Appointment</div>
                    </h5>
                    <div [ngClass]="{'mb-3':appointmentDetail.postAppointmentTasks && appointmentDetail.postAppointmentTasks.length > 0}">
                        <div *ngFor="let taskItem of appointmentDetail.postAppointmentTasks; let itemIndex = index">
                            <div class="d-flex p-2">
                                <div class="flex-fill">
                                    {{taskItem.description}} - <strong class="me-2 text-nowrap">{{taskItem.careMember ?? 'Not assigned'}}</strong>
                                    <hs-task-status [status]="taskItem.statusId"></hs-task-status>
                                </div>
                                <div class="text-end">
                                    <div class="text-end">
                                        <div class="fs-12 ms-2">
                                            {{taskItem.dueDate | date:'MM/dd/yyyy'}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr *ngIf="notLastElementOfArray(appointmentDetail.postAppointmentTasks, itemIndex)" class="m-0" />
                        </div>
                    </div>
                    <hr class="tabs d-none d-md-block" />
                    <ul class="tabs d-none d-md-block">
                        <li class="tabs__list" role="presentation">
                            <div class="tabs__item active">Appointment History</div>
                        </li>
                    </ul>
                    <div id="appointment-history" class="d-none d-md-block">
                        <div *ngFor="let appHistory of appointmentHistory">
                            <div>{{appHistory.createDate | usDateTime}}: {{appHistory.description}} - {{appHistory.createdBy}}</div>
                        </div>
                    </div>
                </div>
                <hr class="mt-3 mb-0 d-lg-none" />
            </div>
            <div class="col-12 col-md-6 col-lg-3 order-lg-1 map-div">
                <h3 class="h5 my-3">Patient Location</h3>
                <hr />
                <div class="fs-14 pb-2">
                    <div class="text-nowrap"><i class="fas fa-map-marker-alt text-primary me-1 fs-16"></i> <strong class="d-inline-block">{{appointmentDetail.addressType}}</strong></div>
                    <div>
                        {{appointmentDetail.address}}
                    </div>
                </div>
                <div class="alert alert-danger d-flex align-items-center " role="alert" *ngIf="address===''">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-exclamation-triangle-fill flex-shrink-0 me-2" viewBox="0 0 16 16" role="img" aria-label="Warning:">
                        <use xlink:href="#exclamation-triangle-fill"></use>
                    </svg>
                    <div>
                        Location not found.
                    </div>
                </div>
                <hs-navigation-map [address]="address" *ngIf="address!==''"></hs-navigation-map>
            </div>
            <div class="col-12 col-md-6 col-lg-3 order-lg-3">
                <hs-notification-by-email  [appointmentDetail] = "appointmentDetail" [appointmentStatusValue]="appointmentDetail.status ? appointmentDetail.status: ''" [notification]="{mobileNo: this.appointmentDetail.notificationMobileNo, emailId: this.appointmentDetail.notificationEmailId}"></hs-notification-by-email>
            </div>

        </div>
    </div>
</div>

<div class="d-md-none btn-float">
    <div class="btn-float__content">


        <a *ngIf="appointmentDetail.status !== commonConstant.APPOINTMENT_CANCEL && appointmentDetail?.oneClickMeetingLink && showVideoCallButton
           && isTeleHealthModeScheduleExist"
           class="btn btn-sm btn-primary rounded-pill px-2 px-sm-4 ms-2"
           [href]="appointmentDetail.oneClickMeetingLink" target="_blank">
            <i class="fas fa-video me-1"></i> Start Call
        </a>
        <a *ngIf="appointmentDetail.status !==  commonConstant.APPOINTMENT_CANCEL && isShowEditButton"
           class="btn btn-sm btn-primary rounded-pill px-3 px-sm-4 ms-2"
           [routerLink]="['/schedule',appointmentDetail.patient?.id,appointmentDetail.id]"><i class="fas fa-edit"></i></a>
        <a *ngIf="appointmentDetail.status !== commonConstant.APPOINTMENT_CANCEL " class="btn btn-sm btn-danger  rounded-pill px-3 px-sm-4 ms-2 text-white" data-bs-toggle="modal" data-bs-target="#cancel-modal"><i class="fas fa-times"></i></a>
        <div *ngIf="appointmentDetail.status === commonConstant.APPOINTMENT_CANCEL" class="badge-danger d-inline-block border border-danger text-danger px-3 rounded-pill fw-normal align-top ms-2 text-center">Appointment Canceled </div>
    </div>
</div>

<!-- TaskModal Modal -->
<div class="modal fade" id="cancel-modal" tabindex="-1" aria-labelledby="ariaCancelModal" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-md">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="ariaCancelModal">Cancel Appointment</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" (click)="closeCancelAppoinment();" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="d-flex fst-italic text-lightest mt-3">
                    <div class="me-1"><i class="fas fa-hand-point-right"></i></div>
                    <div>
                        Cancelling this appointment will result in:
                        <ul>
                            <li>Associated RC Tasks being closed</li>
                            <li>Associated calendar invites for care team being cancelled</li>
                            <li>Patient receives a text message of cancellation</li>
                        </ul>
                    </div>
                </div>

                <div class="d-flex mb-3">

                    <div class="flex-fill fs-15">
                        Please confirm to proceed!
                    </div>
                </div>
                <div class="mb-3">
                    <label for="taskStatus" class="form-label fw-bold">Update Status of Appointment Task</label>
                    <select id="taskStatus" class="form-select" [(ngModel)]="statusId" (change)="changeAppointmentTaskStatus($event);">
                        <option value="249">Closed - Unsuccessful</option>
                        <option value="4367">No Longer Applicable</option>

                    </select>
                </div>
                <div class="mb-3" *ngIf="this.isCloseUnsuccessfull == true">
                    <label for="cancelReason" class="form-label fw-bold">Cancellation Reason</label><span style="color:red">*</span>
                    <select id="cancelReason" class="form-select" [(ngModel)]="cancelReason">
                        <option value="">--Select Cancellation Reason--</option>
                        <option *ngFor="let reason of cancelReasonList" [ngValue]="reason.id">{{reason.name}}</option>
                    </select>
                </div>
                <div class="alert alert-warning" *ngIf="isNoLongerApplicable==true">
                    <p>
                        Are you sure this SLA related Task should be closed as NLA? Please leave a comment as to why this task is no longer valid.
                    </p>
                </div>
                <div>
                    <label for="CommentTextarea" class="form-label fw-bold">Comment </label><span style="color:red">*</span>
                    <textarea class="form-control"
                              id="CommentTextarea" rows="3"
                              placeholder="Enter your comment here"
                              [(ngModel)]="reason"></textarea>
                </div>
            </div>
            <div class="modal-footer d-block text-center" id="cancel-modal-footer">
                <button type="button" class="btn btn-outline-dark rounded-pill px-4 my-0" data-bs-dismiss="modal" (click)="closeCancelAppoinment();">Cancel</button>
                <button type="button" class="btn btn-dark rounded-pill px-4 my-0"
                        (click)="confirmCancelAppointment()"
                        [disabled]="!reason || reason === '' || (isCloseUnsuccessfull && (!cancelReason || cancelReason === '')) ">
                    Confirm
                </button>
            </div>
        </div>
    </div>
</div>

<!-- NotificationModal Modal -->
<div class="modal fade" id="notificationModal" tabindex="-1" aria-labelledby="ariaNotificationModal" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="ariaNotificationModal">Update Email/Phone</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="mb-3">
                    <label for="emailAddress" class="form-label fw-bold">Email address</label>
                    <input type="email" id="emailAddress" placeholder="Type Email address" class="form-control" value="hemantk@esolutionsfirst.com" />
                </div>
                <div>
                    <label for="phoneNumber" class="form-label fw-bold">Phone Number</label>
                    <input type="email" id="phoneNumber" placeholder="Type Phone Number" class="form-control" value="+1(811) 9118-811" />
                </div>
            </div>
            <div class="modal-footer text-end">
                <button type="button" class="btn btn-dark rounded-pill px-4 my-0" data-bs-dismiss="modal">Update</button>
            </div>
        </div>
    </div>
</div>
<!-- NotificationModal Modal -->
<div class="modal fade" id="viewRecordingModal" tabindex="-1" aria-labelledby="ariaViewRecordingModal" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="ariaViewRecordingModal">View Recording</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <video width="100%" controls>
                    <source src="https://dl.infragistics.com/pg/2011-1/web/shared/videoplayer/videos/QuinceIntro_Part3_1.h264.mp4" type="video/mp4">
                    Your browser does not support HTML video.
                </video>
            </div>
            <div class="modal-footer text-end">
                <button type="button" class="btn btn-dark rounded-pill px-4 my-0" data-bs-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>


<!--Resend invite Modal -->

<div class="modal fade" id="resendinvite-modal" tabindex="-1" aria-labelledby="ariaCancelModal" aria-hidden="true">
    <div class="modal-dialog modal-md modal-dialog-centered">
        <div class="modal-content">

            <form autocomplete="off" #resendInvite="ngForm" name="resendInvite">

                <div class="modal-header d-block">
                    <div class="d-flex">
                        <h5 class="modal-title" id="ariaCancelModal">Resend Invite</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="resetResendInvite();"></button>
                    </div>
                    <div>Resend appointment invite to the patient or care member</div>
                </div>
                <div class="modal-body">
                    <div class="fw-bold">Select to whom you want to resend appointment information</div>
                    <div class="mt-2">
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="checkbox" name="isPatient" #isPatient="ngModel" [(ngModel)]="resendInviteModel.isPatient" (change)="isCheckPatient($event)" id="inlineCheckbox1" value="option1">
                            <label class="form-check-label" for="inlineCheckbox1">Patient </label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="checkbox" id="inlineCheckbox2" name="isCareMember" #isCareMember="ngModel" [(ngModel)]="resendInviteModel.isCareMember" (change)="isCheckCareMember($event)" value="option2">
                            <label class="form-check-label" for="inlineCheckbox2">Care Member </label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="checkbox" id="inlineCheckbox3" name="isAdditionalUser" #isAdditionalUser="ngModel" [(ngModel)]="resendInviteModel.isAdditionalUser" (change)="isCheckAdditionalUser($event)" value="option3">
                            <label class="form-check-label" for="inlineCheckbox3">Additional User</label>
                        </div>
                    </div>
                    <div class="border border-light rounded-3 p-2 mt-4" *ngIf="resendInviteModel.isPatient">
                        <h6 class="p-2 bg-secondary">
                            Resend appointment information to Patient
                        </h6>
                        <div class="row  mx-1">
                            <div class="col-12 col-sm-6">
                                <div class="fw-bold">Patient Email</div>
                                <div class="text-truncate">{{appointmentDetail.emailId}}</div>
                            </div>
                            <div class="col-12 col-sm-6 pt-3 pt-sm-0">
                                <div class="fw-bold">Patient Mobile No</div>
                                <div class="text-truncate">{{ appointmentDetail.mobileNo}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="border border-light rounded-3 p-2 mt-2" *ngIf="resendInviteModel.isCareMember">
                        <h6 class="p-2 bg-secondary">
                            Resend calendar invite to Care Member
                        </h6>
                        <div class="my-3  mx-3" *ngFor="let careTeam of appointmentDetail.appointmentScheduleDetails; let itemIndex = index">
                            <strong class="text-dark">{{careTeam.modeId | apptMode}} - {{careTeam.name}}</strong> -
                            {{careTeam.type}}
                        </div>
                    </div>
                    <div class="border border-light rounded-3 p-2 mt-2" *ngIf="resendInviteModel.isAdditionalUser">
                        <h6 class="p-2 bg-secondary">
                            Resend appointment information to other user
                        </h6>
                        <div class="row mx-1">
                            <div class="col-12 col-sm-6">
                                <div class="fw-bold">Email Address</div>
                                <div class="my-2">
                                    <input class="form-control" id="email" name="email" #email="ngModel" [(ngModel)]="resendInviteModel.email"  (focus)="CheckError()" placeholder="useremail@xxx.com" type="email"
                                           [class.border-danger]="(email.touched && !email.valid) || showErrorEmail"
                                           pattern='^(([^<>()[\]\\.,;:\s@" ]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$'>

                                    <span class="text-danger" *ngIf="resendInvite.controls['email'].invalid">Invalid email address</span>
                                </div>

                            </div>
                            <div class="col-12 col-sm-6">
                                <div class="fw-bold">Phone Number</div>
                                <div class="my-2">
                                    <input class="form-control" name="phone" #phone="ngModel"
                                           [class.border-danger]="(phone.touched && !phone.valid) || showErrorPhone"
                                           [(ngModel)]="resendInviteModel.mobileNumber" 
                                           (focus)="CheckError()" placeholder="(XXX) XXX-XXXX" type="text"
                                           mask="(XXX) XXX-XXXX" id="phone">
                                    <span class="text-danger" *ngIf="resendInvite.controls['phone'].invalid">Invalid phone number</span>
                                </div>
                            </div>
                        </div>
                        <div class="text-danger" *ngIf="showError">Email/phone entered matches patient email/phone, which will result in duplication of communication to patient. Please enter a different email/phone</div>
                    </div>
                </div>
                <div class="modal-footer justify-content-between w-100" id="cancel-modal-footer">
                    <button type="button" class="btn btn-outline-dark rounded-pill px-4 my-0" data-bs-dismiss="modal" (click)="resetResendInvite();">Cancel</button>
                    <button type="button" class="btn btn-dark rounded-pill px-4 my-0"
                            [disabled]="!isDisabledResendInviteBtn || resendInvite.invalid"
                            (click)="confirmResndAppointment(resendInvite)">
                        Send Invite
                    </button>
                </div>

            </form>
        </div>
    </div>
</div>
</div>