import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReferenceDataProvider } from './reference-data-provider';
import { InitialPipe } from './pipes/initial.pipe';
import { PhonePipe } from './pipes/phone.pipe';
import { TitlePipe } from './pipes/title.pipe';
import { USDatePipe } from './pipes/us-date.pipe';
import { USDateTimePipe } from './pipes/us-datetime.pipe';
import { ApptModePipe } from './pipes/appt-mode.pipe';
import { TimePipe } from './pipes/time.pipe';
import { DateRangeFilter } from './pipes/data-filter-by-date-range.pipe';
import { ApptTypePipe } from './pipes/appt-type.pipe';
import { CommonDateTimeFormatter } from './pipes/common-date-time-formatter';
import { SafeUrlPipe } from './pipes/safe.pipe';



@NgModule({
  declarations: [
    InitialPipe,
    PhonePipe,
    TitlePipe,
    USDatePipe,
    USDateTimePipe,
    ApptModePipe,
    ApptTypePipe,
    TimePipe,
    DateRangeFilter,
    SafeUrlPipe,
  ],
  imports: [
    CommonModule
  ],
  providers: [
    CommonDateTimeFormatter,
    ReferenceDataProvider,
    ApptModePipe,
  ],
  exports: [
    InitialPipe,
    PhonePipe,
    TitlePipe,
    USDatePipe,
    USDateTimePipe,
    ApptModePipe,
    ApptTypePipe,
    TimePipe,
    DateRangeFilter,
    SafeUrlPipe,
  ]
})
export class CommonsModule { }
