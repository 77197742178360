import { Component, Input, OnInit } from '@angular/core';
import { TaskStatus, taskStatusList } from '../../schedule/task-list/task-list.component';

@Component({
  selector: 'hs-task-status',
  templateUrl: './task-status.component.html',
  styleUrls: ['./task-status.component.scss']
})
export class TaskStatusComponent implements OnInit {

  public label?: string;
  public color?: string;

  constructor() { }

  ngOnInit(): void {
  }

  @Input()
  public set status(value: number) {

    this.label = value?.toString();
    this.color = 'warning';
    for (let index = 0; index < taskStatusList.length; index++) {
      const taskStatus: TaskStatus = taskStatusList[index];

      if(taskStatus.key == value) {
        this.label = taskStatus.value;
        if(value == 104) {
          this.color = 'primary';
        } else if(value == 4367) {
          this.color = 'warning';
        } else if(value == 105) {
          this.color = 'success';
        } else if(value == 249) {
          this.color = 'danger';
        } else if (value == -140) {
            this.color = 'danger';
        }
      }
    }
  }
}
