<div class="modal fade popup-modal" id="global-generic-modal" tabindex="-1" aria-labelledby="signModalaria" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <div>
                    <h5 class="modal-title fw-bold">{{title}}</h5>
                    <div>{{subTitle}}</div>
                </div>
                <button type="button" class="btn-close" aria-label="Close" (click)="closeClicked()"></button>
            </div>
            <ng-template componentHost></ng-template>
            <div *ngIf="(leftButtons && leftButtons.length > 0) || (rightButtons && rightButtons.length > 0)" class="modal-footer justify-content-between w-100">
                <div class="d-flex">
                    <button *ngFor="let button of leftButtons" type="button" class="btn btn-sm btn-outline-dark rounded-pill px-4" (click)="buttonClicked(button)">
                        {{button.label}}
                    </button>
                </div>
                <div class="d-flex text-end">
                    <button *ngFor="let button of rightButtons" type="button" class="btn btn-sm btn-dark rounded-pill px-4" (click)="buttonClicked(button)">
                        {{button.label}}
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
