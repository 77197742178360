import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from "@azure/msal-angular";
import { AuthenticationResult, InteractionType } from "@azure/msal-browser";

import { EMPTY, Observable } from "rxjs";
import { catchError, switchMap } from 'rxjs/operators';
import { environment } from "../../environments/environment";

@Injectable()
export class RequestHeadersInterceptor implements HttpInterceptor {
    private nextRequest: boolean = true;

    constructor(@Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,) {}

    public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const scopes = [environment.apiClientID];
        const accounts = this.authService.instance.getAllAccounts();
        const account = accounts[0];
        return this.authService.acquireTokenSilent({ scopes, account })
        .pipe(
            catchError(() => {
                if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
                    return this.authService.acquireTokenPopup({ ...this.msalGuardConfig.authRequest, scopes });
                }
                return EMPTY;
            }),
            switchMap((result: AuthenticationResult) => {
                const headers = req.headers
                    .set('Authorization', `Bearer ${result.accessToken}`);

                const requestClone = req.clone({ headers });
                return next.handle(requestClone);
            })
        );
    }
}