import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppointmentsComponent } from './appointments.component';
import { AppointmentDetailsComponent } from './appointment-details/appointment-details.component';
import { AppointmentsHistoryComponent } from './appointments-history/appointments-history.component';
import { CareMemberMapComponent } from './care-member-map/care-member-map.component';
import { RecentAppointmentsComponent } from './recent-appointments/recent-appointments.component';
import { NotificationByEmailComponent } from './appointment-details/notification-by-email/notification-by-email.component';
import { MbscModule } from '@mobiscroll/angular';
import { DateRangeComponent } from './date-range/date-range.component';
import { CommonsModule } from '../commons/commons.module';
import { CommonsUiModule } from '../commons-ui/commons-ui.module';
import { CareMemberCalendarComponent } from './care-member-calendar/care-member-calendar.component';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxMaskDirective, NgxMaskPipe, provideEnvironmentNgxMask } from 'ngx-mask';
import { HttpClientJsonpModule } from '@angular/common/http';

@NgModule({
    declarations: [
        AppointmentsComponent,
        AppointmentDetailsComponent,
        AppointmentsHistoryComponent,
        CareMemberMapComponent,
        RecentAppointmentsComponent,
        NotificationByEmailComponent,
        DateRangeComponent,
        CareMemberCalendarComponent,
    ],
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        MbscModule,
        CommonsModule,
        CommonsUiModule,
        HttpClientJsonpModule,
        NgxMaskDirective,
        NgxMaskPipe
    ],
    providers:[
        provideEnvironmentNgxMask()
    ]
})
export class AppointmentsModule { }
