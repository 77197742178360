import { Pipe, PipeTransform } from '@angular/core';
import { CommonDateTimeFormatter } from './common-date-time-formatter';

@Pipe({
    name: 'usDateTime'
})

export class USDateTimePipe implements PipeTransform {

    constructor(private commonDateTimeFormatter: CommonDateTimeFormatter){}

    transform(value: any, offsetMillis?: number): any {
        return this.commonDateTimeFormatter.transform(value, 'MM/dd/yyyy h:mm a', offsetMillis);
    }
}