import { Component, NgZone, OnInit } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { setOptions, MbscEventcalendarView, MbscCalendarEvent, MbscEventcalendarOptions, MbscPageChangeEvent  } from '@mobiscroll/angular';
import * as moment from 'moment-timezone';
import { CareMemberCalenderEvents, HSCalendarService } from 'src/app/api-client';
import { GenericModalService } from 'src/app/commons-ui/generic-modal/generic-modal-service';
import { Loader } from 'src/app/commons-ui/loader';
import { CommonConstants } from 'src/app/commons/common-constants';
import { DateTime } from 'src/app/commons/date-time';
import { CalendarEvent } from 'src/app/schedule/schedule-calendar/calendar-event';
import { ViewSchedulePopupComponent, ViewSchedulePopupInput } from 'src/app/schedule/view-schedule-popup/view-schedule-popup.component';
import { LoggingServiceTsService } from 'src/app/store/loggingService/logging.service';



setOptions({
    theme: 'ios',
    themeVariant: 'light',
    clickToCreate: false,
    dragToCreate: false,
    dragToMove: false,
    dragToResize: false
});

@Component({
  selector: 'hs-care-member-calendar',
  templateUrl: './care-member-calendar.component.html',
  styleUrls: ['./care-member-calendar.component.scss']
})
export class CareMemberCalendarComponent implements OnInit {
  public view = 'week';
  public calView: MbscEventcalendarView = {
    schedule: { type: 'week' }
  };
  public events: MbscCalendarEvent[] = [];
  public calendarOptions: MbscEventcalendarOptions = {
    onPageChange: (args: MbscPageChangeEvent) => {
      this.loadCareMemberEvents(args.firstDay, args.lastDay);
    },
    onEventClick: (args) => {
      // const timeZone: string = args.event.timezone ?? "US/Eastern";
      // const tempDate = args.date;
      // let tzDate = new Date(tempDate.toLocaleString('en-US', { timeZone }));
      // const timezoneOffset = tempDate.getTime() - tzDate.getTime();
      const timezoneOffset = new Date().getTimezoneOffset();

      if(!args.event.isHSSchedule) {
          return;
      }
      this.genericModalService.showModel<ViewSchedulePopupInput, void>(
        ViewSchedulePopupComponent,
        {
          event: { ...args.event, ...args.event.careMemberSchedule },
          timezoneOffset: timezoneOffset,
          showOptionalButtons: true,
        }
      );
    },
  }
  
  constructor(private calendarService: HSCalendarService, 
              public zone: NgZone, private genericModalService: GenericModalService,
              private authService: MsalService,private loging : LoggingServiceTsService) {}
  ngOnInit(): void {
    localStorage.removeItem('requestedUrl');
    let session = this.loging.GetSessionDetails();
    this.loging.logPageView("Care Member Calendar Page",window.location.href,session);
    const weekDays = DateTime.getWeekDates(DateTime.now());
    this.loadCareMemberEvents(weekDays[0], weekDays[1]);
  }
  private scheduleColor(event: CalendarEvent): string {
    if(!event.isHSSchedule) {
      return CommonConstants.LIGHTGRAY;
    }
    const modeId = event.careMemberSchedule? String(event.careMemberSchedule.modeId).toLowerCase(): '';
    if(modeId === CommonConstants.IN_HOME) {
      return CommonConstants.NEW_IN_HOME_COLOR;
    } else if (modeId === CommonConstants.TELEHEALTH) {
      return CommonConstants.NEW_TELEHEALTH_COLOR;
    } else if (modeId === CommonConstants.TELEPHONE) {
      return CommonConstants.NEW_TELEPHONE_COLOR;
    }else if(modeId === CommonConstants.IN_CLINIC) {
      return CommonConstants.NEW_IN_CLINIC_COLOR;
    }
    return ''
  }
  private loadCareMemberEvents(startDate: Date, endDate: Date): void {
    Loader.showLoader("my-calendar-view");
    this.calendarService.hSCalendarMySchedule (startDate, endDate).subscribe((response: CareMemberCalenderEvents) => {
      const allEvents: MbscCalendarEvent[] = [];
      const events: Array<CalendarEvent> = response.events? response.events : [];
        for (const event of events) {
            
            let startDate = event.fromDate ? new Date(event.fromDate + 'Z') : undefined;
            let endDate = event.toDate ? new Date(event.toDate + 'Z') : undefined;
            if (event.isAllDay) {
                startDate = event.fromDate ? new Date(event.fromDate) : undefined;
                endDate = event.toDate ? new Date(new Date(event.toDate).getTime() - 1) : undefined;
            }
        allEvents.push({
          careMemberSchedule: event.careMemberSchedule,
          isHSSchedule: event.isHSSchedule,
          patientId: event.patientId,
          showAs: event.showAs,
          start: startDate,
          end: endDate,
          title: event.title ? event.title : '',
          color: this.scheduleColor(event),
        });
      }
      Loader.hideLoader("my-calendar-view");
      this.zone.run(() => {
        this.events = [...allEvents];
      });
    })
  }
  changeView(): void {
      setTimeout(() => {
        switch (this.view) {
          case 'year':
              this.calView = {
                  calendar: { type: 'year' }
              };
              break;
          case 'month':
              this.calView = {
                  calendar: { type: 'month' },
              };
              break;
          case 'week':
              this.calView = {
                  schedule: { type: 'week' }
              };
              break;
          case 'day':
              this.calView = {
                  schedule: { type: 'day' }
              };
              break;
          case 'agenda':
              this.calView = {
                  calendar: { type: 'week' },
                  agenda: { type: 'week' }
              };
              break;
        }
      });
  }
}
