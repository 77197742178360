/**
 * Somatus Hybrid Scheduler API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { AutoSearchScheduleResponse } from '../model/autoSearchScheduleResponse';
import { AvailableSlotsScheduleResponse } from '../model/availableSlotsScheduleResponse';
import { CalenderModel } from '../model/calenderModel';
import { CareMemberCalenderEvents } from '../model/careMemberCalenderEvents';
import { CentralNurseScheduleRequest } from '../model/centralNurseScheduleRequest';
import { CentralNurseScheduleResponse } from '../model/centralNurseScheduleResponse';
import { NPScheduleAvailSlotsRequest } from '../model/nPScheduleAvailSlotsRequest';
import { RefreshCalenderSlotDeltaRequest } from '../model/refreshCalenderSlotDeltaRequest';
import { ReportCareMemberCalenderSlotDeltaRequest } from '../model/reportCareMemberCalenderSlotDeltaRequest';
import { ScheduleDaySlotsRequest } from '../model/scheduleDaySlotsRequest';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class HSCalendarService {

    protected basePath = 'http://localhost:5000/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param meetingId 
     * @param comment 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public hSCalendarCancelMeetingStatus(meetingId: string, comment: string, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public hSCalendarCancelMeetingStatus(meetingId: string, comment: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public hSCalendarCancelMeetingStatus(meetingId: string, comment: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public hSCalendarCancelMeetingStatus(meetingId: string, comment: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (meetingId === null || meetingId === undefined) {
            throw new Error('Required parameter meetingId was null or undefined when calling hSCalendarCancelMeetingStatus.');
        }

        if (comment === null || comment === undefined) {
            throw new Error('Required parameter comment was null or undefined when calling hSCalendarCancelMeetingStatus.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<string>('put',`${this.basePath}/api/HSCalendar/cancel/${encodeURIComponent(String(meetingId))}/${encodeURIComponent(String(comment))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public hSCalendarCancelPatientsAppointmentsAfterEligibility(observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public hSCalendarCancelPatientsAppointmentsAfterEligibility(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public hSCalendarCancelPatientsAppointmentsAfterEligibility(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public hSCalendarCancelPatientsAppointmentsAfterEligibility(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<boolean>('get',`${this.basePath}/api/HSCalendar/cancel/apppointments`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public hSCalendarCreate(body: CalenderModel, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public hSCalendarCreate(body: CalenderModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public hSCalendarCreate(body: CalenderModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public hSCalendarCreate(body: CalenderModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling hSCalendarCreate.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<boolean>('post',`${this.basePath}/api/HSCalendar`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public hSCalendarDeliverPendingEmails(observe?: 'body', reportProgress?: boolean): Observable<any>;
    public hSCalendarDeliverPendingEmails(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public hSCalendarDeliverPendingEmails(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public hSCalendarDeliverPendingEmails(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/HSCalendar/send/emails`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param careMemberId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public hSCalendarGenerateByCareMemberCalender(body: ReportCareMemberCalenderSlotDeltaRequest, careMemberId: number, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public hSCalendarGenerateByCareMemberCalender(body: ReportCareMemberCalenderSlotDeltaRequest, careMemberId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public hSCalendarGenerateByCareMemberCalender(body: ReportCareMemberCalenderSlotDeltaRequest, careMemberId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public hSCalendarGenerateByCareMemberCalender(body: ReportCareMemberCalenderSlotDeltaRequest, careMemberId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling hSCalendarGenerateByCareMemberCalender.');
        }

        if (careMemberId === null || careMemberId === undefined) {
            throw new Error('Required parameter careMemberId was null or undefined when calling hSCalendarGenerateByCareMemberCalender.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<boolean>('post',`${this.basePath}/api/HSCalendar/generate/${encodeURIComponent(String(careMemberId))}/calender/token`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param careMemberId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public hSCalendarGenerateByCareMemberCalenderView(body: ReportCareMemberCalenderSlotDeltaRequest, careMemberId: number, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public hSCalendarGenerateByCareMemberCalenderView(body: ReportCareMemberCalenderSlotDeltaRequest, careMemberId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public hSCalendarGenerateByCareMemberCalenderView(body: ReportCareMemberCalenderSlotDeltaRequest, careMemberId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public hSCalendarGenerateByCareMemberCalenderView(body: ReportCareMemberCalenderSlotDeltaRequest, careMemberId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling hSCalendarGenerateByCareMemberCalenderView.');
        }

        if (careMemberId === null || careMemberId === undefined) {
            throw new Error('Required parameter careMemberId was null or undefined when calling hSCalendarGenerateByCareMemberCalenderView.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<boolean>('post',`${this.basePath}/api/HSCalendar/generate/${encodeURIComponent(String(careMemberId))}/calenderview/token`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public hSCalendarGenerateCalenderEventsDelta(body: ReportCareMemberCalenderSlotDeltaRequest, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public hSCalendarGenerateCalenderEventsDelta(body: ReportCareMemberCalenderSlotDeltaRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public hSCalendarGenerateCalenderEventsDelta(body: ReportCareMemberCalenderSlotDeltaRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public hSCalendarGenerateCalenderEventsDelta(body: ReportCareMemberCalenderSlotDeltaRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling hSCalendarGenerateCalenderEventsDelta.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<boolean>('post',`${this.basePath}/api/HSCalendar/generate/delta/token`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public hSCalendarGetCentralNurseSchedules(body: CentralNurseScheduleRequest, observe?: 'body', reportProgress?: boolean): Observable<CentralNurseScheduleResponse>;
    public hSCalendarGetCentralNurseSchedules(body: CentralNurseScheduleRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CentralNurseScheduleResponse>>;
    public hSCalendarGetCentralNurseSchedules(body: CentralNurseScheduleRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CentralNurseScheduleResponse>>;
    public hSCalendarGetCentralNurseSchedules(body: CentralNurseScheduleRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling hSCalendarGetCentralNurseSchedules.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<CentralNurseScheduleResponse>('post',`${this.basePath}/api/HSCalendar/centralnurse/schedule`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public hSCalendarGetNurseSchedules(body: NPScheduleAvailSlotsRequest, observe?: 'body', reportProgress?: boolean): Observable<Array<AutoSearchScheduleResponse>>;
    public hSCalendarGetNurseSchedules(body: NPScheduleAvailSlotsRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<AutoSearchScheduleResponse>>>;
    public hSCalendarGetNurseSchedules(body: NPScheduleAvailSlotsRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<AutoSearchScheduleResponse>>>;
    public hSCalendarGetNurseSchedules(body: NPScheduleAvailSlotsRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling hSCalendarGetNurseSchedules.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Array<AutoSearchScheduleResponse>>('post',`${this.basePath}/api/HSCalendar/autosearch`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public hSCalendarGetNurseSchedulesDaySlots(body: ScheduleDaySlotsRequest, observe?: 'body', reportProgress?: boolean): Observable<Array<AvailableSlotsScheduleResponse>>;
    public hSCalendarGetNurseSchedulesDaySlots(body: ScheduleDaySlotsRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<AvailableSlotsScheduleResponse>>>;
    public hSCalendarGetNurseSchedulesDaySlots(body: ScheduleDaySlotsRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<AvailableSlotsScheduleResponse>>>;
    public hSCalendarGetNurseSchedulesDaySlots(body: ScheduleDaySlotsRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling hSCalendarGetNurseSchedulesDaySlots.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Array<AvailableSlotsScheduleResponse>>('post',`${this.basePath}/api/HSCalendar/autosearch/slots`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param careMemberId 
     * @param startDateTime 
     * @param endDateTime 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public hSCalendarMemberSchedule(careMemberId: number, startDateTime?: Date, endDateTime?: Date, observe?: 'body', reportProgress?: boolean): Observable<CareMemberCalenderEvents>;
    public hSCalendarMemberSchedule(careMemberId: number, startDateTime?: Date, endDateTime?: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CareMemberCalenderEvents>>;
    public hSCalendarMemberSchedule(careMemberId: number, startDateTime?: Date, endDateTime?: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CareMemberCalenderEvents>>;
    public hSCalendarMemberSchedule(careMemberId: number, startDateTime?: Date, endDateTime?: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (careMemberId === null || careMemberId === undefined) {
            throw new Error('Required parameter careMemberId was null or undefined when calling hSCalendarMemberSchedule.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (startDateTime !== undefined && startDateTime !== null) {
            queryParameters = queryParameters.set('startDateTime', <any>startDateTime.toISOString());
        }
        if (endDateTime !== undefined && endDateTime !== null) {
            queryParameters = queryParameters.set('endDateTime', <any>endDateTime.toISOString());
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<CareMemberCalenderEvents>('get',`${this.basePath}/api/HSCalendar/schedules/${encodeURIComponent(String(careMemberId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param startDateTime 
     * @param endDateTime 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public hSCalendarMySchedule(startDateTime?: Date, endDateTime?: Date, observe?: 'body', reportProgress?: boolean): Observable<CareMemberCalenderEvents>;
    public hSCalendarMySchedule(startDateTime?: Date, endDateTime?: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CareMemberCalenderEvents>>;
    public hSCalendarMySchedule(startDateTime?: Date, endDateTime?: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CareMemberCalenderEvents>>;
    public hSCalendarMySchedule(startDateTime?: Date, endDateTime?: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (startDateTime !== undefined && startDateTime !== null) {
            queryParameters = queryParameters.set('startDateTime', <any>startDateTime.toISOString());
        }
        if (endDateTime !== undefined && endDateTime !== null) {
            queryParameters = queryParameters.set('endDateTime', <any>endDateTime.toISOString());
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<CareMemberCalenderEvents>('get',`${this.basePath}/api/HSCalendar/schedules/mine`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param duration 
     * @param delta 
     * @param modeId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public hSCalendarSendAppointmentReminder(duration: number, delta: number, modeId: string, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public hSCalendarSendAppointmentReminder(duration: number, delta: number, modeId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public hSCalendarSendAppointmentReminder(duration: number, delta: number, modeId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public hSCalendarSendAppointmentReminder(duration: number, delta: number, modeId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (duration === null || duration === undefined) {
            throw new Error('Required parameter duration was null or undefined when calling hSCalendarSendAppointmentReminder.');
        }

        if (delta === null || delta === undefined) {
            throw new Error('Required parameter delta was null or undefined when calling hSCalendarSendAppointmentReminder.');
        }

        if (modeId === null || modeId === undefined) {
            throw new Error('Required parameter modeId was null or undefined when calling hSCalendarSendAppointmentReminder.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Blob>('get',`${this.basePath}/api/HSCalendar/send-reminder/${encodeURIComponent(String(duration))}/${encodeURIComponent(String(delta))}/${encodeURIComponent(String(modeId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public hSCalendarSendNotification(observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public hSCalendarSendNotification(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public hSCalendarSendNotification(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public hSCalendarSendNotification(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<boolean>('get',`${this.basePath}/api/HSCalendar/send-notification`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param icalId 
     * @param refId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public hSCalendarStatus(icalId: string, refId: string, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public hSCalendarStatus(icalId: string, refId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public hSCalendarStatus(icalId: string, refId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public hSCalendarStatus(icalId: string, refId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (icalId === null || icalId === undefined) {
            throw new Error('Required parameter icalId was null or undefined when calling hSCalendarStatus.');
        }

        if (refId === null || refId === undefined) {
            throw new Error('Required parameter refId was null or undefined when calling hSCalendarStatus.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<string>('get',`${this.basePath}/api/HSCalendar/status/icalId/${encodeURIComponent(String(icalId))}/${encodeURIComponent(String(refId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param meetingId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public hSCalendarStatusByMeetingId(meetingId: string, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public hSCalendarStatusByMeetingId(meetingId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public hSCalendarStatusByMeetingId(meetingId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public hSCalendarStatusByMeetingId(meetingId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (meetingId === null || meetingId === undefined) {
            throw new Error('Required parameter meetingId was null or undefined when calling hSCalendarStatusByMeetingId.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<string>('get',`${this.basePath}/api/HSCalendar/status/meetings/${encodeURIComponent(String(meetingId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param careMemberId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public hSCalendarUpdateByCareMemberCalenderEventsDelta(careMemberId: number, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public hSCalendarUpdateByCareMemberCalenderEventsDelta(careMemberId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public hSCalendarUpdateByCareMemberCalenderEventsDelta(careMemberId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public hSCalendarUpdateByCareMemberCalenderEventsDelta(careMemberId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (careMemberId === null || careMemberId === undefined) {
            throw new Error('Required parameter careMemberId was null or undefined when calling hSCalendarUpdateByCareMemberCalenderEventsDelta.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<boolean>('get',`${this.basePath}/api/HSCalendar/caremember/${encodeURIComponent(String(careMemberId))}/calender/slot/delta`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param fromDateTime 
     * @param toDateTime 
     * @param timeZone 
     * @param eventId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public hSCalendarUpdateCalendar(fromDateTime: string, toDateTime: string, timeZone: string, eventId: string, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public hSCalendarUpdateCalendar(fromDateTime: string, toDateTime: string, timeZone: string, eventId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public hSCalendarUpdateCalendar(fromDateTime: string, toDateTime: string, timeZone: string, eventId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public hSCalendarUpdateCalendar(fromDateTime: string, toDateTime: string, timeZone: string, eventId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (fromDateTime === null || fromDateTime === undefined) {
            throw new Error('Required parameter fromDateTime was null or undefined when calling hSCalendarUpdateCalendar.');
        }

        if (toDateTime === null || toDateTime === undefined) {
            throw new Error('Required parameter toDateTime was null or undefined when calling hSCalendarUpdateCalendar.');
        }

        if (timeZone === null || timeZone === undefined) {
            throw new Error('Required parameter timeZone was null or undefined when calling hSCalendarUpdateCalendar.');
        }

        if (eventId === null || eventId === undefined) {
            throw new Error('Required parameter eventId was null or undefined when calling hSCalendarUpdateCalendar.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<boolean>('put',`${this.basePath}/api/HSCalendar/update/${encodeURIComponent(String(fromDateTime))}/${encodeURIComponent(String(toDateTime))}/${encodeURIComponent(String(timeZone))}/${encodeURIComponent(String(eventId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public hSCalendarUpdateCareMemberCalenderEvents(body: RefreshCalenderSlotDeltaRequest, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public hSCalendarUpdateCareMemberCalenderEvents(body: RefreshCalenderSlotDeltaRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public hSCalendarUpdateCareMemberCalenderEvents(body: RefreshCalenderSlotDeltaRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public hSCalendarUpdateCareMemberCalenderEvents(body: RefreshCalenderSlotDeltaRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling hSCalendarUpdateCareMemberCalenderEvents.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<boolean>('post',`${this.basePath}/api/HSCalendar/caremember/calender/slot/delta`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public hSCalendarUpdateLatLng(observe?: 'body', reportProgress?: boolean): Observable<any>;
    public hSCalendarUpdateLatLng(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public hSCalendarUpdateLatLng(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public hSCalendarUpdateLatLng(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/HSCalendar/update/latlng`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param meetingId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public hSCalendarUpdateMeetingStatus(meetingId: string, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public hSCalendarUpdateMeetingStatus(meetingId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public hSCalendarUpdateMeetingStatus(meetingId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public hSCalendarUpdateMeetingStatus(meetingId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (meetingId === null || meetingId === undefined) {
            throw new Error('Required parameter meetingId was null or undefined when calling hSCalendarUpdateMeetingStatus.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<string>('put',`${this.basePath}/api/HSCalendar/schedules/${encodeURIComponent(String(meetingId))}/status`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
