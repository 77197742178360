import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

function dateCompare(dateTimeA: string, dateTimeB: string) {
  var momentA = moment(dateTimeA,"DD/MM/YYYY");
  var momentB = moment(dateTimeB,"DD/MM/YYYY");
  if (momentA > momentB) return 1;
  else if (momentA < momentB) return -1;
  else return 0;
}

@Pipe({
  name: 'dateRangeFilter'
})
export class DateRangeFilter implements PipeTransform {

  constructor(){}

  transform(value: any, args: string): any {
    const filteredList = value;
    if (args && value) {
      const dates = args.split('-');
      return filteredList.filter((item: any) => {
        const data = new Date(item.scheduleDateTime);
        const year = data.getFullYear();
        const month = data.getMonth() + 1;
        const day = data.getDate();
        const firstCompare = dateCompare(`${day}/${month}/${year}`, dates[0].trim());
        const secoundCompare = dateCompare(dates[1].trim(), `${day}/${month}/${year}`);
        return firstCompare !== -1 && secoundCompare !== -1;
      })
    }
    return value;
  }
}
