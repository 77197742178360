/**
 * Somatus Hybrid Scheduler API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { AppointmentWizardType } from '../model/appointmentWizardType';
import { AvaiableSlotRequestModel } from '../model/avaiableSlotRequestModel';
import { AvailableSlots } from '../model/availableSlots';
import { CareMemberInfo } from '../model/careMemberInfo';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class AppointmentWizardService {

    protected basePath = 'http://localhost:5001/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param stateId 
     * @param isDefaultAssigned 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public appointmentWizardGetAppointmentWizardTypes(stateId?: string, isDefaultAssigned?: boolean, observe?: 'body', reportProgress?: boolean): Observable<Array<AppointmentWizardType>>;
    public appointmentWizardGetAppointmentWizardTypes(stateId?: string, isDefaultAssigned?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<AppointmentWizardType>>>;
    public appointmentWizardGetAppointmentWizardTypes(stateId?: string, isDefaultAssigned?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<AppointmentWizardType>>>;
    public appointmentWizardGetAppointmentWizardTypes(stateId?: string, isDefaultAssigned?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (stateId !== undefined && stateId !== null) {
            queryParameters = queryParameters.set('stateId', <any>stateId);
        }
        if (isDefaultAssigned !== undefined && isDefaultAssigned !== null) {
            queryParameters = queryParameters.set('isDefaultAssigned', <any>isDefaultAssigned);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<AppointmentWizardType>>('get',`${this.basePath}/api/AppointmentWizard/Get/Mode_Type`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param searchText 
     * @param careMemberTypeID 
     * @param stateID 
     * @param modeId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public appointmentWizardGetCareTeamMemberByState(searchText?: string, careMemberTypeID?: number, stateID?: string, modeId?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<CareMemberInfo>>;
    public appointmentWizardGetCareTeamMemberByState(searchText?: string, careMemberTypeID?: number, stateID?: string, modeId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<CareMemberInfo>>>;
    public appointmentWizardGetCareTeamMemberByState(searchText?: string, careMemberTypeID?: number, stateID?: string, modeId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<CareMemberInfo>>>;
    public appointmentWizardGetCareTeamMemberByState(searchText?: string, careMemberTypeID?: number, stateID?: string, modeId?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (searchText !== undefined && searchText !== null) {
            queryParameters = queryParameters.set('searchText', <any>searchText);
        }
        if (careMemberTypeID !== undefined && careMemberTypeID !== null) {
            queryParameters = queryParameters.set('careMemberTypeID', <any>careMemberTypeID);
        }
        if (stateID !== undefined && stateID !== null) {
            queryParameters = queryParameters.set('stateID', <any>stateID);
        }
        if (modeId !== undefined && modeId !== null) {
            queryParameters = queryParameters.set('modeId', <any>modeId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<CareMemberInfo>>('get',`${this.basePath}/api/AppointmentWizard/search/stateID`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public appointmentWizardSearchAvailableSlots(body: AvaiableSlotRequestModel, observe?: 'body', reportProgress?: boolean): Observable<Array<AvailableSlots>>;
    public appointmentWizardSearchAvailableSlots(body: AvaiableSlotRequestModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<AvailableSlots>>>;
    public appointmentWizardSearchAvailableSlots(body: AvaiableSlotRequestModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<AvailableSlots>>>;
    public appointmentWizardSearchAvailableSlots(body: AvaiableSlotRequestModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling appointmentWizardSearchAvailableSlots.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Array<AvailableSlots>>('post',`${this.basePath}/api/AppointmentWizard/SearchAvailableSlots`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
