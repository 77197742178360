import { Pipe, PipeTransform } from '@angular/core';
import { parsePhoneNumber, CountryCode } from 'libphonenumber-js/min';

@Pipe({
  name: 'phone'
})
export class PhonePipe implements PipeTransform {

  transform(phoneValue: number | string): any {
    if(!phoneValue) {
      return "";
    }
    try {
      const phoneNumber = parsePhoneNumber(phoneValue.toString(), "US");
      return phoneNumber.formatNational();
    } catch (error) {
      return phoneValue;
    }
  }
}
