import { Time } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BestTimeService } from '../../api-client/api/bestTime.service';
import { CareTeamService } from '../../api-client/api/careTeam.service';
import { AvailableSlotsScheduleResponse } from '../../api-client/model/availableSlotsScheduleResponse';
import { CareMemberInfo } from '../../api-client/model/careMemberInfo';
import { ListAvailableSlotsBestTimeResponse } from '../../api-client/model/listAvailableSlotsBestTimeResponse';
import { PatientModel } from '../../api-client/model/patientModel';
import { ScheduleBestTimeSlotsRequest } from '../../api-client/model/scheduleBestTimeSlotsRequest';
import { Loader } from '../../commons-ui/loader';
import { ScheduleService } from '../services/schedule.service';

@Component({
  selector: 'hs-search-best-time',
  templateUrl: './search-best-time.component.html',
  styleUrls: ['./search-best-time.component.scss']
})
export class SearchBestTimeComponent implements OnInit {
    public readonly durationTimes: number[] = [90, 60];
    public searchForm: UntypedFormGroup;
    public careTeamMembers: CareMemberInfo[] = [];
    public scheduleList: AvailableSlotsScheduleResponse[] = [];
    public selectedTimezone: string = 'Eastern Standard Time';
    public selectedTimezoneCode: string = 'US/Eastern';
    public isPreviousButtonDisabled: boolean = true;
    public isNextButtonDisabled: boolean = true;
    public elasticId?: string;
    @Output() submitBestTimeEvent = new EventEmitter<CareMemberInfo[]>();
    @Output() submitElasticEvent = new EventEmitter<string>();
    @Input() patient?: PatientModel;
    @Input() careMembers!: Map<any, CareMemberInfo>;
    constructor(private fb: UntypedFormBuilder,
        private careTeamService: CareTeamService,
        private scheduleService: ScheduleService, private bestTimeService: BestTimeService,) {
        this.searchForm = this.fb.group({
            careMemberId: ['', Validators.required],
            selectedDate: [new Date(), Validators.required],
            duration: [90, Validators.required],
        })
    }

    ngOnInit(): void {
        this.scheduleService.$openBestTimeScheduleModal.subscribe((value: string) => {
            if (value === 'open') {
                this.loadCareTeamMembers();
                this.scheduleList = [];
                this.isPreviousButtonDisabled = true;
                this.isNextButtonDisabled = true;
                this.searchForm.controls.selectedDate.setValue(new Date(new Date().toLocaleDateString('en-US')));
                Loader.hideLoader("autoBestTimeScheduleModalBody");
            }
        });
        this.scheduleService.$shareTimezonSub.subscribe((value) => {
            this.selectedTimezone = value;
        });
  }
    public set time(data: Time) {
        this.searchForm.controls.selectedTime.setValue(data)
    }

    private async loadCareTeamMembers(): Promise<void> {
        let that = this;
        that.careTeamMembers = [];
        this.careMembers.forEach(function (item)
        {
            that.careTeamMembers.push(item)
        });
        this.searchForm.controls.careMemberId.setValue(this.careTeamMembers[0].id)
    }

    public fetchScheduleSearchList(): void {
        if (this.patient && this.patient.state) {
            Loader.showLoader("autoBestTimeScheduleModalBody");
            const requestPayload: ScheduleBestTimeSlotsRequest = {
                duration: this.searchForm.value.duration,
                startDate: `${this.searchForm.value.selectedDate.toLocaleDateString('en-US')}`,
                timeZone: this.selectedTimezone,
                userId: Number(this.patient.id),
                memberId: this.patient.memberId,
                careMemberId: this.searchForm.value.careMemberId
            };
            this.isNextButtonDisabled = false;
            if (this.searchForm.value.selectedDate.toLocaleDateString('en-US') == new Date().toLocaleDateString('en-US')) {
                this.isPreviousButtonDisabled = true;
            }
            else {
                this.isPreviousButtonDisabled = false;
            }
            this.bestTimeService.bestTimeGet(requestPayload).subscribe((data: ListAvailableSlotsBestTimeResponse) => {
                this.scheduleList = data.collection!;
                this.elasticId = data.elasticId!;
                this.scheduleList.forEach((item: AvailableSlotsScheduleResponse) => {
                    var startdate = new Date(item.startDateTime);
                    item.startDateTime = new Date(startdate.toLocaleString('en-US', { timeZone: this.selectedTimezoneCode }));

                    var enddate = new Date(item.endDateTime);
                    item.endDateTime = new Date(enddate.toLocaleString('en-US', { timeZone: this.selectedTimezoneCode }));
                });
                Loader.hideLoader("autoBestTimeScheduleModalBody");
            });
        }

        this.scheduleService.$shareTimezonCodeSub.subscribe((value) => {
            this.selectedTimezoneCode = value;
        });
    }

    public ResetFetchScheduleList($event: Event): void {
        $event.stopPropagation();
        var date = new Date(new Date().toLocaleDateString('en-US'));
        this.searchForm.controls.selectedDate.setValue(date);
        this.fetchScheduleSearchList();
    }

    public afterFetchScheduleList($event: Event): void {
        $event.stopPropagation();
        let length = this.scheduleList.length;
        let date = new Date(this.searchForm.value.selectedDate);
        let convertedDate = new Date(date.setDate(date.getDate() + 7));
        this.searchForm.controls.selectedDate.setValue(convertedDate);
        this.fetchScheduleSearchList();
    }

    public previousFetchScheduleList($event: Event): void {
        $event.stopPropagation();
        let length = this.scheduleList.length;
        let date = new Date(this.searchForm.value.selectedDate);
        let convertedDate = new Date(date.setDate(date.getDate() - 7));
        this.searchForm.controls.selectedDate.setValue(convertedDate);
        this.fetchScheduleSearchList();
    }

    public submitAutoSearchSchedules(item: any): void {
        let careMemberInfo: CareMemberInfo[] = [];
        let obj: CareMemberInfo = {
            id: item.careMemberId!,
            name: item.name,
            emailId: item.emailId,
            schedulerId: 0,
            scheduleStartDate: item.startDateTime!,
            scheduleEndDate: item.endDateTime!,
            schedulePreTime: 0,
            schedulePostTime: 0,
            appointmentCount: 0,
            type: item.type,
            distance:0
        };
        careMemberInfo.push(obj);
        this.submitBestTimeEvent.emit(careMemberInfo);
        this.scheduleService.openCareMemberEvents(obj);
        this.submitElasticEvent.emit(this.elasticId);
    }
}
