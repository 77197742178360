import { Component, Input, OnInit } from '@angular/core';
import { CentralNurseScheduleResponse } from '../../../api-client';

@Component({
  selector: 'hs-auto-central-nurse-availability-ui',
  templateUrl: './auto-central-nurse-availability-ui.component.html',
  styleUrls: ['./auto-central-nurse-availability-ui.component.scss']
})
export class AutoCentralNurseAvailabilityUiComponent {

    @Input()
    public set careMember(slots: CentralNurseScheduleResponse) {
        if (slots) {
            this.centralNurseSlot = slots;
        }
    }
    @Input() times: string[] = [];
    public centralNurseSlot: CentralNurseScheduleResponse = { careMemberId: 0, slot1: false, slot2: false, slot3: false, startDateTime: new Date() };
    constructor() { }
}
