import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { CommonConstants } from '../commons/common-constants';
import { Router } from '@angular/router';

import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import { AuthenticationResult, InteractionStatus, PopupRequest, RedirectRequest, EventMessage, EventType } from '@azure/msal-browser';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { LoggingServiceTsService } from '../store/loggingService/logging.service';
import { v4 as uuidv4 } from 'uuid';
import { ReferenceDataProvider } from '../commons/reference-data-provider';

@Component({
  selector: 'hs-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  providers: [
  MsalService]
})
export class HomeComponent implements OnInit, OnDestroy{

  loggedIn: boolean = false
  profile: any;
  isIframe = false;
  loginDisplay = false;
  private readonly _destroying$ = new Subject<void>();

  constructor(
    private http: HttpClient,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private router: Router,
    private logging: LoggingServiceTsService,
    private readonly referenceDataProvider : ReferenceDataProvider
  ) { }

  ngOnInit(): void {
    let session = this.logging.GetSessionDetails();
    this.logging.logPageView("Home Page",window.location.href,session);
    this.logging.logEventForComponentRendering("User Authentication : Login Home Screen")
    this.checkAndSetActiveAccount();
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
      )
      .subscribe((result: EventMessage) => {
        const payload = result.payload as AuthenticationResult;
        this.authService.instance.setActiveAccount(payload.account);
        this.checkAndSetActiveAccount();
        this.getClaims(this.authService.instance.getActiveAccount()?.idTokenClaims);
      });
    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.checkAndSetActiveAccount();
        this.getClaims(this.authService.instance.getActiveAccount()?.idTokenClaims);
      })


  }
  checkAndSetActiveAccount() {
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */


    if (this.authService.instance.getAllAccounts().length > 0) {
      this.loggedIn = true;
      let accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(accounts[0]);
      this.router.navigateByUrl('/dashboard');


      const navUrl = localStorage.getItem('requestedUrl');
      let url = CommonConstants.DEFAULT_HOME;
      try {
        url = navUrl && JSON.parse(navUrl)[0];
      } catch(error) {}
      if (!url || url === '/' || url === '') {
        this.router.navigateByUrl( CommonConstants.DEFAULT_HOME );
      } else {
        this.router.navigateByUrl( url );
      }
      localStorage.removeItem('requestedUrl');
    }

  }


  async loginRedirect() {
    this.logging.logEventForComponentRendering("User Authentication : User Clicked On ' Sign In as Somatus Employee ' ")
    if (this.msalGuardConfig.authRequest) {
      try {
        await this.authService.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest);
      } catch(error) {
        console.log(error);
      }
    } 
    else {
      try {
        await this.authService.loginRedirect();
      } catch (error) {
        console.log(error);
      }
    }
  }

  loginPopup() {
    if (this.msalGuardConfig.authRequest) {
      this.authService.loginPopup({ ...this.msalGuardConfig.authRequest } as PopupRequest)
        .subscribe((response: AuthenticationResult) => {
          this.authService.instance.setActiveAccount(response.account);
        });
    } else {
      this.authService.loginPopup()
        .subscribe((response: AuthenticationResult) => {
          this.authService.instance.setActiveAccount(response.account);
        });
    }
  }

  async logout(popup?: boolean) {
    if (popup) {
      await this.authService.logoutPopup({
        mainWindowRedirectUri: "/"
      });
    } 
    else {
      await this.authService.logoutRedirect();
    }
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
  getProfile() {
    let graph_endpoint = this.referenceDataProvider.getAzureConfigurationById('graph_endpoint') ?? ''; 
    this.http.get(graph_endpoint)
      .subscribe((profile: any) => {
        this.profile = profile;
      });
  }
  getClaims(claims: any) {
    const dataSource = [
      { id: 1, claim: "Display Name", value: claims ? claims['name'] : null },
      { id: 2, claim: "User Principal Name (UPN)", value: claims ? claims['preferred_username'] : null },
      { id: 2, claim: "OID", value: claims ? claims['oid'] : null }
    ];
    let sessionId = uuidv4();
    localStorage.setItem(CommonConstants.LS_SESSION,sessionId);
    localStorage.setItem(CommonConstants.LS_USERNAME, dataSource[1].value);
    localStorage.setItem(CommonConstants.LS_NAME, dataSource[0].value);
    if(claims != undefined){
      this.logging.trackEvent('User Authentication : Login Successfull',this.logging.createCustomLog('Login Successfull','User loggedIn Sucessfully','INFO'));
    }

  }


}
