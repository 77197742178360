<div class="modal-body">
    <div class="add-care-member">
        <div class="row">
            <div class="mb-3" [ngClass]="typeId==244 ? 'col-4' : 'col-6'">
                <label for="careMemberList" class="mb-1">Care Team Member Type</label>
                <select class="form-select" (change)="onChangeCareMember()"
                        [(ngModel)]="typeId"
                        id="stage">
                    <option value="0">--Select Care Member Type--</option>
                    <option *ngFor="let item of careTeamMemberList" [value]="item.value">
                        {{ item.name }}
                    </option>
                </select>
            </div>
            <div *ngIf="typeId==244" class="col-4 mb-3">
                <label class="mb-1">Search by</label>
                <div class="switch-button">
                    <input class="switch-button-checkbox" type="checkbox" (change)="onProximityChange()"/>
                    <label class="switch-button-label" for=""><span  class="switch-button-label-span">Proximity</span></label>
                </div>
                


            </div>

            <div class="mb-3" *ngIf="!isProximity" [ngClass]="typeId==244 ? 'col-4' : 'col-6'">
                <label for="care-team-member-type" class="mb-1">Search Care Member</label>
                <input type="text"
                       name="care-team-member-type"
                       placeholder="Search Care Team Member"
                       id="care-team-member-type"
                       [(ngModel)]="typedText"
                       class="form-control" />
            </div>
            <div *ngIf="isProximity && typeId==244" class="col-4 mb-3 d-flex align-items-end">
                <button (click)="searchProximity()" class="btn btn-primary w-100">
                    <i class="fas fa-search me-1"></i> Search Proximity
                </button>
            </div>
        </div>
        
        <hr class="md-2">

        <div id="care-members-results-panel" >
            <div *ngIf="!careMembers" class="text-center">Please type three or more characters to search!</div>
            <div *ngIf="careMembers && careMembers.length == 0" class="alert alert-success text-center">No matching Care Member found!</div>
            <div *ngIf="careMembers && careMembers.length > 0" class="border rounded px-3 py-2 mb-2 bg-light">
                <div class="row g-2">
                    <div class="col-5 col-lg-5">
                        <strong class="d-block">Care Member</strong>
                    </div>
                    <div *ngIf="(isProximity && typeId==244)" class="col-3 col-lg-2 d-flex align-items-center justify-content-center">
                        <strong class="d-block">Distance</strong>
                    </div>
                    <div [ngClass]="isProximity && typeId==244 ? 'justify-content-center' : 'justify-content-end'" class="col-4 col-lg-5 d-flex align-items-center">
                        <strong [ngClass]="isProximity && typeId==244 ? 'custom-margin' : ''" class="d-block">Type</strong>
                    </div>
                </div>
            </div>
            <div class="results-panel">
                <div *ngFor="let careMember of careMembers; let row = index;"
                     class="border rounded px-3 py-2 mb-2"
                     [ngClass]="{'care-member-result-selected': careMember == selectedCareMember}"
                     (click)="selectedCareMember = careMember"
                     (keydown)="handleKeyDown($event)"
                     (keyup)="handleKeyUp($event)"
                     (keypress)="handleKeyPress($event)">


                    <div class="row g-2">
                        <div class="col-5 col-lg-5">
                            <strong class="d-block">{{careMember.name}}</strong>
                            <span class="fs-12">{{careMember.emailId}}</span>
                        </div>
                        <div class="col-3 col-lg-2 d-flex align-items-center justify-content-center">
                            <div *ngIf="isProximity && typeId==244">
                                <strong class="d-block">{{careMember.distance}} Miles</strong>
                            </div>
                        </div>
                        <div class="col-4 col-lg-5 d-flex align-items-center justify-content-end">
                            <div class="fs-12 text-lightest">
                                {{careMember.type}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
