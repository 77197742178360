import { Component } from '@angular/core';

@Component({
  selector: 'hs-appointment-wizard',
  templateUrl: './appointment-wizard.component.html',
  styleUrls: ['./appointment-wizard.component.scss']
})
export class AppointmentWizardComponent {

}
