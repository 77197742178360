<div class="modal-body">
    <div class="scroll-modal-content">
        <table class="table table-bordered m-0" id="contactDetails">
            <thead>
                <tr>
                    <th scope="col" width="28">
                    </th>
                    <th scope="col">Contact Name</th>
                    <th scope="col">Contact Type</th>
                    <th scope="col">Phone Type</th>
                    <th scope="col">Contact Number</th>
                    <th scope="col">Status</th>
                    <th scope="col" class="text-center" width="90">Is Primary</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of patientContacts;">
                    <th scope="row">
                        <input class="form-check-input" type="radio" 
                            name="{{item.patientContactTypeName}}_contactType" 
                            [checked]="selectContactId == item.patientContactId"
                            [value]="item.patientContactId" [(ngModel)]="selectContactId" />
                    </th>
                    <td>{{item.name}}</td>
                    <td>{{item.phoneType}}</td>
                    <td>{{item.patientContactTypeName}}</td>
                    <td>{{item.preferredContact}}</td>
                    <td>{{item.status}}</td>
                    <td class="text-center">
                        <i *ngIf="item.isPrimary" class="fas fa-check text-success"></i>
                    </td>
                </tr>
               
            </tbody>
        </table>
        </div>
        <div class="d-flex fst-italic text-lightest mt-3">
            <div class="me-1"><i class="fas fa-hand-point-right"></i></div>
            <div>
                To add a new phone number, Go to Renal IQ Care > Patient Details > Providers and Contacts
            </div>
        </div>
</div>


