import { Component } from "@angular/core";
import { PatientContactInfo } from "../../api-client/model/patientContactInfo";
import { GenericModal } from "../../commons-ui/generic-modal/generic-modal";
import { GenericModalButtonInfo } from "../../commons-ui/generic-modal/generic-modal-service";

@Component({
    selector: 'patient-contact-list',
    templateUrl: './patient-contact-list.component.html',
    styleUrls: ['./patient-contact-list.component.scss']
})

export class PatientContactListComponent implements GenericModal<PatientContactListInput, PatientContactInfo> {

    private outputHandler?: (contact: PatientContactInfo) => void;

    public patienId: number = 0;
    public patientContacts: PatientContactInfo [] = [];
    public selectContactId?: number;

    constructor() { }

    setOutputDataHandler(handler: (data: PatientContactInfo) => void): void {
        this.outputHandler = handler;
    }

    getTitle(): string {
        return "Providers and Contacts";
    }

    getSubTitle(): string {
        return "";
    }

    setInputData(data: PatientContactListInput): void {
        this.patienId = data.patientId;
        this.selectContactId = data.selectedContact?.patientContactId ?? this.selectContactId;
        this.patientContacts = data.patientContacts;
    }

    getLeftButtons(): GenericModalButtonInfo[] {
        return [
            {
                label: "Cancel",
                cssClasses: "btn btn-outline-dark rounded-pill px-4",
                action: async () => true,
            },
        ];
    }

    getRightButtons(): GenericModalButtonInfo[] {
        return [
            {
                label: "Update Contact",
                cssClasses: "btn btn-dark rounded-pill px-4",
                action: async () => {
                    const selectedInfo: PatientContactInfo[] = this.patientContacts.filter((item) => {
                        return item.patientContactId === this.selectContactId;
                    })
                    this.outputHandler?.(selectedInfo[0]);
                    return true;
                }
            },
        ];
    }

    public async onCloseClicked(): Promise<boolean> {
        return true;
    }
}

export interface PatientContactListInput {
    patientId: number;
    selectedContact?: PatientContactInfo;
    patientContacts: PatientContactInfo [];
}
