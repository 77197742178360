import { Pipe, PipeTransform } from '@angular/core';
import { HsappointmentModeMasterModel } from 'src/app/api-client';
import { ReferenceDataProvider } from '../reference-data-provider';

@Pipe({
    name: 'apptMode',
    pure: false
})
export class ApptModePipe implements PipeTransform {

  constructor(private referenceDataProvider: ReferenceDataProvider){}

  transform(value?: string, ...args: any[]): any {
    if(!value) {
      return "";
    }
    
    const modes: HsappointmentModeMasterModel[] = this.referenceDataProvider.apptModes;
    if(!modes || modes.length == 0) {
      return value;
    }

    for (let index = 0; index < modes.length; index++) {
      if(modes[index].modeId == value) {
        return modes[index].mode;
      }
    }
    
    return value;
  }
}
