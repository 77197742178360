import { Component, OnInit } from '@angular/core';
import { CommonConstants } from '../commons/common-constants';
import { ReferenceDataService } from '../api-client';

@Component({
  selector: 'hs-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  constructor(private referenceDataService: ReferenceDataService) { 
    
  }

  public uiVersion: string = CommonConstants.uiVersion;
  public apiVersion: string = "-";

    ngOnInit(): void {
        let that = this;
        setTimeout(function () {
            that.referenceDataService.referenceDataGetVersion().subscribe((version: string) => that.apiVersion = version);
        },1000)
  }

}
