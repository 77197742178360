export * from './appointment.service';
import { AppointmentService } from './appointment.service';
export * from './bestTime.service';
import { BestTimeService } from './bestTime.service';
export * from './calendar.service';
import { CalendarService } from './calendar.service';
export * from './careTeam.service';
import { CareTeamService } from './careTeam.service';
export * from './hSCalendar.service';
import { HSCalendarService } from './hSCalendar.service';
export * from './mobile.service';
import { MobileService } from './mobile.service';
export * from './patient.service';
import { PatientService } from './patient.service';
export * from './rCIntegration.service';
import { RCIntegrationService } from './rCIntegration.service';
export * from './rCTask.service';
import { RCTaskService } from './rCTask.service';
export * from './referenceData.service';
import { ReferenceDataService } from './referenceData.service';
export * from './user.service';
import { UserService } from './user.service';
export const APIS = [AppointmentService, BestTimeService, CalendarService, CareTeamService, HSCalendarService, MobileService, PatientService, RCIntegrationService, RCTaskService, ReferenceDataService, UserService];
