<div [class]="containerClass">
    <label for="startingTime" class="form-label" [class]="labelClass">{{labelText}}</label>
    <div class="btn-group time-dropdown">
        <button type="button" class="btn btn-default dropdown-toggle" 
            data-toggle="dropdown" aria-haspopup="true" 
            aria-expanded="false"
            (click)=" openDropdown = !openDropdown">
          {{selectedLabel}}
        </button>
        <div class="dropdown-menu" [class.show]="openDropdown">
          <a class="dropdown-item" href="#"
            (click)="selectTime($event, dropdownValue)"
            *ngFor="let dropdownValue of dropdownValues" >{{dropdownValue.label}}</a>
        </div>
    </div>
</div>
