<ul class="tabs">
    <li class="tabs__list" role="presentation">
      <div class="tabs__item active">Appointment History</div>
    </li>
</ul>
<div id="appointments-panel">
    <div class="row d-none d-lg-flex">
        <div class="col-2">
            <strong>Type</strong>
        </div>
        <div class="col-2">
            <strong class="text-nowrap">Appointment With</strong>
        </div>
        <div class="col-3">
            <strong>Date/Time</strong>
        </div>
        <div class="col-5">
            <strong>Address</strong>
        </div>
    </div>
    
    
</div>