import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppointmentDetailsComponent } from './appointments/appointment-details/appointment-details.component';
import { AppointmentsHistoryComponent } from './appointments/appointments-history/appointments-history.component';
import { AppointmentsComponent } from './appointments/appointments.component';
import { HomeComponent } from './home/home.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { ScheduleComponent } from './schedule/schedule.component';
import { CareMemberCalendarComponent } from './appointments/care-member-calendar/care-member-calendar.component';
import { AuthGuard } from './auth/auth-guard';

export const routes: Routes = [
  { path: '', component: HomeComponent, },
  { path: 'schedule/:patientId', component: ScheduleComponent, canActivate: [AuthGuard] },
  { path: 'schedule/:patientId/:apptId', component: ScheduleComponent, canActivate: [AuthGuard] },
  { path: 'appointments', component: AppointmentsComponent, canActivate: [AuthGuard] },
  { path: 'appointments/history', component: AppointmentsHistoryComponent, canActivate: [AuthGuard] },
  { path: 'appointments/:id', component: AppointmentDetailsComponent, canActivate: [AuthGuard] },
  { path: 'calendar', component: CareMemberCalendarComponent, canActivate: [AuthGuard] },
  { path: '**', component: NotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
    