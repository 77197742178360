import { Component, Input } from '@angular/core';
import { filter } from 'rxjs/operators';
import { CentralNurseScheduleResponse } from 'src/app/api-client/model/centralNurseScheduleResponse';

@Component({
  selector: 'hs-central-nurse-availability-ui',
  templateUrl: './central-nurse-availability-ui.component.html',
  styleUrls: ['./central-nurse-availability-ui.component.scss']
})
export class CentralNurseAvailabilityUiComponent {
  @Input()
  public set careMember(slots: any) {
    if (slots) {
        this.centralNurseSlot = slots;
    }
  }
  @Input() times: string[] = [];
    public centralNurseSlot: CentralNurseScheduleResponse = { careMemberId: 0, slot1: false, slot2: false, slot3: false, startDateTime: new Date() };
  constructor() { }

}
