import { Injectable, Type } from "@angular/core";
import { GenericModal } from "./generic-modal";
import { GenericModalHandler } from "./generic-modal-handler";

declare const $: any;

@Injectable()
export class GenericModalService {

    private genericModalHandler?: GenericModalHandler;

    public showModel<I, O>(dialogComponent: Type<GenericModal<I, O>>, inputData?: I, handler?: (data: O) => void): void {
        this.genericModalHandler?.showModel(dialogComponent, inputData, handler);
    }

    public setHandler(handler: GenericModalHandler): void {
        this.genericModalHandler = handler;
    }

    public hideModel(): void {
      $("#global-generic-modal").modal('hide');
    }
}

export interface GenericModalButtonInfo {
  label: string,
  cssClasses?: string;
  action?(): Promise<boolean>,
}
