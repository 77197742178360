
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { HomeModule } from './home/home.module';
import { ScheduleModule } from './schedule/schedule.module';
import { AppointmentsModule } from './appointments/appointments.module';
import { NotFoundComponent } from './not-found/not-found.component';
import { CommonsUiModule } from './commons-ui/commons-ui.module';
import { ApiModule } from './api-client';
import { BASE_PATH } from './api-client/variables';
import { environment } from 'src/environments/environment';
import { AuthModule } from './auth/auth.module';
import { RequestHeadersInterceptor } from './api/request-headers-interceptor';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserCacheLocation, IPublicClientApplication, InteractionType, LogLevel, PublicClientApplication } from '@azure/msal-browser';
import { ToastrModule } from 'ngx-toastr';
import { CommonsModule } from './commons/commons.module';
import { DatePipe } from '@angular/common';
import { SafeUrlPipe } from './commons/pipes/safe.pipe';
import { MsalGuard, MsalBroadcastService, MsalInterceptorConfiguration, MsalModule, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalGuardConfiguration, MsalRedirectComponent } from '@azure/msal-angular';
import { AuthGuard } from './auth/auth-guard';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ApplicationinsightsAngularpluginErrorService } from '@microsoft/applicationinsights-angularplugin-js';
import { MbscModule } from '@mobiscroll/angular';
import { AzureAppConfiguration } from '@azure/app-configuration-provider';
import { AzureAppConfigurationService } from './azure-app-configuration.service';

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;


export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.authClientID,
      authority: environment.authAuthority,
      postLogoutRedirectUri: '/'
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage
    },
    system: {
      allowNativeBroker: false, // Disables WAM Broker
      loggerOptions: {
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false
      }
    }
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set(environment.baseApiUrl, ['user.read']);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return { 
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: ['user.read']
    },
    loginFailedRoute: '/login-failed'
  };
}

export function appInsightsFactory() {
  return new ApplicationInsights({
    config: {
      instrumentationKey: environment.connectionString,
    }
  });
}
// export function initializeApp(appConfigService: AzureAppConfigurationService) {
//   return () => appConfigService.loadAppConfig();
// }

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    NotFoundComponent,
  ],
  imports: [ 
    MbscModule,
    FormsModule,  
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({}),
    AuthModule,
    ApiModule,
    MsalModule,
    CommonsUiModule,
    HomeModule,
    ScheduleModule,
    AppointmentsModule,    
    CommonsModule,
    ReactiveFormsModule,
  ],
  providers: [
    AuthGuard,
    SafeUrlPipe,
    DatePipe,
    DatePipe,
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: initializeApp,
    //   deps: [AzureAppConfigurationService],
    //   multi: true,
    // },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestHeadersInterceptor,
      multi: true,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    {provide: BASE_PATH, useValue: environment.baseApiUrl },
    // {provide: LocationStrategy, useClass: HashLocationStrategy},
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    {
      provide: ErrorHandler,
      useClass: ApplicationinsightsAngularpluginErrorService
    }
  
    
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule { }
