import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { PatientModel, RCTaskService } from 'src/app/api-client';
import { RCTask } from 'src/app/api-client/model/rCTask';
import { Loader } from 'src/app/commons-ui/loader';
import { InputTaskModel } from '../../api-client';
import { SelectedTask } from './selected-task';
import { LoggingServiceTsService } from 'src/app/store/loggingService/logging.service';

export const taskStatusList: TaskStatus[] = [
    {
        key: 104,
        value: 'Open',
    },
    {
        key: 4367,
        value: 'No Longer Applicable',
    },
    {
        key: 105,
        value: 'Closed - Successful',
    },
    {
        key: 249,
        value: 'Closed - Unsuccessful',
    },
    {
        key: -140,
        value: 'Void - Created By Mistake',
    }
];

export type TaskStatus = {
  key: number;
  value: string;
}

@Component({
  selector: 'hs-task-list',
  templateUrl: './task-list.component.html',
  styleUrls: ['./task-list.component.scss']
})
export class TaskListComponent implements OnInit {

  @Output()
  public onChange: EventEmitter<SelectedTask[]> = new EventEmitter();

  public patientModel?: PatientModel;

  public tasks?: RCTask[];

  public allTasksSelected: boolean = false;
  public selectedTaskIds: Set<number> = new Set();
  public taskStatusLust: TaskStatus[] = taskStatusList;
  public taskStatusForm: UntypedFormGroup;
  private updatingTaskIndex: number = 0;

  constructor(private taskService: RCTaskService,
              public fb: UntypedFormBuilder,
              private logger: LoggingServiceTsService) {
    this.taskStatusForm = this.fb.group({
      statusId: [taskStatusList[0].key],
      comment: [''],
      task: [''],
      description: ['']
    })
  }

  ngOnInit(): void {
    this.logger.logEventForComponentRendering('OutStanding Scheduling Task')
  }

  private async loadTasks(): Promise<void> {
    Loader.showLoader("tasks-panel");
    try {
      this.tasks = await this.taskService.rCTaskGetOpenTaskByPatientId(this.patientModel?.id!).toPromise();
    } finally {
      Loader.hideLoader("tasks-panel");
    }
  }

  @Input()
  public set patient(patient: PatientModel) {
      this.patientModel = patient;
      if(this.patientModel?.id) {
          this.loadTasks();
      }
  }

  public toggleTask(taskId: number): void {
    this.logger.trackEvent('Click On CheckBox Outstanding Task',this.logger.createCustomLog('Clicked on CheckBox', 'Selected A Single Task', 'INFO'));
    if(this.selectedTaskIds.has(taskId)) {
      this.selectedTaskIds.delete(taskId);
    } else {
      this.selectedTaskIds.add(taskId);
    }

    this.allTasksSelected = this.selectedTaskIds.size == this?.tasks?.length;

    this.publishChanges();
  }

  public toggleAllTask(): void {
    this.logger.trackEvent('Click On CheckBox Outstanding Task',this.logger.createCustomLog('Clicked on CheckBox', 'Selected all Tasks', 'INFO'));
    if(this.allTasksSelected) {
      this.allTasksSelected = false;
      this.selectedTaskIds.clear();
    } else {
      this.allTasksSelected = true;
      this.tasks?.forEach(task => this.selectedTaskIds.add(task.taskId));
    }

    this.publishChanges();
  }

  private publishChanges(): void {
    let that = this;
    let taskIds: SelectedTask[] = [];
      this.selectedTaskIds.forEach(function (taskId) {
          let task: RCTask[] = [];
          if (that.tasks) {
              task = that.tasks.filter(x => x.taskId == taskId);
              taskIds.push({ task: task[0], statusId: task[0].statusId, comment: task[0].description });
          }
    });
 
    this.onChange.next(taskIds);
  }
  public openUpdateTaskModal(task: RCTask, index: number): void{
    this.logger.logEventForComponentRendering('User Click on Edit Button to Edit OutStanding Task')
    this.updatingTaskIndex = index;
    this.taskStatusForm.setValue({statusId: task.statusId, comment: task.comment? task.comment: '', task: task.task, description: task.description});
  }
  submitTaskStatus(): void{
    if (this.tasks) {
      this.tasks[this.updatingTaskIndex] = {
        ... this.tasks[this.updatingTaskIndex],
        ...this.taskStatusForm.value
      }
      this.publishChanges();
      this.logger.trackEvent('Click On Save Button ',this.logger.createCustomLog('Clicked on Save Button to save Changes', 'Successfully Saved Changes', 'INFO'));
    }
  }
}
