/**
 * Somatus Hybrid Scheduler API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { AppointmentDetail } from '../model/appointmentDetail';
import { AppointmentResponseModel } from '../model/appointmentResponseModel';
import { AppointmentsListResponse } from '../model/appointmentsListResponse';
import { CancelAppointmentRequestModel } from '../model/cancelAppointmentRequestModel';
import { CreateAppointmentRequest } from '../model/createAppointmentRequest';
import { TodayAppointmentsResponse } from '../model/todayAppointmentsResponse';
import { ValueTupleOfStringAndLong } from '../model/valueTupleOfStringAndLong';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class AppointmentService {

    protected basePath = 'http://localhost:5001/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public appointmentCancelAppointment(body: CancelAppointmentRequestModel, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public appointmentCancelAppointment(body: CancelAppointmentRequestModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public appointmentCancelAppointment(body: CancelAppointmentRequestModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public appointmentCancelAppointment(body: CancelAppointmentRequestModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling appointmentCancelAppointment.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<boolean>('post',`${this.basePath}/api/Appointment/cancel`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public appointmentCreateAppointments(body: CreateAppointmentRequest, observe?: 'body', reportProgress?: boolean): Observable<AppointmentResponseModel>;
    public appointmentCreateAppointments(body: CreateAppointmentRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AppointmentResponseModel>>;
    public appointmentCreateAppointments(body: CreateAppointmentRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AppointmentResponseModel>>;
    public appointmentCreateAppointments(body: CreateAppointmentRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling appointmentCreateAppointments.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<AppointmentResponseModel>('post',`${this.basePath}/api/Appointment`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param isTestTelehealth 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public appointmentDraftAppointments(body: CreateAppointmentRequest, isTestTelehealth?: boolean, observe?: 'body', reportProgress?: boolean): Observable<ValueTupleOfStringAndLong>;
    public appointmentDraftAppointments(body: CreateAppointmentRequest, isTestTelehealth?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ValueTupleOfStringAndLong>>;
    public appointmentDraftAppointments(body: CreateAppointmentRequest, isTestTelehealth?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ValueTupleOfStringAndLong>>;
    public appointmentDraftAppointments(body: CreateAppointmentRequest, isTestTelehealth?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling appointmentDraftAppointments.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (isTestTelehealth !== undefined && isTestTelehealth !== null) {
            queryParameters = queryParameters.set('isTestTelehealth', <any>isTestTelehealth);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<ValueTupleOfStringAndLong>('post',`${this.basePath}/api/Appointment/draft`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public appointmentGetAppointmentDetail(id: number, observe?: 'body', reportProgress?: boolean): Observable<AppointmentDetail>;
    public appointmentGetAppointmentDetail(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AppointmentDetail>>;
    public appointmentGetAppointmentDetail(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AppointmentDetail>>;
    public appointmentGetAppointmentDetail(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling appointmentGetAppointmentDetail.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<AppointmentDetail>('get',`${this.basePath}/api/Appointment/detail/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param status 
     * @param text 
     * @param pageNumber 
     * @param pageSize 
     * @param startDate 
     * @param endDate 
     * @param createdByMe 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public appointmentGetAppointments(status?: string, text?: string, pageNumber?: number, pageSize?: number, startDate?: Date, endDate?: Date, createdByMe?: boolean, observe?: 'body', reportProgress?: boolean): Observable<AppointmentsListResponse>;
    public appointmentGetAppointments(status?: string, text?: string, pageNumber?: number, pageSize?: number, startDate?: Date, endDate?: Date, createdByMe?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AppointmentsListResponse>>;
    public appointmentGetAppointments(status?: string, text?: string, pageNumber?: number, pageSize?: number, startDate?: Date, endDate?: Date, createdByMe?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AppointmentsListResponse>>;
    public appointmentGetAppointments(status?: string, text?: string, pageNumber?: number, pageSize?: number, startDate?: Date, endDate?: Date, createdByMe?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {








        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (status !== undefined && status !== null) {
            queryParameters = queryParameters.set('status', <any>status);
        }
        if (text !== undefined && text !== null) {
            queryParameters = queryParameters.set('text', <any>text);
        }
        if (pageNumber !== undefined && pageNumber !== null) {
            queryParameters = queryParameters.set('pageNumber', <any>pageNumber);
        }
        if (pageSize !== undefined && pageSize !== null) {
            queryParameters = queryParameters.set('pageSize', <any>pageSize);
        }
        if (startDate !== undefined && startDate !== null) {
            queryParameters = queryParameters.set('startDate', <any>startDate.toISOString());
        }
        if (endDate !== undefined && endDate !== null) {
            queryParameters = queryParameters.set('endDate', <any>endDate.toISOString());
        }
        if (createdByMe !== undefined && createdByMe !== null) {
            queryParameters = queryParameters.set('createdByMe', <any>createdByMe);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<AppointmentsListResponse>('get',`${this.basePath}/api/Appointment`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public appointmentGetTodayAppointments(observe?: 'body', reportProgress?: boolean): Observable<TodayAppointmentsResponse>;
    public appointmentGetTodayAppointments(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TodayAppointmentsResponse>>;
    public appointmentGetTodayAppointments(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TodayAppointmentsResponse>>;
    public appointmentGetTodayAppointments(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<TodayAppointmentsResponse>('get',`${this.basePath}/api/Appointment/today`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public appointmentUpdateAppointments(body: CreateAppointmentRequest, observe?: 'body', reportProgress?: boolean): Observable<AppointmentResponseModel>;
    public appointmentUpdateAppointments(body: CreateAppointmentRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AppointmentResponseModel>>;
    public appointmentUpdateAppointments(body: CreateAppointmentRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AppointmentResponseModel>>;
    public appointmentUpdateAppointments(body: CreateAppointmentRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling appointmentUpdateAppointments.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<AppointmentResponseModel>('put',`${this.basePath}/api/Appointment`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
