import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'initial'
})
export class InitialPipe implements PipeTransform {

  transform(value?: string, ...args: any[]): any {
    if(!value) {
      return "";
    }
    
    const parts = value.split(/\s/);

    let initial: string = parts.map(part => part.substr(0, 1).toUpperCase()).join("");

    if(initial.length > 2) {
        initial = initial.substr(0, 2);
    }
    
    return initial;
  }
}
