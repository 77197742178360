import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DateTime } from 'src/app/commons/date-time';

@Component({
  selector: 'hs-time-field',
  templateUrl: './time-field.component.html',
  styleUrls: ['./time-field.component.scss']
})
export class TimeFieldComponent implements OnInit {
  @Input() containerClass?: string = 'select-group';
  @Input() labelClass?: string = 'm-0';
  @Input() selectClass?: string = '';
  @Input() labelText?: string = 'Starting Time';
  @Input() dropdownDisabled?: boolean = false;
  private readonly minsStep: number = 15;

  @Output()
  public onChange: EventEmitter<Time | null> = new EventEmitter<Time | null>();

  public minTime: Time = {hours: 0, minutes: 0};
  public maxTime: Time = {hours: 24, minutes: 0};

  public dropdownValues: DropdownValue[] = [];

  private selectedMinutes: number | null = null;

  constructor() { }

  ngOnInit(): void {
    this.createDropdownValues();
  }

  private createDropdownValues(): void {
    let minMinutes: number = this.minTime.hours * 60;
    let maxMinutes: number = this.maxTime.hours * 60 + this.maxTime.minutes;

    this.dropdownValues = [];
    for (let min = minMinutes; min < maxMinutes; min += this.minsStep) {
      let hours: number = Math.floor(min / 60);
      let minutes: number = min % 60;

      let amPm: string = "am";
      if(hours > 11) {
        amPm = "pm";
        hours = hours - 12;
        if(hours == 0) {
          hours = 12;
        }
      }

      this.dropdownValues.push({
        minutes: min,
        label: (hours < 10 ? ("0" + hours) : hours) + ":" + (minutes < 10 ? ("0" + minutes) : minutes) + " " + amPm,
      });
    }
  }

  public get selection(): number | null {
    return this.selectedMinutes!;
  }

  @Input()
  public set selection(time: number | null) {
    this.selectedMinutes = time;
    this.onChange.emit(Time.from(this.selectedMinutes!));
  }

  @Input()
  public set time(time: Time | null) {
    this.selectedMinutes = time ? (time.hours * 60 + time.minutes) : null;
  }
}

export class Time {
  constructor(public hours: number, public minutes: number){}

  public static from(minutesOfTheDay: number): Time {
    let hours: number = Math.floor(minutesOfTheDay / 60);
    let minutes: number = minutesOfTheDay % 60;
    return {hours: hours, minutes: minutes};
  }
}

interface DropdownValue {
  minutes: number;
  label: string;
}
