import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { AppointmentInfo } from 'src/app/api-client';
import { GenericModal } from 'src/app/commons-ui/generic-modal/generic-modal';
import { GenericModalButtonInfo, GenericModalService } from 'src/app/commons-ui/generic-modal/generic-modal-service';

@Component({
  selector: 'hs-outstanding-appointment-popup',
  templateUrl: './outstanding-appointment-popup.component.html',
  styleUrls: ['./outstanding-appointment-popup.component.scss']
})
export class OutstandingAppointmentPopupComponent implements OnInit, GenericModal<number, void> {
  upcomingAppointments: any = [];
  constructor(public genericModalService: GenericModalService,public datePipe: DatePipe) { }

  ngOnInit(): void {
  }

  getTitle(): string {
    return "Upcoming Appointment(s)";
  }

  getLeftButtons(): GenericModalButtonInfo[] {
    return [];
  }

  getRightButtons(): GenericModalButtonInfo[] {
    return [
      {
        label: "Close",
        cssClasses: "btn btn-dark rounded-pill",
        action: () => Promise.resolve(true),
      }
    ];
  }

  getSubTitle(): string {
    return "";
  }

  setInputData(data: any): void {
    this.upcomingAppointments = data;
  }

  setOutputDataHandler(handler: (data: void) => void): void {
  }

  onCloseClicked(): Promise<boolean> {
    return Promise.resolve(true);
  }

  public outstandingAppointmentScheduledTime(appointment: AppointmentInfo): string | null {
    const timezone = appointment?.scheduleTimeZone ? appointment.scheduleTimeZone : 'US/Eastern';
    const startDate = new Date(new Date(new Date(appointment.scheduleDateTime + "Z").getTime() + appointment.schedulePreTime * 60000).toLocaleString('en-US', { timeZone: timezone }));
    const startTime = startDate ? this.datePipe.transform(startDate, 'shortTime') : '';
    return startTime
}

public outstandingAppointmentScheduledDate(appointment: AppointmentInfo): string | null {
  const timezone = appointment?.scheduleTimeZone ? appointment.scheduleTimeZone : 'US/Eastern';
    const startDate = new Date(new Date(appointment.scheduleDateTime + "Z").toLocaleString('en-US', { timeZone: timezone }));
    return startDate ? this.datePipe.transform(startDate, 'MM/dd/yyyy') : '';
}
}
