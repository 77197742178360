/**
 * Somatus Hybrid Scheduler API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface HscareMemberSchedule { 
    schedulerId: number;
    careMemberId?: number;
    appointmentId?: number;
    modeId?: string;
    meetingId?: string;
    scheduleStartDateTime?: Date;
    scheduleEndDateTime?: Date;
    calcScheduleStartDateTime: Date;
    calcScheduleEndDateTime: Date;
    status?: string;
    createdBy?: number;
    createdOn?: Date;
    updatedBy?: number;
    updatedOn?: Date;
    typeId?: number;
    schedulePreTime?: number;
    scheduleDuration?: number;
    schedulePostTime?: number;
    preferredLanguage?: string;
    iCalId?: string;
    iCallReferenceId?: string;
}