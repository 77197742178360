import { CareMemberSlotRequest } from './../api-client/model/careMemberSlotRequest';
import { AppointmentWizardMode } from './../api-client/model/appointmentWizardMode';
import { AppointmentWizardType } from './../api-client/model/appointmentWizardType';
import { DatePipe } from '@angular/common';
import { Component, EventEmitter, HostListener, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { AppointmentDetail, AppointmentMode, AppointmentService, AppointmentType, CareMemberInfo, CareTeamService, CreateAppointmentRequest, HsappointmentModeMasterModel, HstimeZoneMasterModel, PatientAppointmentService, PatientModel, ValueTupleOfStringAndLong, } from '../api-client';
import { PatientService } from '../api-client/api/patient.service';
import { PatientContactInfo } from '../api-client/model/patientContactInfo';
import { GenericModalService } from '../commons-ui/generic-modal/generic-modal-service';
import { Loader } from '../commons-ui/loader';
import { PatientContactListComponent, PatientContactListInput } from './patient-contact-list/patient-contact-list.component';
import { SelectedTask } from './task-list/selected-task';
import { Subject } from 'rxjs';
import { PatientAppointmentsComponent } from './patient-appointments/patient-appointments.component';
import { ScheduleService } from './services/schedule.service';
import { OutstandingAppointmentPopupComponent } from './outstanding-appointment-popup/outstanding-appointment-popup.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ReferenceDataProvider } from '../commons/reference-data-provider';
import { CalendarEvent } from './schedule-calendar/calendar-event';
import { AvaiableSlotRequestModel } from '../api-client/model/avaiableSlotRequestModel';
import { AvailableSlots } from '../api-client/model/availableSlots';
import { FilterCareNursePipe } from './pipes/filter-care-nurse.pipe';
import { AppointmentWizardService } from '../api-client/api/appointmentWizard.service';
import { StaffTypes } from '../api-client/model/staffTypes';
import { LoggingServiceTsService } from '../store/loggingService/logging.service';

declare const $: any; 
type AppointmentWizardPair = [number, AppointmentWizardType];
var days = ['Sun','Mon','Tues','Wed','Thurs','Fri','Sat']
@Component({
  selector: 'hs-schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.scss'],
  providers: [FilterCareNursePipe]
})
export class ScheduleComponent implements OnInit {
    private patientId: number = 0;
    public appointmentId: number = 0;
 
    public appointment?: AppointmentDetail;
 
    public patient?: PatientModel;
    public patientPriority: string = "";
    public practiceName: string = "";
    public selectedPatientContact?: PatientContactInfo;
    public patientContacts: PatientContactInfo[] = [];
 
    public address?: string;
    public addressType?: string;
    public selectedTask: SelectedTask[] = [];
    public lat: number = 0;
    public lng: number = 0;
    public latLong = new Subject<{ lat: number, lng: number, address: string }>();
 
    public pageLoadingFailed: boolean = false;
    public centralCareNurse: CareMemberInfo[] = [];
    public appointmentTypes: AppointmentWizardType[] = [];
    public mapAppointmentTypeId : AppointmentWizardPair[] = [];
    public appointmentModes: AppointmentWizardMode[] = [];
    public centralNurseList: CareMemberInfo[] = [];
    public careMembers?: CareMemberInfo[];
    public careMemberList: CareMemberSlotRequest[] = [];
    public careMemberSlots : AvailableSlots[] = [];
    public careTeamMemberList : any;
    public apptData: CalendarEvent = <CalendarEvent>{};
    public staffTypes: StaffTypes[] = [];
    public stateId?:string = " ";
    public name:string=" ";
    public _typedText: string = "";
    private timeoutRef: any;
    public typeId: number = 0;
    public isDisable: boolean = true;
    public showResults: boolean = true;
    public flag: boolean = false;
    public careMember?: CareMemberInfo;
    public staffTypeId : number = 0;
    public appointmentType? : AppointmentType;
    private selectedTimezoneName: string = 'Eastern Time'
    public selectedTimezone?: string = 'US/Eastern';
    public resultLength = 0;
    private timezones : HstimeZoneMasterModel[] = [];
    private apptModes: HsappointmentModeMasterModel[] = [];
    public noResult: boolean = false;
    public isCarememberNameValid: boolean = true;
    public selectedCareMemberName: string = '';
    public disableSearch: boolean = false;
    public noCareMemberFound: boolean = false;
    public isTerminated : boolean = false;
    public maxDaysLimitExceed = false;

    searchAppointmentForm: FormGroup=this.formBuilder.group({
        selectDateSearching: [new Date(), Validators.required],
        IdTaskType: ['', Validators.required],
        IdAppointmentType: ['', Validators.required],
        IdDuration: ['', Validators.required],
        IdStaffType: ['', Validators.required],
        careMemberName: [''],
    })
 
    constructor(
        private referenceDataProvider: ReferenceDataProvider,
        private formBuilder: FormBuilder,
        private patientService: PatientService,
        private careTeamService: CareTeamService,
        private route: ActivatedRoute,
        private genericModalService: GenericModalService,
        private appointmentService: AppointmentService,
        private datePipe: DatePipe,
        public scheduleService: ScheduleService,
        private apptWizardSerice : AppointmentWizardService,
        private logging: LoggingServiceTsService,
        private readonly patientAppointmentService : PatientAppointmentService) { }
        public patientContactInfo : PatientContactInfo[] = [];
        public contactInfo : PatientContactInfo = {
            patientContactId: 0,
            patienId: 0,
            contactTypeId: 0,
            isPrimary: false,
            isCallOptOut: false,
            isTextOptOut: false
        };
        public durations: number[] = [30,60,90];
        public draftAppointmentId : number = 0;
        public meetingLink : string = '';
        public isActive : boolean = false;
        public Today: Date= new Date();
        public isPrevDisable : boolean = true;
        public isNextDisable : boolean = true;
        public isSearchEnable : boolean = true;
        public isResetDisabled : boolean = true;
        public isSearched : boolean = false;
        public isPreviousSearch : boolean = false;
        private modeId : string  = '';
        public addOrUpdate : string = 'Add';
        public snpDefaultAssign : boolean = false;

    async ngOnInit(): Promise<void> {
        this.route.params
        .subscribe(
            async (params: Params) => {
                this.patientId = +params['patientId'];
                this.appointmentId = +params['apptId'];
                await this.loadPatient(this.patientId);
                if (this.appointmentId){
                    this.addOrUpdate = this.appointmentId ? 'Update' : 'Add';
                    this.logging.logEventForComponentRendering(this.addOrUpdate + ' Schedule Component');
                    return;
                }
                else{
                    this.logging.logEventForComponentRendering(this.addOrUpdate + ' Schedule Component');
                }
                    
                await this.loadOutstandingAppointment();
            }
        );
        localStorage.removeItem('requestedUrl');
        this.searchAppointmentForm.get('careMemberName')?.disable();
        this.scheduleService.$shareTimezonSub.subscribe((value) => {
            this.selectedTimezoneName = value;
        });
        this.timezones = await this.referenceDataProvider.getTimezones();
        this.selectedTimezone = this.timezones.find(x => x.name == this.selectedTimezoneName)?.code;
        this.apptModes = await this.referenceDataProvider.getAppointmentModes();
        this.searchAppointmentForm.get('IdAppointmentType')?.disable();
        this.searchAppointmentForm.get('IdStaffType')?.disable();
        this.searchAppointmentForm.get('IdDuration')?.disable();
    }
    private async loadOutstandingAppointment(): Promise<void> {
        try {
            const data = await this.patientAppointmentService.patientAppointmentUpcomingAppointments(this.patientId, 30).toPromise();
            if (data && data.length) {
                this.showOutstandingAppointments(data);
            }
        } catch(error) {
 
        }
    }
        private async loadAppointment(): Promise<void> {
        Loader.showLoader("appointment-info");
        try {
            this.appointment = await this.appointmentService.appointmentGetAppointmentDetail(this.appointmentId).toPromise();
            this.selectedPatientContact = this.patientContacts.find(contact => contact.preferredContact == this.appointment?.mobileNo);
 
            this.address = this.appointment?.address;
            this.addressType = this.appointment?.addressType;
        } finally {
            Loader.hideLoader("appointment-info");
        }
    }
 
    private async loadContactInfo(): Promise<void> {
        for (let index = 0; index < this.patientContacts.length; index++) {
            if(this.appointment==null || this.appointment==undefined){
                /** New Appointment */
                if(this.patientContacts[index].contactTypeId == -114 && this.patientContacts[index].isPrimary){
                    this.selectedPatientContact = this.patientContacts[index];
                    return;
                }
            }else if(this.patientContacts[index].preferredContact === this.appointment?.mobileNo) {
                this.selectedPatientContact = this.patientContacts[index];
                return;
            }
        }
 
        /** if no contact selected, then select first contact */
        this.selectedPatientContact = this.patientContacts?.[0];
       
    }
 
    public async typeChanged(): Promise<void> {
        const careMemberInput = document.getElementById('careMember') as HTMLInputElement;
        this.searchAppointmentForm.patchValue({
            IdAppointmentType: '',
            IdDuration: '',
            IdStaffType:''
        })
        this.searchAppointmentForm.get('careMemberName')?.setValue('');
        careMemberInput.placeholder = 'Care Member (Optional)';
        await this.updateAppointmentModes();
        this.apptData.modeId = this.appointmentModes?.[0].modeId;
        this.searchAppointmentForm.get('IdAppointmentType')?.enable();
        this.StaffChange();
    }
    public modeChanged(){
        let mode = this.apptModes.find(x => x.mode == this.searchAppointmentForm.value.IdAppointmentType)?.modeId;
        if(mode != null){
            this.modeId = mode;
        }
        let duration = this.appointmentModes.find(x => x.modeId == mode)?.defaultDuration;
        this.searchAppointmentForm.get('IdDuration')?.setValue(duration);
        this.searchAppointmentForm.get('IdDuration')?.enable();
        this.searchAppointmentForm.get('IdStaffType')?.enable();
    }

    private StaffChange(){
        this.staffTypes = this.appointmentTypes.find(mode => mode.typeId == this.searchAppointmentForm.value.IdTaskType)?.staffTypes ?? [];
    }

    private async loadAppointmentTypes(): Promise<void> {
        await this.loadPatientPriority(this.patient?.id);
        let csnpnurse = this.centralNurseList.filter(x => x.type == 'Central SNP Nurse');
        if(csnpnurse.length > 0){
            this.snpDefaultAssign  =true;
        }
        let result = await this.GetAppointmentWizardType(this.patient?.stateId,this.snpDefaultAssign);
        if(result != undefined){
            this.appointmentTypes = result;
        }
        this.appointmentTypes.forEach((items) => this.mapAppointmentTypeId.push([items.typeId, items]));
        this.apptData.typeId = this.apptData.typeId ?? this.appointmentTypes?.[0].typeId;
        this.updateAppointmentModes();
    }
    private async GetAppointmentWizardType(stateId : any,snpDefaultAssign : boolean) {
        let  appointmentWTypes = await this.apptWizardSerice.appointmentWizardGetAppointmentWizardTypes(stateId,snpDefaultAssign).toPromise();
        return appointmentWTypes;
    }
    private async updateAppointmentModes(): Promise<void> {
        this.appointmentModes = this.appointmentTypes.find(mode => mode.typeId == this.searchAppointmentForm.value.IdTaskType)?.mode ?? [];
        this.appointmentType = this.appointmentTypes.find(x => x.typeId == this.searchAppointmentForm.value.IdTaskType);
        this.apptData.modeId = this.apptData.modeId ?? this.appointmentModes?.[0]?.modeId!;
    }
    public checkDisable(event: Event){
        const target = event.target as HTMLInputElement;
        const value=target.value;
        if(value == '' || value ==" "){
            this.disableSearch = true;
        }
        else{
            this.disableSearch = false;
        }
    }
    public staffChanged(event: Event) {
        this.checkDisable(event);
        this.showResults = true;
        this.flag = false;
        const careMemberInput = document.getElementById('careMember') as HTMLInputElement | null;
    
        this.updateCareMemberName(careMemberInput);
    
        this.updateStaffTypeAndTypeId();
    
        this.updateIsDisableState();
    
        this.updateCareMemberNameFieldState(careMemberInput);
    }
    
    private updateCareMemberName(careMemberInput: HTMLInputElement | null) {
        for (const element of this.centralNurseList) {
            if (this.searchAppointmentForm.value.IdStaffType === element.type) {
                if (element.name !== undefined) {
                    this.name = element.name;
                    this.searchAppointmentForm.get('careMemberName')?.setValue(element.name);
                    this.careMember = element;
                }
                this.flag = true;
                break;
            }
        }
    }
    
    private updateStaffTypeAndTypeId() {
        for (const element of this.staffTypes) {
            if (this.searchAppointmentForm.value.IdStaffType === element.name) {
                this.staffTypeId = element.value;
                this.typeId = element.value;
                break;
            }
        }
    }
    
    private updateIsDisableState() {
        this.isDisable = this.searchAppointmentForm.value.IdStaffType === '';
    }
    
    private updateCareMemberNameFieldState(careMemberInput: HTMLInputElement | null) {
        if (!this.flag && careMemberInput) {
            this.searchAppointmentForm.get('careMemberName')?.setValue('');
            careMemberInput.placeholder = 'Care Member (Optional)';
            this.searchAppointmentForm.get('careMemberName')?.enable();
        } else if (careMemberInput !== null) {
            careMemberInput.value = this.name;
            this.searchAppointmentForm.get('careMemberName')?.enable();
        }
    }
    
    valueChanged(input :string){
        if(input=="" || input==" "){
            this.isCarememberNameValid = true;
        }
        else if(input !== this.selectedCareMemberName){
            this.isCarememberNameValid = false;
        }
        else{
            this.isCarememberNameValid = true;
        }
    }
    selectCareMember(careMember: any) {
        const inputField = document.getElementById('careMember') as HTMLInputElement ;
        inputField.value = careMember.name;    
        this.searchAppointmentForm.get('careMemberName')?.setValue(careMember.name);
        this.selectedCareMemberName  = careMember.name;
        this.valueChanged(careMember.name);
        this.showResults = false;
        this.careMember = careMember;
    }
    searchText(event:Event){
        const target = event.target as HTMLInputElement;
        const value=target.value;
        this.valueChanged(value);
        this.typedText(value);
    }
    public typedText(text: string) {
        if (this.timeoutRef) {
            clearTimeout(this.timeoutRef);
            this.timeoutRef = null;
        }
        this.careMembers = undefined;
        if(text.length === 0) {
            this.careMembers = [];
        }
        if (text.length < 3) {
            return;
        }

        this._typedText = text;
        this.timeoutRef = setTimeout(this.search.bind(this), 500);

    }
    public async search(): Promise<void> {
        if (this._typedText!="") {
            this.noResult = false;
            this.careMembers = await this.apptWizardSerice.appointmentWizardGetCareTeamMemberByState(this._typedText, this.typeId, this.patient?.stateId,this.modeId).toPromise();
            if(this.careMembers && this.careMembers.length == 0){
                this.noResult = true;
            }
            const careMemberInput = document.getElementById('careMember') as HTMLInputElement;;
            if(careMemberInput.value == ''){
                this.careMembers = [];
            }
        }
    }
    @HostListener('document:click', ['$event'])
    handleDocumentClick(event: MouseEvent) {
        const careMemberInput = document.getElementById('careMember') as HTMLInputElement;
        const target = event.target as HTMLElement;
        const isInside = target && !!target.closest('.care-member-result');
        if (!isInside) {
            // this.searchAppointmentForm.get('careMemberName')?.setValue('');
            // careMemberInput.placeholder = 'Care Member (Optional)';
            this.noResult = false;
        }
    }
    
    public CareMemberAppointment(){
        const careMemberInput = document.getElementById('careMember') as HTMLInputElement;
        const requestData: CareMemberSlotRequest =this.mapFormValuesToModel(this.searchAppointmentForm);
        requestData.careMemberName = requestData.careMemberName == "" ? "Any" :requestData.careMemberName;
        this.careMemberList.push(requestData);
        for(let caremember of this.careMemberList){
            this.staffTypes = this.staffTypes.filter((item : any) => caremember.staffType !== item.name);
        }   
        const id = this.searchAppointmentForm.get('IdTaskType')?.value;
        this.updateAppointmentTypes(requestData.appointmentType,id);
        this.careMembers = [];
        this.isResetDisabled = false;
        this.isSearchEnable = false;
        this.isPrevDisable = true;
        this.isNextDisable = true;
        this.searchAppointmentForm.get('careMemberName')?.setValue('');
        careMemberInput.placeholder = 'Care Member (Optional)';
        this.searchAppointmentForm.patchValue({
            IdTaskType: '',
            IdAppointmentType: '',
            IdDuration: '',
            IdStaffType:''
        })
        careMemberInput.disabled = true;
    }
    
    private updateAppointmentTypes(mode : any,id : number){
        this.appointmentTypes = this.appointmentTypes?.filter((item) => item.typeId !== id);
        this.appointmentTypes = this.appointmentTypes?.filter(x => x.mode?.findIndex(x => x.modeId == mode) != -1);
        for(let type of this.appointmentTypes){
            type.mode = type.mode?.filter(x => x.modeId === mode);
        }
    }
    // private updateAppointmentModes()
    public mapFormValuesToModel(form: FormGroup): CareMemberSlotRequest {
        const time: number = parseInt(form.value.IdDuration.toString().substring(0, 2));
        if(form.value.careMemberName == ""){
            this.careMember = undefined;
        }
        return {
            taskType: form.value.IdTaskType.toString(),
            appointmentType: this.apptModes.find(x => x.mode == form.value.IdAppointmentType)?.modeId ,
            duration: time,
            careMemberName:form.value.careMemberName == "" ? '' : form.value.careMemberName,
            staffType: form.value.IdStaffType,
            email: this.careMember?.emailId,
            id: this.careMember?.id??0,
            staffTypeId: parseInt(this.staffTypeId.toString()),
            taskName : this.appointmentType?.type
        }
    }
    public async removeEntry(index : number){
        this.staffTypes.push({
            value : this.careMemberList[index].staffTypeId,
            name : this.careMemberList[index].staffType
        });
        this.careMemberList.splice(index,1);
        let appointmentWTypes = await this.GetAppointmentWizardType(this.patient?.stateId,this.snpDefaultAssign);
        if(appointmentWTypes != undefined){
            this.appointmentTypes = appointmentWTypes;
        }
        for(let careMember of this.careMemberList){
            this.updateAppointmentTypes(careMember.appointmentType,parseInt(careMember.taskType ?? '0'));
        }

        for(let caremember of this.careMemberList){
            this.staffTypes = this.staffTypes.filter((item : any) => caremember.staffType !== item.name);
        }
    }
    // textChanged(){
    //     if(this.searchAppointmentForm.get('careMemberName')?.value == ""){
    //         this.careMembers=[];
    //         this.showResults=false;
    //     }
    // }
    
    async onSearch(){
        this.maxDaysLimitExceed = false;
        this.noCareMemberFound = false;
        this.isTerminated = false;
        Loader.showLoader("result-panel-slots");
        const requstData :AvaiableSlotRequestModel =this.mapSearchValuesToModel();
        if(requstData.careMemberSlotRequest != undefined){
            for(let element of requstData.careMemberSlotRequest){
                if(element.careMemberName == "Any"){
                    element.careMemberName= "";
                }
            }
        }
        const staffTypeIds = requstData.careMemberSlotRequest?.map(item => item.id);
        this.centralNurseList.map((item) => {
            if(staffTypeIds?.includes(item.id) && item.isTerminated){
               this.isTerminated = true;
            }
        })
        if(!this.isTerminated){
            let result =await this.apptWizardSerice.appointmentWizardSearchAvailableSlots(requstData).toPromise();
            if(result != undefined){
                this.careMemberSlots = result;
            }
            this.searchAppointmentForm.get('selectDateSearching')?.setValue(new Date(this.careMemberSlots[0].slotStartDate));
            if(this.careMemberSlots[0].isTerminated){
                this.isTerminated = true;
            }
            else if (this.careMemberSlots[0].maxDaysLimitExceed){
                this.maxDaysLimitExceed = true;
            }
            else if(this.careMemberSlots[0].noCareMember){
                this.noCareMemberFound = true;
            }
            else{
                this.searchAppointmentForm.get('selectDateSearching')?.setValue(new Date(this.careMemberSlots[0].slotStartDate));
                if(this.careMemberSlots[0].careMemberResponseModels != null){
                    if(this.careMemberSlots[0]?.careMemberResponseModels?.length === 1){
                        this.resultLength = 1;
                    }
                    else if(this.careMemberSlots[0]?.careMemberResponseModels?.length === 2){
                        this.resultLength = 2;
                    }
                    else if(this.careMemberSlots[0]?.careMemberResponseModels?.length === 3){
                        this.resultLength = 3;
                    }
                }
                else{
                    this.resultLength = 4;
                }
            }
        }

        this.isNextDisable = false;
        this.isSearched = true;
        this.isSearchEnable = true;
        this.dateChanged();
    Loader.hideLoader("result-panel-slots");
}

    public mapSearchValuesToModel():AvaiableSlotRequestModel { 
        return {
            timeZone: this.selectedTimezoneName,
            patientAddress: this.patient?.address,
            stateId: parseInt(this.patient?.stateId ?? "", 10),
            startDate:`${this.searchAppointmentForm.value.selectDateSearching.toLocaleDateString('en-US')}`,
            careMemberSlotRequest: this.careMemberList,
            isPrevious : this.isPreviousSearch,
            userId: this.patientId,
            memberId: this.patient?.enrollmentNumber
        }
    }
    dateChanged(){
            if (`${this.searchAppointmentForm.value.selectDateSearching.toLocaleDateString('en-US')}` == `${this.Today.toLocaleDateString('en-US')}`) {
                this.isPrevDisable = true;
            }
            else {
                if(!this.isSearched){
                    this.isPrevDisable = true;
                }
                else{
                    this.isPrevDisable = false;
                }
            }
    }
    goToNewDate(value:number): void {
        if(value == -1){
            this.isPreviousSearch = true;
        }
        const currentDate = this.searchAppointmentForm.get('selectDateSearching')?.value;
        const changeDate = new Date(currentDate);
        changeDate.setDate(changeDate.getDate() + value); 
    
        this.searchAppointmentForm.patchValue({
          selectDateSearching: changeDate
        });
        this.dateChanged();
        this.onSearch();
        this.isSearchEnable = true;
        this.isPreviousSearch = false;
    }
    async resetForm(){
        this.maxDaysLimitExceed = false;
        this.noCareMemberFound = false;
        this.isTerminated = false;
        this.isNextDisable = true;
        this.isPrevDisable = true;
        this.isResetDisabled = true;
        this.isSearchEnable = true;
        this.searchAppointmentForm.reset();
        this.searchAppointmentForm.patchValue({
            selectDateSearching: new Date(),
            IdTaskType: '',
            IdAppointmentType: '',
            IdDuration: '',
            IdStaffType:''
          });
        this.dateChanged();
        this.careMemberList = [];
        this.careMembers = [];
        this.resultLength = -1;
        this.searchAppointmentForm.get('IdAppointmentType')?.disable();
        this.searchAppointmentForm.get('IdStaffType')?.disable();
        this.staffTypes = [];
        this.appointmentTypes=[];
        let result = await this.GetAppointmentWizardType(this.patient?.stateId,this.snpDefaultAssign);
        if(result != undefined){
            this.appointmentTypes = result;
        }
        }
    resetdropdown(){
        this.searchAppointmentForm.patchValue({
            selectDateSearching: new Date(),
            IdTaskType: '',
            IdAppointmentType: '',
            IdDuration: '',
            IdStaffType:''
          });
    }
    
    public showPatientContactsDialog(): void {
        this.genericModalService.showModel<PatientContactListInput, PatientContactInfo>(
            PatientContactListComponent,
            {patientId: this.patientId, selectedContact: this.selectedPatientContact, patientContacts: this.patientContacts},
            (patientContact: PatientContactInfo) => this.selectedPatientContact = patientContact,
        );
    }
 
    async updateAddress(): Promise<void> {
        if (this.patient) {
            this.patient.address = this.address;
            this.patient.addressType = this.addressType;
        }
    }
 
    private async loadPatientPriority(id: any): Promise<void> {
        Loader.showLoader("appointment-info");
        try {
            this.patientPriority = await this.patientService.patientGetPriority(id).toPromise() ?? '';
        } catch (error) {
            console.error(error);
        }
        finally {
            Loader.hideLoader("appointment-info");
        }
    }
 
    private async loadPracticeName(id: any): Promise<void> {
        Loader.showLoader("appointment-info");
        try {
            this.practiceName = await this.patientService.patientGetPatientAttribute(id).toPromise() ?? '';
        } catch (error) {
            console.error(error);
        }
        finally {
            Loader.hideLoader("appointment-info");
        }
    }
 
    private async loadPatient(id: any): Promise<void> {
        let staffResult: any;
        this.pageLoadingFailed = false;
        Loader.showLoader("scheduler-panel");
        await this.loadPatientPriority(this.patientId);
        await this.loadPracticeName(this.patientId);
        this.fetchCareMemberList();
        try {
            this.patient = await this.patientService.patientGetDetail(id).toPromise();
            if(this.patient != undefined){
            let resultPC = await this.patientService.patientGetPatientContactList(this.patient.id).toPromise();
            if(resultPC != undefined){
                this.patientContacts = resultPC;
            }
            this.selectedPatientContact = this.selectedPatientContact ?? this.patientContacts[0];
            this.loadAppointmentTypes();
            this.staffTypes=staffResult;
            let resultCN = await this.careTeamService.careTeamGetCareMemberByPatientId(this.patientId).toPromise();
            if(resultCN != undefined){
                this.centralNurseList = resultCN;
            }
            this.lat = this.patient.lat;
            this.lng = this.patient.lng;
            this.latLong.next({ lat: this.lat, lng: this.lng, address: this.address ?? ''});
            this.address = this.patient.address!;
            this.addressType = this.patient.addressType!;
            }
            if(this.appointmentId) {
                this.loadAppointment()
            }
            this.loadContactInfo();
        } catch (error) {
            this.pageLoadingFailed = true;
            console.error(error);
        }
        finally {
            Loader.hideLoader("scheduler-panel");
        }
    }
    private fetchCareMemberList(){
        this.careTeamService.careTeamGetCareMemberTypes().subscribe((data: any) => {
            this.careTeamMemberList = data;
        });
    }
    public openUpdateAddressDialog(): void {
    }
 
    public showPatientAppointments(): void {
        this.genericModalService.showModel(PatientAppointmentsComponent, this.patientId);
    }
    public showOutstandingAppointments(data: any): void {
        this.genericModalService.showModel(OutstandingAppointmentPopupComponent, data);
    }
    public submitCareNurseEvent($event: CareMemberInfo[]): void {
        this.centralCareNurse = $event;
    }
   
   
    public async draftappointment(){
        Loader.showLoader("schedule-component-panel");
        this.isActive=true;
        let request: any = {};
        request = <CreateAppointmentRequest>{
            patientId: this.patient?.id,
            address: this.patient?.address,
            addressType: this.patient?.addressType,
            scheduleTimeZoneId: this.patient?.timeZone,
            mobileNo: this.selectedPatientContact?.preferredContact,
            textOptOutStatus: this.selectedPatientContact?.isTextOptOut,
            callOptOutStatus: this.selectedPatientContact?.isCallOptOut,
            email: this.patient?.email,
            id: 0, typeId: 0,
            scheduleDateTime: new Date(),
            isOverrideAppointment: false
        };
        let appt = await this.appointmentService.appointmentDraftAppointments(request, true).toPromise();
        
        if (appt?.item1) {
            this.draftAppointmentId = appt.item2;
            this.meetingLink = appt.item1;
            Loader.hideLoader("schedule-component-panel");
            window.open(this.meetingLink, "_blank");
            this.isActive=false;
        }
    }
    public appointmentScheduledTime(datetime: Date): string | null {
        const startTime = datetime ? this.datePipe.transform(datetime, 'shortTime') : '';
        return startTime
    }

    public appointmentScheduledDate(date: Date): string | null {
        return date ? this.datePipe.transform(date, 'MM/dd/yyyy') : '';
    }

    public appointmentScheduleDay(date : Date) : String {
        let day = new Date(date).getDay();
        return days[day];

    }
    
    public selectResult(slot : AvailableSlots){
        let careMemberInfo: CareMemberInfo[] = [];
        let cmalslots : CalendarEvent[] = [];
        const caremembers = slot.careMemberResponseModels;
        for (const item of caremembers!) {
            let obj: CareMemberInfo = {
                id: item.careMemberId,
                name: item.careMemberName,
                emailId: item.careMemberEmail,
                schedulerId: 0,
                scheduleStartDate: new Date(item.apptstartTime),
                scheduleEndDate: new Date(item.apptendTime),
                schedulePreTime: item.preTime,
                schedulePostTime: item.postTime,
                appointmentCount: 0,
                type: item.staffType,
                distance: 0,
                modeId : item.appointmentType,
                address : item.address
            };
            careMemberInfo.push(obj);
            let date = new Date();
            let tzDate = new Date(date.toLocaleString('en-US', { timeZone: this.selectedTimezone }));
            const timezoneOffset = date.getTime() - tzDate.getTime();
            let start = this.addMinutes(item.apptstartTime,(-item.preTime));
            let end = this.addMinutes(item.apptendTime,(item.postTime));
            let newEvent : CalendarEvent = {
                color: '',
                id: Date.now()+item.careMemberId,
                patientId: this.patientId,
                isHSSchedule : true,
                modeId : item.appointmentType,
                schedulePostTime : item.postTime,
                schedulePreTime : item.preTime,
                resource : item.careMemberId,
                timezoneOffset: timezoneOffset,
                scheduleTimeZoneId: undefined,
                start : new Date(start),
                end : new Date(end),
                typeId : parseInt(item.taskType??'0'),
                preferredLanguageCode : 'en',
                selectedAppointment : true
            }
            cmalslots.push(newEvent);
        }
        const data = {
            calendarEvent: cmalslots,
            careMember: careMemberInfo,
            elasticId : slot.elasticId
        }
        this.scheduleService.addCareMemberEvents(data);
        $("#appointmentWizardModal").modal('hide');
    }

    addMinutes(date: Date, minutes: number): Date {
        let newDate = new Date(date);
        newDate.setTime(newDate.getTime() + minutes * 60000);
        return newDate;
    }

    handleKeyDown(event: KeyboardEvent) {
        //This method is created to add handle key dowm functionality in future
     }
      
      handleKeyUp(event: KeyboardEvent) {
        //This method is created to add handle key up functionality in future
       }
      
      handleKeyPress(event: KeyboardEvent) {
        //This method is created to add handle key press functionality in future
       }
}


