import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScheduleComponent } from './schedule.component';
import { ScheduleCalendarComponent } from './schedule-calendar/schedule-calendar.component';
import { AppointmentsListComponent } from './appointments-list/appointments-list.component';
import { CommonsUiModule } from '../commons-ui/commons-ui.module';
import { AppointmentPopupComponent } from './appointment-popup/appointment-popup.component';
import { TaskListComponent } from './task-list/task-list.component';
import { AddCareMemberComponent } from './add-care-member/add-care-member.component';
import { MbscModule } from '@mobiscroll/angular';
import { EventHelper } from './schedule-calendar/event-helper';
import { TimeFieldComponent } from './schedule-calendar/time-field/time-field.component';
import { ExistingAppointmentComponent } from './schedule-calendar/existing-appointment/existing-appointment.component';
import { PatientContactListComponent } from './patient-contact-list/patient-contact-list.component';
import { CommonsModule } from '../commons/commons.module';
import { ViewSchedulePopupComponent } from './view-schedule-popup/view-schedule-popup.component';
import { PatientAppointmentsComponent } from './patient-appointments/patient-appointments.component';
import { SearchCentralNurseComponent } from './search-central-nurse/search-central-nurse.component';
import { CentralNurseAvailabilityUiComponent } from './search-central-nurse/central-nurse-availability-ui/central-nurse-availability-ui.component';
import { FilterCareNursePipe } from './pipes/filter-care-nurse.pipe';
import { AutoCentralNurseAvailabilityUiComponent } from './search-central-nurse/auto-central-nurse-availability-ui/auto-central-nurse-availability-ui.component';
import { SearchBestTimeComponent } from './search-best-time/search-best-time.component';
import { OutstandingAppointmentPopupComponent } from './outstanding-appointment-popup/outstanding-appointment-popup.component';
import { AppointmentWizardComponent } from './appointment-wizard/appointment-wizard.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgxMaskDirective, NgxMaskPipe, provideEnvironmentNgxMask } from 'ngx-mask';


@NgModule({
  declarations: [
    ScheduleComponent,
    ScheduleCalendarComponent,
    AppointmentsListComponent,
    AppointmentPopupComponent,
    TaskListComponent,
    AddCareMemberComponent,
    TimeFieldComponent,
    ExistingAppointmentComponent,
    PatientContactListComponent,
    ViewSchedulePopupComponent,
    PatientAppointmentsComponent,
    SearchCentralNurseComponent,
    CentralNurseAvailabilityUiComponent,
    FilterCareNursePipe,
    AutoCentralNurseAvailabilityUiComponent,
    SearchBestTimeComponent,
    OutstandingAppointmentPopupComponent,
    AppointmentWizardComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    CommonsModule,
    CommonsUiModule,
    MbscModule,
    ReactiveFormsModule,
    NgxMaskDirective,
    NgxMaskPipe
  ],
  providers: [
    EventHelper,
    provideEnvironmentNgxMask()
  ]
})
export class ScheduleModule { }
