import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { ReferenceDataProvider } from './commons/reference-data-provider';
import { AppInsightsService } from './app-insights-service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  constructor( private referenceDataProvider: ReferenceDataProvider,private appInsights : AppInsightsService) {}

  ngOnInit(): void {
    this.referenceDataProvider.init();
  }
}
