
<iframe *ngIf="url" id="mapHeight" frameborder="0" class="iframe" [src] = "url"> </iframe>
<!-- <agm-map 
    [latitude]="latitude" 
    [longitude]="longitude">
      <agm-marker 
      [latitude]="latitude" 
      [longitude]="longitude"></agm-marker>
</agm-map> -->

