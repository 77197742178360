/**
 * Somatus Hybrid Scheduler API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { AddressDistanceModel } from '../model/addressDistanceModel';
import { CareMemberInfo } from '../model/careMemberInfo';
import { CareMemberInfoRequest } from '../model/careMemberInfoRequest';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class CareTeamService {

    protected basePath = 'https://somatus-hs-api.azurewebsites.net/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public careTeamGetCancellationReasonList(observe?: 'body', reportProgress?: boolean): Observable<any>;
    public careTeamGetCancellationReasonList(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public careTeamGetCancellationReasonList(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public careTeamGetCancellationReasonList(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/CareTeam/cancellationReasonList`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param patienId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public careTeamGetCareMemberByPatientId(patienId: number, observe?: 'body', reportProgress?: boolean): Observable<Array<CareMemberInfo>>;
    public careTeamGetCareMemberByPatientId(patienId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<CareMemberInfo>>>;
    public careTeamGetCareMemberByPatientId(patienId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<CareMemberInfo>>>;
    public careTeamGetCareMemberByPatientId(patienId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (patienId === null || patienId === undefined) {
            throw new Error('Required parameter patienId was null or undefined when calling careTeamGetCareMemberByPatientId.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<CareMemberInfo>>('get',`${this.basePath}/api/CareTeam/patient/${encodeURIComponent(String(patienId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param careMemberId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public careTeamGetCareMemberDetail(careMemberId: number, observe?: 'body', reportProgress?: boolean): Observable<Array<CareMemberInfo>>;
    public careTeamGetCareMemberDetail(careMemberId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<CareMemberInfo>>>;
    public careTeamGetCareMemberDetail(careMemberId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<CareMemberInfo>>>;
    public careTeamGetCareMemberDetail(careMemberId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (careMemberId === null || careMemberId === undefined) {
            throw new Error('Required parameter careMemberId was null or undefined when calling careTeamGetCareMemberDetail.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<CareMemberInfo>>('get',`${this.basePath}/api/CareTeam/${encodeURIComponent(String(careMemberId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param patientAddress 
     * @param careMemberAddress 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public careTeamGetCareMemberDistance(patientAddress?: string, careMemberAddress?: string, observe?: 'body', reportProgress?: boolean): Observable<AddressDistanceModel>;
    public careTeamGetCareMemberDistance(patientAddress?: string, careMemberAddress?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AddressDistanceModel>>;
    public careTeamGetCareMemberDistance(patientAddress?: string, careMemberAddress?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AddressDistanceModel>>;
    public careTeamGetCareMemberDistance(patientAddress?: string, careMemberAddress?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (patientAddress !== undefined && patientAddress !== null) {
            queryParameters = queryParameters.set('patientAddress', <any>patientAddress);
        }
        if (careMemberAddress !== undefined && careMemberAddress !== null) {
            queryParameters = queryParameters.set('careMemberAddress', <any>careMemberAddress);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<AddressDistanceModel>('get',`${this.basePath}/api/CareTeam/distance`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public careTeamGetCareMemberTypes(observe?: 'body', reportProgress?: boolean): Observable<any>;
    public careTeamGetCareMemberTypes(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public careTeamGetCareMemberTypes(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public careTeamGetCareMemberTypes(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/CareTeam/careTeamMemberTypes`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param searchText 
     * @param careMemberTypeID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public careTeamGetCareTeamMember(searchText?: string, careMemberTypeID?: number, observe?: 'body', reportProgress?: boolean): Observable<Array<CareMemberInfo>>;
    public careTeamGetCareTeamMember(searchText?: string, careMemberTypeID?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<CareMemberInfo>>>;
    public careTeamGetCareTeamMember(searchText?: string, careMemberTypeID?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<CareMemberInfo>>>;
    public careTeamGetCareTeamMember(searchText?: string, careMemberTypeID?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (searchText !== undefined && searchText !== null) {
            queryParameters = queryParameters.set('searchText', <any>searchText);
        }
        if (careMemberTypeID !== undefined && careMemberTypeID !== null) {
            queryParameters = queryParameters.set('careMemberTypeID', <any>careMemberTypeID);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<CareMemberInfo>>('get',`${this.basePath}/api/CareTeam/search`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public careTeamGetCentralNurseByPatientStateId(body: CareMemberInfoRequest, observe?: 'body', reportProgress?: boolean): Observable<CareMemberInfo>;
    public careTeamGetCentralNurseByPatientStateId(body: CareMemberInfoRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CareMemberInfo>>;
    public careTeamGetCentralNurseByPatientStateId(body: CareMemberInfoRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CareMemberInfo>>;
    public careTeamGetCentralNurseByPatientStateId(body: CareMemberInfoRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling careTeamGetCentralNurseByPatientStateId.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<CareMemberInfo>('post',`${this.basePath}/api/CareTeam/centralnurse`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public careTeamGetPreferredLanguageList(observe?: 'body', reportProgress?: boolean): Observable<any>;
    public careTeamGetPreferredLanguageList(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public careTeamGetPreferredLanguageList(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public careTeamGetPreferredLanguageList(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/CareTeam/preferredlanguagelist`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param stateId 
     * @param patientAddress 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public careTeamProximitySearch(stateId: number, patientAddress?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public careTeamProximitySearch(stateId: number, patientAddress?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public careTeamProximitySearch(stateId: number, patientAddress?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public careTeamProximitySearch(stateId: number, patientAddress?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (stateId === null || stateId === undefined) {
            throw new Error('Required parameter stateId was null or undefined when calling careTeamProximitySearch.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (patientAddress !== undefined && patientAddress !== null) {
            queryParameters = queryParameters.set('patientAddress', <any>patientAddress);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/CareTeam/proximity/search/${encodeURIComponent(String(stateId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
