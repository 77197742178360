import { Injectable } from '@angular/core';
import { AppConfigurationClient } from '@azure/app-configuration';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AzureAppConfigurationService {

  private appConfigClient !: AppConfigurationClient;
  private configValues: { [key: string]: string } = {};

  // constructor() {
  //   this.appConfigClient = new AppConfigurationClient(environment.azureAppConfiguration_connectionString);
  //  }
   
  //  async loadAppConfig(): Promise<void> {
  //   try {
  //     const settings = this.appConfigClient.listConfigurationSettings({ labelFilter: environment.azureAppConfiguration_label });

  //     for await (const setting of settings) {
  //       this.configValues[setting.key] = setting.value || '';
  //     }

  //   } catch (error) {
  //     console.error('Error fetching configuration:', error);
  //   }
  // }

  // getConfigValue(key: string): string | undefined {
  //   return this.configValues[key];
  // }
}
