import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AppointmentService } from 'src/app/api-client/api/appointment.service';
import { CareMemberInfo } from 'src/app/api-client/model/careMemberInfo';
import { RCTask } from 'src/app/api-client/model/rCTask';
import { ViewAppointment } from 'src/app/api-client/model/viewAppointment';
import { Loader } from 'src/app/commons-ui/loader';
import { environment } from 'src/environments/environment';
import { AppointmentManagementService, AppointmentResendInviteRequestModel, CareTeamService, PatientContactInfo } from '../../api-client';
import { AppointmentDetail } from '../../api-client/model/appointmentDetail';
import { CommonConstantEnum, CommonConstants } from '../../commons/common-constants';
import { PatientService } from 'src/app/api-client/api/patient.service';
import { CancellationReason } from 'src/app/models/common';
import { CreateAppointmentRequest } from 'src/app/api-client';
import { LoggingServiceTsService } from 'src/app/store/loggingService/logging.service';
import { ReferenceDataProvider } from 'src/app/commons/reference-data-provider';

declare const $: any;

@Component({
    selector: 'hs-appointment-details',
    templateUrl: './appointment-details.component.html',
    styleUrls: ['./appointment-details.component.scss']
})
export class AppointmentDetailsComponent implements OnInit {
    private patientId: number = 0;
    public appointmentDetail: AppointmentDetail = <AppointmentDetail>{};
    public statusId: string = '249';
    public cancelReason: string = '';
    public reason: string = '';
    public address: string = "";
    public appointmentHistory: Array<ViewAppointment> = []
    public isShowEditButton: boolean = false;
    public showVideoCallButton: boolean = false;
    public isTeleHealthModeScheduleExist: boolean = false;
    public recordingLink: string | null = null;
    public isRecordingAvailable: boolean = false;
    public showRecordingNotAvailableMsg: boolean = false;
    public commonConstant = CommonConstantEnum;
    public isShowResendInviteBtn: boolean = false;
    public isDisabledResendInviteBtn: boolean = false;
    public patientPriority: string = "";
    public isNoLongerApplicable: boolean = false;
    public isCloseUnsuccessfull: boolean = true;
    public cancelReasonList: CancellationReason[] = [];
    public patientContactInfo : PatientContactInfo[] = [];
    public contactInfo : PatientContactInfo = {
        patientContactId: 0,
        patienId: 0,
        contactTypeId: 0,
        isPrimary: false,
        isCallOptOut: false,
        isTextOptOut: false
    };
    public showError = false;
    public showErrorEmail = false;
    public showErrorPhone = false;
    public draftAppointmentId : number = 0;
    public meetingLink : string = '';
    public resendInviteModel: AppointmentResendInviteRequestModel = <AppointmentResendInviteRequestModel>{
        appointmentId: this.appointmentDetail.id,
        isPatient: false,
        isCareMember: false,
        isAdditionalUser: false,
        mobileNumber: '',
        email: ''
    };
    public isActive : boolean = false;
    private videoPlayer : any;

    constructor(private datePipe: DatePipe,
        private appointmentService: AppointmentService,
        private route: ActivatedRoute,
        private router: Router,
        private toastr: ToastrService,
        private careTeamService:CareTeamService,
        private patientService: PatientService,
        private loggingService : LoggingServiceTsService,
        private readonly appointmentManagementService : AppointmentManagementService,
        private readonly referenceDataProvider : ReferenceDataProvider) { }

    ngOnInit():void {
        this.route.params
            .subscribe(
                (params: Params) => {
                    this.patientId = params.id;
                }
            );
        this.loadAppointmentDetails(this.patientId);
        this.fetchCancellationReasonList();
        localStorage.removeItem('requestedUrl');
        let session = this.loggingService.GetSessionDetails();
        this.loggingService.logPageView("Appointments Details Page",window.location.href,session);
        this.videoPlayer = this.referenceDataProvider.getAzureConfigurationById('videoPlayer');
    }

    async loadAppointmentDetails(id: number): Promise<void> {
        Loader.showLoader("appointment-details");
        try {
            let resultAD = await this.appointmentService.appointmentGetAppointmentDetail(id).toPromise();
            if(resultAD){
                this.appointmentDetail = resultAD;
            }
            await this.loadPatientPriority(this.appointmentDetail.patient?.id);
            let appointmentScheduleList = this.appointmentDetail?.appointmentScheduleDetails ? this.appointmentDetail?.appointmentScheduleDetails : [];
            if (appointmentScheduleList.length > 0) {
                const showButton = new Date(appointmentScheduleList[0].scheduleStartDate).getTime() >= new Date().getTime();
                this.isShowEditButton = showButton;
                this.showVideoCallButton = showButton;
                this.isShowResendInviteBtn = showButton;
                this.isTeleHealthModeScheduleExist = appointmentScheduleList.some(x => x.modeId == 'telehealth');
            }
            this.address = this.appointmentDetail.patient?.address ? this.appointmentDetail.patient?.address : "";
        } finally {
            Loader.hideLoader("appointment-details");
        }
        this.checkRecordingAvailable();
        this.appointmentDetail.id && this.loadAppointmentHistory();
    }

    private async loadPatientPriority(id: any): Promise<void> {
        Loader.showLoader("appointment-history");
        try {
            let resultPP = await this.patientService.patientGetPriority(id).toPromise();
            if(resultPP != undefined){
                this.patientPriority = resultPP;
            }
        } catch (error) {
            console.error(error);
        }
        finally {
            Loader.hideLoader("appointment-history");
        }
    }

    private async loadAppointmentHistory(): Promise<void> {
        Loader.showLoader("appointment-history");
        try {
            let resultVA = await this.appointmentManagementService.appointmentManagementGetAppointmentHistory(this.appointmentDetail.id).toPromise();
            if(resultVA != undefined){
                this.appointmentHistory = resultVA;
            }
        } finally {
            Loader.hideLoader("appointment-history");
        }
    }
    public notLastElementOfArray(item: Array<RCTask> | Array<CareMemberInfo> | undefined, index: number): boolean {
        if (item && item.length && index < (item.length - 1)) {
            return true;
        }
        return false;
    }
    public async confirmCancelAppointment(): Promise<void> {
        Loader.showLoader("cancel-modal-footer");
        var selectedCancelReason = this.isCloseUnsuccessfull ? (this.getCancellationReasonSelectedText()  + " ") : "";
        await this.appointmentService.appointmentCancelAppointment({
            appointmentId: this.appointmentDetail.id,
            statusId: Number(this.statusId),
            reason: this.reason,
            cancellationReasonId: Number(this.cancelReason)
        }).toPromise();
        this.reason = '';
        if (this.appointmentDetail.status) {
            this.appointmentDetail.status = CommonConstants.APPOINTMENT_CANCEL;
        }
        Loader.hideLoader("cancel-modal-footer");
        $('#cancel-modal').modal('hide');
        this.router.navigate(['/appointments']);
        this.loggingService.trackEvent('Cancel Appointement : Done',this.loggingService.createCustomLog('Appointment Cancel','Appointment Cancel Confirmed','INFO'));   
    }

    public careTeamMemberScheduledTime(careTeam: CareMemberInfo): string | null {
        const timezone = this.appointmentDetail.timezone ? this.appointmentDetail.timezone : 'US/Eastern';
        const startDate = new Date(new Date(new Date(careTeam.scheduleStartDate + "Z").getTime() + careTeam.schedulePreTime * 60000).toLocaleString('en-US', { timeZone: timezone }));
        const endDate = new Date(new Date(new Date(careTeam.scheduleEndDate + "Z").getTime() - careTeam.schedulePostTime * 60000).toLocaleString('en-US', { timeZone: timezone }));
        const startTime = startDate ? this.datePipe.transform(startDate, 'shortTime') : '';
        const endTime = endDate ? this.datePipe.transform(endDate, 'shortTime') : '';
        return startTime + '-' + endTime;
    }

    public careTeamMemberScheduledDate(careTeam: CareMemberInfo): string | null {
        const timezone = this.appointmentDetail.timezone ? this.appointmentDetail.timezone : 'US/Eastern';
        const startDate = new Date(new Date(careTeam.scheduleStartDate + "Z").toLocaleString('en-US', { timeZone: timezone }));
        return startDate ? this.datePipe.transform(startDate, 'MM/dd/yyyy') : '';
    }
    private async checkRecordingAvailable() {
        let showRecording = false;
        if (this.appointmentDetail?.appointmentScheduleDetails && this.appointmentDetail?.meetingId) {
            showRecording = this.appointmentDetail?.appointmentScheduleDetails.some((schedule) => {
                const schedulePassed = new Date(schedule.scheduleEndDate + 'Z').getTime() < new Date().getTime();
                return schedule.modeId !== 'phone' && schedulePassed;
            });
            if (showRecording) {
                this.recordingLink = await this.appointmentManagementService.appointmentManagementGetMeetingRecording(this.appointmentDetail?.meetingId).toPromise()??'';
            }
        }
        if (showRecording && this.recordingLink && this.recordingLink != "") {
            this.isRecordingAvailable = true;
        } else if (showRecording) {
            this.showRecordingNotAvailableMsg = true;
        }
    }
    playVideo() {
        var url = this.videoPlayer + this.appointmentDetail?.meetingId;
        this.recordingLink && window.open(url, '_blank');
    }

    getcareTeamStatusClassName(careTeamstatus: any): string {
        if (careTeamstatus === 'Accepted') {
            return 'bg-success-light border-success text-success';
        }
        if (careTeamstatus === 'Tentative') {
            return 'bg-warning-light border-warning text-warning';
        }
        if (careTeamstatus === 'Declined') {
            return 'bg-danger-light border-danger text-danger';
        }
        if (careTeamstatus === 'Canceled') {
            return 'bg-danger-light border-danger text-danger';
        }
        if (careTeamstatus === CommonConstants.STATUS_CANCEL) {
            return 'bg-danger-light border-danger text-danger';
        }

        return '';
    }

    isCheckPatient(event: any) {
        this.resendInviteModel.isPatient = event.currentTarget.checked;
        this.resendBtnDisabled();

    }

    isCheckCareMember(event: any) {
        this.resendInviteModel.isCareMember = event.currentTarget.checked;
        this.resendBtnDisabled();
    }

    isCheckAdditionalUser(event: any) {
        this.resendInviteModel.isAdditionalUser = event.currentTarget.checked;
        this.resendBtnDisabled();
    }

    resendBtnDisabled() {
        if (this.resendInviteModel.isCareMember == true || this.resendInviteModel.isAdditionalUser == true || this.resendInviteModel.isPatient == true) {
            this.isDisabledResendInviteBtn = true;
        }
        else {
            this.isDisabledResendInviteBtn = false;
        }
    }

    public async confirmResndAppointment(form: any): Promise<void> {
        Loader.showLoader("cancel-modal-footer");
        this.isDisabledResendInviteBtn = false;
        this.appointmentDetail.notificationEmailId = this.resendInviteModel.email;
        this.appointmentDetail.notificationMobileNo = this.resendInviteModel.mobileNumber;

        if (this.resendInviteModel.isAdditionalUser && this.resendInviteModel.email == '' && this.resendInviteModel.mobileNumber == '') {
            this.toastr.warning("Please enter email or phone number. ");
            this.isDisabledResendInviteBtn = true;
            return;
        }
        if(this.resendInviteModel.email == this.appointmentDetail.emailId && this.resendInviteModel.mobileNumber == this.appointmentDetail.mobileNo?.replace(/-/g,'') && this.appointmentDetail.emailId != '' && this.appointmentDetail.mobileNo != ''){
            this.showError = true;
            this.showErrorEmail = true;
            this.showErrorPhone = true;
            return ;
        }
        if(this.resendInviteModel.email == this.appointmentDetail.emailId && this.appointmentDetail.emailId != ''){
            this.showError = true;
            this.showErrorEmail = true;
            return;
        }
        if(this.resendInviteModel.mobileNumber == this.appointmentDetail.mobileNo?.replace(/-/g,'') && this.appointmentDetail.mobileNo != null){
            this.showError = true;
            this.showErrorPhone = true;
            return;
        }

        this.resendInviteModel.appointmentId = this.appointmentDetail.id;
        let result = await this.appointmentManagementService.appointmentManagementResendInvite(this.resendInviteModel).toPromise();
        if (result) {
            this.toastr.success("Invite sent successfully.");
        }

        this.resetResendInvite();

        Loader.hideLoader("cancel-modal-footer");
        $('#resendinvite-modal').modal('hide');
        this.isDisabledResendInviteBtn = true;
    }

    resetResendInvite() {
        this.resendInviteModel.isCareMember = false;
        this.resendInviteModel.isPatient = false;
        this.resendInviteModel.isAdditionalUser = false;
        this.resendInviteModel.email = '';
        this.resendInviteModel.mobileNumber = '';
        this.isDisabledResendInviteBtn = false;
    }

    changeAppointmentTaskStatus(event: any) {
        if (event.target.value == 4367) {
            this.isNoLongerApplicable = true;
            this.isCloseUnsuccessfull = false;
        }
        else if (event.target.value == 249) {
            this.isCloseUnsuccessfull = true;
            this.isNoLongerApplicable = false;
        }
        else {
            this.isNoLongerApplicable = false;
            this.isCloseUnsuccessfull = false;
        }
    }

    closeCancelAppoinment() {
        this.statusId = '249';
        this.reason = '';
        this.isNoLongerApplicable = false;
        this.isCloseUnsuccessfull = true;
        this.cancelReason = '';
    }

    private fetchCancellationReasonList(): void {
        this.careTeamService.careTeamGetCancellationReasonList().subscribe((cancelReasonList: CancellationReason[]) => {
            this.cancelReasonList = [...cancelReasonList];
        })
    }

    private getCancellationReasonSelectedText() : string
    {
       let result =  this.cancelReasonList.filter(x=> x.id == Number(this.cancelReason))[0].name;
        if(result != null && result != "")
        {
            return result;
        }
        return "";
    }
    public async getPrimaryContact (patientId : number) {
        await this.patientService.patientGetPatientContactList(patientId).toPromise()
        .then((contactList: any) => {
        this.patientContactInfo = contactList;
        })
        .catch((error: any) => {
            console.error('Error fetching patient contact list:', error);
        });
        for(const element of this.patientContactInfo){
            if(element.isPrimary){
                this.contactInfo = element;
            }
        }
    }
    public async draftappointment(){
        Loader.showLoader("appointment-details");
        this.isActive=true;
        await this.getPrimaryContact(this.appointmentDetail.patient?.id!);
        let request: any = {};
        request = <CreateAppointmentRequest>{
            patientId: this.appointmentDetail.patient?.id,
            address: this.address,
            addressType: this.appointmentDetail.addressType,
            scheduleTimeZoneId: this.appointmentDetail.patient?.timeZone,
            mobileNo: this.appointmentDetail.mobileNo,
            textOptOutStatus: this.contactInfo?.isTextOptOut,
            callOptOutStatus: this.contactInfo?.isCallOptOut,
            email: this.appointmentDetail.patient?.email,
            id: 0, typeId: 0,
            scheduleDateTime: new Date(),
            isOverrideAppointment: false
        };
        let appt = await this.appointmentService.appointmentDraftAppointments(request,true).toPromise();
        if (appt?.item1) {
            this.draftAppointmentId = appt.item2;
            this.meetingLink = appt.item1;
            Loader.hideLoader("appointment-details");
            window.open(this.meetingLink, "_blank");
            this.isActive=false;
        } 
    }
    public CheckError(){
        this.showError = false;
        if(!this.showError){
            this.showErrorEmail = false;
            this.showErrorPhone = false;
        }
        this.isDisabledResendInviteBtn = true;
    }
    public EditAppointment(){
        this.loggingService.trackEvent('Edit Appointment : Initiated',this.loggingService.createCustomLog('Appointment Edit','Appointment Edit Initiated','INFO'));
    }
    public CancelAppointment(){
        this.loggingService.trackEvent('Cancel Appointment : Initiated',this.loggingService.createCustomLog('Appointment Cancel','Appointment Cancel Initiated','INFO'));   
    }
}
